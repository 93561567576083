/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { Button, Form, Input, Select, Upload, Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import "suneditor/dist/css/suneditor.min.css";
import { toast } from "react-toastify";
import { franchiseServices } from "../../../../../services/franchiseServices";
import { ca } from "date-fns/locale";
import { AsyncPaginate } from "react-select-async-paginate";

const AddFileModal = ({ open, closeModal, onOk, dataInput }) => {
  const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
  const [cate, setCate] = useState(null);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (dataInput != null) {
      onFill();
      if (open == true) {
        // getOneHelp(dataInput.id);
      }
    } else {
      onReset();
    }
    if (open == true) {
      // getOneHelp(dataInput.id);
      getFranchiseCate();
    }
  }, [open]);

  const addFileFranchise = async (data) => {
    setLoading(true);
    try {
      const response = await franchiseServices.addFileFranchise(data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const updateFranchiseCate = async (data, id) => {
    try {
      const response = await franchiseServices.updateFranchiseCate(data, id);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
  };

  // const getOneHelp = async (id) => {
  //   setReset(true);
  //   try {
  //     const response = await helpServices.getOneHelp(id);
  //     setContentText(response.data.content);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setReset(false);
  // };

  const getFranchiseCate = async () => {
    setReset(true);
    try {
      const response = await franchiseServices.getFranchiseCate();
      setCate(response.data);
    } catch (error) {
      console.log(error);
    }
    setReset(false);
  };

  const onFinish = (values) => {

    if (customers.length == 0) {
      toast.error("Vui lòng chọn khách hàng");
      return;
    }
    values.staff_id = customers.value;
    console.log(values);
    values.file_url = fileUrl;
    values.upload = null;
    if (dataInput != null) {
     // updateFranchiseCate(values, dataInput.id);
    } else {
      addFileFranchise(values);
    }
  };

  const onFill = () => {
    form.setFieldsValue({
      name: dataInput?.name ?? "",
      file_catiegory_id: dataInput?.file_catiegory_id ?? "",
      file_url: dataInput?.file_url ?? "",
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 20,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 22,
      span: 28,
    },
  };

  const { Option } = Select;

  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const beforeUpload = (file) => {
    // const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    // if (!isJpgOrPng) {
    //   toast.error("You can only upload JPG/PNG file!");
    //   return false;
    // }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      toast.error("File phải nhỏ hơn 20MB!");
      return false;
    }
    return isLt5M;
  };

  const [fileList, setFileList] = useState([]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (info) => {
    console.log(info.file.status);

    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
       
      });
      setFileList([...fileList, info.file]);
    }
  };

  const getCustomerFranchise = async (search, __loadedOptions, { page }) => {
    const queryString = `?search=${search}&page=${page}`;

    try {
      const res = await franchiseServices.getCustomerFranchise(queryString);

      return {
        options: res.data.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };


  return (
    <Modal
      title={dataInput?.id != null ? "Sửa file" : "Thêm file"}
      centered
      open={open}
      width={800}
      hildren={form}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div className="mt-4">
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
        <Row align="middle" className="mb-4">
            <Col flex="5">
              <div className="d-flex justify-content-between">
                <div></div>
                <p className="mr-2">Khách hàng:</p>
              </div>
            </Col>
            <Col flex="19">
              <AsyncPaginate
                value={customers}
                loadOptions={getCustomerFranchise}
                isMulti={false}
                closeMenuOnSelect={false}
                onChange={setCustomers}
                additional={{
                  page: 1,
                }}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 99999,
                  }),
                }}
                menuPortalTarget={document.body}
                debounceTimeout={500}
                isClearable
                isSearchable
              />
            </Col>
          </Row>
          <Form.Item
            name="name"
            label="Tên file"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập tên file"} />
          </Form.Item>
          <Form.Item
            name="file_catiegory_id"
            label="Loại file"
            hasFeedback
            rules={[{ required: true, message: "Chưa chọn loại file" }]}
          >
            <Select
              placeholder="Chọn loại file"
              onChange={(v) => {
                console.log(v);
              }}
            >
              {cate?.map((item, index) => {
                return <Option value={item.id}>{item.name}</Option>;
              })}
            </Select>
          </Form.Item>
          {dataInput?.id != null ? (
            <></>
          ) : (
            <Form.Item
              name="upload"
              label="Upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "Chưa chọn file Upload!",
                  type: "array",
                },
              ]}
            >
              <Upload
                name="upload"
                label="Upload"
                valuePropName="fileList"
                headers={{
                  token: tokenInfo,
                  "Content-Type": "multipart/form-data",
                }}
                maxCount={1}
                action="https://api.comnieuthienly.vn/api/upload_v2/files?type=franchise"
                beforeUpload={beforeUpload}
                onChange={handleChange}
                fileList={fileList}
                customRequest={({ file, onSuccess, onError }) => {
                  const formData = new FormData();
                  formData.append("image", file);
                  fetch("https://api.comnieuthienly.vn/api/upload_v2/files?type=franchise", {
                    method: "POST",
                    headers: {
                      token: tokenInfo,
                    },
                    body: formData,
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      setFileUrl( data?.data?.image_url);
                      onSuccess();
                    })
                    .catch((error) => {
                      onError();
                    });
                }}
              >
                <Button icon={<UploadOutlined />}>Upload File</Button>
              </Upload>
            </Form.Item>
          )}
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddFileModal;
