/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { procedureServices } from "../../../../services/procedureServices";
import moment from "moment";
import { constants } from "../../../../constants";
import { MdAccessTimeFilled } from "react-icons/md";
import { formatMoneyVND } from "../../../../helpers";
import { toast } from "react-toastify";
import { BsFileEarmarkArrowDownFill } from "react-icons/bs";
import { Image, Modal } from "antd";

const ReportFormModal = ({
  visible,
  formId,
  closeModal,
  onSuccess,
  departmentsInput,
}) => {
  const areas = useSelector((state) => state.area.areas);
  const reportNumber = useSelector((state) => state.reportNumber.report);

  const dispatch = useDispatch();
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [form, setForm] = useState(null);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    if (visible == true) {
      getFormDetail({ id: formId });
    }
  }, [formId]);

  const customStyles = {
    content: {
      width: "70%",
      height: "auto",
      maxHeight: "90%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  const checkPermission = (process) => {
    const index = (process?.approvers ?? []).findIndex(
      (e) => e.id === reportNumber.current_user?.id
    );
    if (index != -1) {
      return true;
    } else {
      return false;
    }
  };

  const acceptOrCancelMany = async ({ isApprove }) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await procedureServices.acceptOrCancelMany({
        data: {
          is_approve: isApprove,
          list_form_id: [form?.id],
        },
      });
      closeModal();
      onSuccess();
      if (response.code != 200) {
        return;
      }
      toast.success(isApprove == true ? "Đã xác nhận" : "Đã từ chối");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const getFormDetail = async ({ id }) => {
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.SHOW_LOADING,
    });
    try {
      const res = await procedureServices.getReportFormDetail({
        id: id,
      });

      setForm(res.data);
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.NONE_LOADING,
    });
  };

  function checkApprove(processId, staffId) {
    var history = form?.histories ?? [];

    if (history.length === 0) {
      return null;
    }

    var dataFind = history.filter((e) => {
      console.log("sss");
      return e?.process_id === processId;
    });

    if (dataFind?.length === 0) {
      return null;
    }

    if ((dataFind[0]?.actions ?? []).length === 0) {
      return null;
    }

    var staffApprove = dataFind[0]?.actions.filter((e) => {
      return e?.id === staffId;
    });

    if ((staffApprove ?? []).length == 0) {
      return null;
    }

    if ((staffApprove ?? []).length !== 0) {
      return staffApprove[0]?.pivot?.is_approve === 1 ? true : false;
    }

    return null;
  }
  const checkProcessItem = (processId, staffId) => {
    const check = checkApprove(processId, staffId);
    if (check === null) return;
    return (
      <div
        className="px-2 py-1 mx-5"
        style={{
          border: `1px solid ${check === true ? "#39d46b" : "red"}`,
          borderRadius: "10px",
          color: `${check === true ? "#39d46b" : "red"}`,
          transform: "rotate(-10deg)",
        }}
      >
        {check === true ? "Đã duyệt" : check === false ? "Từ chối" : ""}
      </div>
    );
  };

  const itemProcess = () => {
    return (
      <div>
        {(form?.list_process ?? []).map((p, index) => {
          return (
            <div className="d-flex mb-3 ">
              <div
                style={{
                  backgroundColor: "#f2f7ff",
                  borderLeft: "2px solid #1ccc78",
                  padding: "4px",
                  height: "20px",
                  color: "#1ccc78",
                  alignItems: "center",
                  display: "flex",
                  marginRight: "15px",
                  fontWeight: "700",
                }}
              >
                {index + 1}
              </div>
              <div className="flex-shrink-1 px-2">
                {(p?.approvers ?? []).map((staff) => {
                  return (
                    <div className="d-flex align-items-center mb-2">
                      <div className="mr-2">
                        <img
                          src={staff?.avatar ?? ""}
                          style={{
                            width: "35px",
                            height: "35px",
                            borderRadius: "20%",
                            objectFit: "cover",
                            border: "1px solid #ccc",
                          }}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "https://i.imgur.com/QWm0Z3L.png";
                          }}
                        />
                      </div>
                      <div
                        style={{
                          color:
                            checkApprove(p.id, staff.id) === null
                              ? "grey"
                              : checkApprove(p.id, staff.id) == true
                              ? "#39d46b"
                              : "red",
                          fontWeight: "400",
                        }}
                      >
                        {staff?.name}
                      </div>
                      {checkProcessItem(p.id, staff.id)}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const itemHistory = () => {
    return (
      <div>
        {(form?.histories ?? []).map((h) => {
          return (h.actions ?? []).map((a) => {
            return (
              <div className="d-flex align-items-center mb-2">
                <MdAccessTimeFilled color="grey"></MdAccessTimeFilled>
                <span>
                  <span style={{ fontWeight: "700", marginLeft: "5px" }}>{`${
                    a?.name ?? ""
                  }`}</span> 
                  <span>
                    {a?.pivot?.is_approve === 1
                      ? " đã duyệt yêu cầu"
                      : " đã từ chối yêu cầu"}
                  </span>
                </span>
                {a?.pivot?.handled_at !== null ? (
                  <div className="flex-grow-1" style={{ textAlign: "end" }}>
                    {moment(a?.pivot?.handled_at).format(
                      constants.FORMAT_DD_MM_YYYY_HH_MM
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            );
          });
        })}
        <div className="d-flex align-items-center mb-2">
          <MdAccessTimeFilled color="grey"></MdAccessTimeFilled>
          <span>
            <span style={{ fontWeight: "700", marginLeft: "5px" }}>{`${
              form?.staff?.name ?? ""
            } `}</span>
            <span>đã tạo yêu cầu</span>
          </span>
          {form?.created_at !== null ? (
            <div className="flex-grow-1" style={{ textAlign: "end" }}>
              {moment
                .utc(form?.created_at)
                .format(constants.FORMAT_DD_MM_YYYY_HH_MM)}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const itemType = () => {
    var formData;
    if (form?.content != null && form?.content != "") {
      formData = JSON.parse(form?.content);
    }

    var type = form?.type;
    switch (type) {
      case constants.TYPE_REPORT_STAFF_DAY:
        return (
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Mã phiếu
              </div>
              <b>{form?.id ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Ngày báo cáo
              </div>
              <b>
                {moment(
                  form?.report_date ?? formData?.date,
                  "YYYY-MM-DD"
                ).format(constants.FORMAT_DD_MM_YYYY)}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Sự cố công việc trong ngày
              </div>
              <b>{formData?.problem ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Mức độ hoàn thành công việc
              </div>
              <b>{formData?.process_task ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Góp ý/phản ánh của khách hàng
              </div>
              <b>{formData?.feedback ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Khó khăn đang gặp phải
              </div>
              <b>{formData?.hard ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Món hết vào giờ cao điểm
              </div>
              <b>{formData?.out_dishes ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Tình hình nhân sự
              </div>
              <b>{formData?.staff_status ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Doanh thu
              </div>
              <b>{formatMoneyVND(form?.revenue ?? 0)}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Ghi chú
              </div>
              <b>{formData?.content ?? "---:---"}</b>
            </div>

            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );

      case constants.TYPE_REPORT_IN_DAY:
        return (
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Mã phiếu
              </div>
              <b>{form?.id ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Ngày báo cáo
              </div>
              <b>
                {moment(
                  form?.report_date ?? formData?.date,
                  "YYYY-MM-DD"
                ).format(constants.FORMAT_DD_MM_YYYY)}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>{form?.area?.name ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Tình hình nhân sự
              </div>
              <b>{formData?.staff_status ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Sự cố/góp ý khách hàng
              </div>
              <b>{formData?.problem ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chất lượng hàng hóa
              </div>
              <b>{formData?.quality ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Góp ý/Cải tiến
              </div>
              <b>{formData?.feedback ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Khó khăn đang gặp phải
              </div>
              <b>{formData?.hard ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Mức độ thực hiện tiêu chuẩn 4C
              </div>
              <b>{formData?.c4 ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Nhân viên thái độ xấu với khách
              </div>
              <b>{formData?.bad_staff ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Nhân viên vi phạm nội quy
              </div>
              <b>{formData?.violate_rules_staff ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Món hết vào giờ cao điểm
              </div>
              <b>{formData?.out_dishes ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Ghi chú
              </div>
              <b>{formData?.content ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Doanh thu
              </div>
              <b>{formatMoneyVND(form?.revenue ?? 0)}</b>
            </div>
            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );

      case constants.TYPE_REPORT_INVOICE:
        return (
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Mã phiếu
              </div>
              <b>{form?.id ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Ngày báo cáo
              </div>
              <b>
                {moment(
                  form?.report_date ?? formData?.date,
                  "YYYY-MM-DD"
                ).format(constants.FORMAT_DD_MM_YYYY)}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>{form?.area?.name ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Nội dung
              </div>
              <b>{formData?.content ?? "---:---"}</b>
            </div>
            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );

      case constants.TYPE_REPORT_INVENTORY:
        return (
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Mã phiếu
              </div>
              <b>{form?.id ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Ngày báo cáo
              </div>
              <b>
                {moment(
                  form?.report_date ?? formData?.date,
                  "YYYY-MM-DD"
                ).format(constants.FORMAT_DD_MM_YYYY)}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>{form?.area?.name ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Nội dung
              </div>
              <b>{formData?.content ?? "---:---"}</b>
            </div>
            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );

      case constants.TYPE_REPORT_BUDGET:
        return (
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Mã phiếu
              </div>
              <b>{form?.id ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Ngày báo cáo
              </div>
              <b>
                {moment(
                  form?.report_date ?? formData?.date,
                  "YYYY-MM-DD"
                ).format(constants.FORMAT_DD_MM_YYYY)}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>{form?.area?.name ?? "---:---"}</b>
            </div>
            <div
              className="col-12 pb-3"
              style={{ fontSize: "15px", fontWeight: "700", color: "red" }}
            >
              I. Doanh thu:
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Tồn doanh thu TM đầu ngày
              </div>
              <b>{formatMoneyVND(form?.cash_begin_day ?? 0)}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Doanh thu bán hàng TM trong ngày
              </div>
              <b>{formatMoneyVND(form?.cash_in_day ?? 0)}</b>
            </div>

            <span className="col-12 pb-2">
              <div
                style={{
                  fontSize: "13px",
                  fontWeight: "700",
                }}
              >
                {`Thu khác trong ngày: ${formatMoneyVND(
                  form?.other_revenue ?? 0
                )}`}
              </div>
              {(formData?.list_product ?? []).map((e) => {
                return (
                  <div
                    className="row"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      margin: "5px",
                      padding: "5px",
                    }}
                  >
                    <span className="col">{e?.name ?? "---:---"}</span>
                    <span className="col">SL: {e?.quantity ?? "---:---"}</span>
                    <span className="col">{formatMoneyVND(e?.money ?? 0)}</span>
                  </div>
                );
              })}
            </span>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Nộp doanh thu về công ty
              </div>
              <b>{formatMoneyVND(form?.tip ?? 0)}</b>
            </div>
            <div className="col-12 pb-3">
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "700",
                  color: "blue",
                }}
              >
                Tổng doanh thu
              </div>
              <b>
                {formatMoneyVND(
                  (form?.cash_begin_day ?? 0) +
                    (form?.cash_in_day ?? 0) +
                    (form?.other_revenue ?? 0) -
                    (form?.tip ?? 0)
                )}
              </b>
            </div>
            <div
              className="col-12 pb-3"
              style={{ fontSize: "15px", fontWeight: "700", color: "red" }}
            >
              II. Quỹ:
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Tổng quỹ đầu ngày
              </div>
              <b>{formatMoneyVND(form?.early_day_fund ?? 0)}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Bổ sung quỹ
              </div>
              <b>{formatMoneyVND(form?.supplemental_fund ?? 0)}</b>
            </div>
            <span className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                  fontWeight: "700",
                }}
              >
                Những danh mục chi:
              </div>
              {(form?.report_budget ?? []).map((e) => {
                return (
                  <div
                    className="row"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      margin: "5px",
                      padding: "5px",
                    }}
                  >
                    <span className="col">{e?.name ?? "---:---"}</span>
                    <span className="col">SL: {e?.quantity ?? "---:---"}</span>
                    <span className="col">{formatMoneyVND(e?.price ?? 0)}</span>
                  </div>
                );
              })}
            </span>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi tiền mặt trong ngày
              </div>
              <b>{formatMoneyVND(form?.expenditure ?? 0)}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "700",
                  color: "blue",
                }}
              >
                Tồn quỹ tiền mặt cuối ngày
              </div>
              <b>{formatMoneyVND(form?.cash_last_day ?? 0)}</b>
            </div>
            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );

      case constants.TYPE_REPORT_REVENUE:
        return (
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Mã phiếu
              </div>
              <b>{form?.id ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Ngày báo cáo
              </div>
              <b>
                {moment(
                  form?.report_date ?? formData?.date,
                  "YYYY-MM-DD"
                ).format(constants.FORMAT_DD_MM_YYYY)}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>{form?.area?.name ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Ca làm
              </div>
              <b>{form?.shift_type == 0 ? "Sáng" : "Tối"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Tổng doanh thu
              </div>
              <b>{formatMoneyVND(form?.revenue ?? 0)}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Tiền mặt
              </div>
              <b>{formatMoneyVND(form?.cash ?? 0)}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chuyển khoản
              </div>
              <b>{formatMoneyVND(form?.money_transfer ?? 0)}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quẹt thẻ
              </div>
              <b>{formatMoneyVND(form?.money_swipe ?? 0)}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Grab food
              </div>
              <b>{formatMoneyVND(form?.grap ?? 0)}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Shopee Food
              </div>
              <b>{formatMoneyVND(form?.shopee ?? 0)}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Baemin
              </div>
              <b>{formatMoneyVND(form?.beamin ?? 0)}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Bee Food
              </div>
              <b>{formatMoneyVND(form?.bee ?? 0)}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Doanh thu khác
              </div>
              <b>{formatMoneyVND(form?.other_revenue ?? 0)}</b>
            </div>

            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Tổng chi
              </div>
              <b>{formatMoneyVND(form?.expenditure ?? 0)}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Doanh thu tiền mặt còn lại
              </div>
              <b>{formatMoneyVND(form?.revenue_remain_cash ?? 0)}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Ghi chú
              </div>
              <b>{formData?.content ?? "---:---"}</b>
            </div>
            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );

      default:
        return (
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Mã phiếu
              </div>
              <b>{form?.id ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>{form?.area?.name ?? "---:---"}</b>
            </div>
            <span className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Thông tin thiết bị:
              </div>
              {(formData?.list_product ?? []).map((e) => {
                return (
                  <div
                    className="row"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      margin: "5px",
                      padding: "5px",
                    }}
                  >
                    <span className="col">{e?.name ?? "---:---"}</span>
                    <span className="col">SL: {e?.quantity ?? "---:---"}</span>
                    <span className="col">{e?.description ?? "---:---"}</span>
                  </div>
                );
              })}
            </span>
            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );
    }
  };

  function downloadFile(url) {
    const tagA = document.createElement("a");
    tagA.href = url;
    tagA.download = "file";
    tagA.target = "_blank";
    tagA.click();
  }

  return (
    <Modal
      title={form?.title ?? "Thông tin yêu cầu"}
      centered
      open={visible}
      width={"70%"}
      style={{ top: 20 }}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div
        className="mt-4"
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "70vh",
          margin: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "#f2f7ff",
          }}
          className="pt-3 px-3"
        >
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Người báo cáo
              </div>
              <b>{form?.staff?.name ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Ngày tạo
              </div>
              <b>
                {form?.created_at === null
                  ? "---:---"
                  : moment
                      .utc(form?.created_at)
                      .format(constants.FORMAT_DD_MM_YYYY_HH_MM)}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>
                {areas.data?.filter(
                  (area) => area.id == form?.staff?.area_id
                )[0]?.name ?? "---:---"}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div>
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Phòng ban
                </div>
                <b>
                  {departmentsInput != null
                    ? departmentsInput.filter((v) => {
                        return v.value == form?.staff?.department_id ?? "";
                      })[0]?.label ?? "---:---"
                    : "---:---"}
                </b>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "10px" }}></div>
        <div
          style={{
            backgroundColor: "#f2f7ff",
          }}
          className="pt-3 px-3 my-3"
        >
          {itemType()}
        </div>
        {(form?.file_url ?? []).map((f) => {
          return (
            <div className="d-flex ml-3" style={{ alignItems: "center" }}>
              <BsFileEarmarkArrowDownFill
                style={{ color: "#1ecc78" }}
              ></BsFileEarmarkArrowDownFill>
              <span
                className="ml-1"
                style={{ color: "#1ecc78", cursor: "pointer" }}
                onClick={(e) => {
                  downloadFile(f);
                }}
              >
                Xem file đính kèm
              </span>
            </div>
          );
        })}
        {(form?.images ?? []).length > 0 ? (
          <div style={{ margin: "10px" }}>
            <div className="mt-2" style={{ fontWeight: "700" }}>
              Hình ảnh đính kèm:
            </div>
            <div className="d-flex" style={{ flexWrap: "wrap" }}>
              <Image.PreviewGroup
                previprew={{
                  onChange: (current, prev) =>
                    console.log(
                      `current index: ${current}, prev index: ${prev}`
                    ),
                }}
              >
                {(form?.images ?? []).map((e, index) => {
                  return (
                    <div className="my-3 col-4">
                      <Image
                        src={e ?? ""}
                        height={300}
                        style={{
                          borderRadius: "5px",
                          objectFit: "cover",
                          border: "1px solid #ccc",
                          marginRight: "16px",
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://admin.comnieuthienly.vn/images/logo.jpg";
                        }}
                      ></Image>
                    </div>
                  );
                })}
              </Image.PreviewGroup>
            </div>
          </div>  
        ) : (
          <></>
        )}
        <div className="py-3" style={{ fontSize: "15px", fontWeight: "700" }}>
          Người duyệt
        </div>
        {itemProcess()}
        <div
          className="my-4"
          style={{ height: "1px", backgroundColor: "#f2f7ff", width: "100%" }}
        ></div>
        {itemHistory()}
        <div className="my-4"></div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Đóng
        </button>
        {form?.status === 1 && checkPermission(form?.process) ? (
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                borderColor: "#f6c23e",
                borderWidth: "1px",
                borderStyle: "solid",
                color: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                acceptOrCancelMany({ isApprove: false });
              }}
            >
              Từ chối
            </button>
            <div style={{ width: "10px" }}></div>
            <button
              type="button"
              style={{
                backgroundColor: "#f6c23e",
                borderRadius: "8px",
                color: "white",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                acceptOrCancelMany({ isApprove: true });
              }}
            >
              Chấp nhận
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};

export default ReportFormModal;
