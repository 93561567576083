/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { procedureServices } from "../../../../services/procedureServices";
import moment from "moment";
import { constants } from "../../../../constants";
import { MdAccessTimeFilled } from "react-icons/md";
import { formatMoneyVND } from "../../../../helpers";
import { toast } from "react-toastify";
import { Image, Modal } from "antd";

const FormModal = ({
  visible,
  formId,
  closeModal,
  onSuccess,
  departmentsInput,
}) => {
  const areas = useSelector((state) => state.area.areas);
  const reportNumber = useSelector((state) => state.reportNumber.report);

  const dispatch = useDispatch();

  const [form, setForm] = useState(null);

  useEffect(() => {
    if (visible == true) {
      getFormDetail({ id: formId });
    }
  }, [formId]);

  const customStyles = {
    content: {
      width: "70%",
      height: "auto",
      maxHeight: "90%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };
  const checkPermission = (process) => {
    const index = (process?.approvers ?? []).findIndex(
      (e) => e.id === reportNumber.current_user?.id
    );
    if (index != -1) {
      return true;
    } else {
      return false;
    }
  };

  const acceptOrCancelMany = async ({ isApprove }) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await procedureServices.acceptOrCancelMany({
        data: {
          is_approve: isApprove,
          list_form_id: [form?.id],
        },
      });
      closeModal();
      onSuccess();
      if (response.code != 200) {
        return;
      }
      toast.success(isApprove == true ? "Đã xác nhận" : "Đã từ chối");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const getFormDetail = async ({ id }) => {
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.SHOW_LOADING,
    });
    try {
      const res = await procedureServices.getFormDetail({
        id: id,
      });

      setForm(res.data);
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.NONE_LOADING,
    });
  };

  function checkApprove(processId, staffId) {
    var history = form?.histories ?? [];

    if (history.length === 0) {
      return null;
    }

    var dataFind = history.filter((e) => {
      console.log("sss");
      return e?.process_id === processId;
    });

    if (dataFind?.length === 0) {
      return null;
    }

    if ((dataFind[0]?.actions ?? []).length === 0) {
      return null;
    }

    var staffApprove = dataFind[0]?.actions.filter((e) => {
      return e?.id === staffId;
    });

    if ((staffApprove ?? []).length == 0) {
      return null;
    }

    if ((staffApprove ?? []).length !== 0) {
      return staffApprove[0]?.pivot?.is_approve === 1 ? true : false;
    }

    return null;
  }
  const checkProcessItem = (processId, staffId) => {
    const check = checkApprove(processId, staffId);
    if (check === null) return;
    return (
      <div
        className="px-2 py-1 mx-5"
        style={{
          border: `1px solid ${check === true ? "#39d46b" : "red"}`,
          borderRadius: "10px",
          color: `${check === true ? "#39d46b" : "red"}`,
          transform: "rotate(-10deg)",
        }}
      >
        {check === true ? "Đã duyệt" : check === false ? "Từ chối" : ""}
      </div>
    );
  };

  const itemProcess = () => {
    return (
      <div>
        {(form?.list_process ?? []).map((p, index) => {
          return (
            <div className="d-flex mb-3 ">
              <div
                style={{
                  backgroundColor: "#f2f7ff",
                  borderLeft: "2px solid #1ccc78",
                  padding: "4px",
                  height: "20px",
                  color: "#1ccc78",
                  alignItems: "center",
                  display: "flex",
                  marginRight: "15px",
                  fontWeight: "700",
                }}
              >
                {index + 1}
              </div>
              <div className="flex-shrink-1 px-2">
                {(p?.approvers ?? []).map((staff) => {
                  return (
                    <div className="d-flex align-items-center mb-2">
                      <div className="mr-2">
                        <img
                          src={staff?.avatar ?? ""}
                          style={{
                            width: "35px",
                            height: "35px",
                            borderRadius: "20%",
                            objectFit: "cover",
                            border: "1px solid #ccc",
                          }}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "https://i.imgur.com/QWm0Z3L.png";
                          }}
                        />
                      </div>
                      <div
                        style={{
                          color:
                            checkApprove(p.id, staff.id) === null
                              ? "grey"
                              : checkApprove(p.id, staff.id) == true
                              ? "#39d46b"
                              : "red",
                          fontWeight: "400",
                        }}
                      >
                        {staff?.name}
                      </div>
                      {checkProcessItem(p.id, staff.id)}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const itemHistory = () => {
    return (
      <div>
        {(form?.histories ?? []).map((h) => {
          return (h.actions ?? []).map((a) => {
            return (
              <div className="d-flex align-items-center mb-2">
                <MdAccessTimeFilled color="grey"></MdAccessTimeFilled>
                <span>
                  <span style={{ fontWeight: "700", marginLeft: "5px" }}>{`${
                    a?.name ?? ""
                  }`}</span>
                  <span>
                    {a?.pivot?.is_approve === 1 
                      ? " đã duyệt yêu cầu"
                      : " đã từ chối yêu cầu"}
                  </span>
                </span>
                {a?.pivot?.handled_at !== null ? (
                  <div className="flex-grow-1" style={{ textAlign: "end" }}>
                    {moment(a?.pivot?.handled_at).format(
                      constants.FORMAT_DD_MM_YYYY_HH_MM
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            );
          });
        })}
        <div className="d-flex align-items-center mb-2">
          <MdAccessTimeFilled color="grey"></MdAccessTimeFilled>
          <span>
            <span style={{ fontWeight: "700", marginLeft: "5px" }}>{`${
              form?.staff?.name ?? ""
            } `}</span>
            <span>đã tạo yêu cầu</span>
          </span>
          {form?.created_at !== null ? (
            <div className="flex-grow-1" style={{ textAlign: "end" }}>
              {moment
                .utc(form?.created_at)
                .format(constants.FORMAT_DD_MM_YYYY_HH_MM)}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const getTypeUniform = (type) => {
    switch (type) {
      case 0:
        return "Áo bếp đen M";
      case 1:
        return "Áo bếp đen L";
      case 2:
        return "Áo bếp đen XL";
      case 3:
        return "Áo bếp đen 2XL";
      case 4:
        return "Áo bếp đen 3XL";
      case 5:
        return "Áo polo M (Nữ)";
      case 6:
        return "Áo polo L (Nữ)";
      case 7:
        return "Áo polo XL (Nữ)";
      case 8:
        return "Áo polo 2XL (Nữ)";
      case 9:
        return "Áo polo 3XL (Nữ)";
      case 10:
        return "Áo polo M (Nam)";
      case 11:
        return "Áo polo L (Nam)";
      case 12:
        return "Áo polo XL (Nam)";
      case 13:
        return "Áo polo 2XL (Nam)";
      case 14:
        return "Áo polo 3XL (Nam)";
      case 15:
        return "Áo vàng M";
      case 16:
        return "Áo vàng M";
      case 17:
        return "Áo vàng L";
      case 18:
        return "Áo vàng XL";
      case 19:
        return "Áo vàng 2XL";
      case 20:
        return "Áo vàng 3XL";
      case 21:
        return "Bảng tên nhựa";
      case 22:
        return "Bảng tên QLNH";
      case 23:
        return "Bảng tên CHT";
      case 24:
        return "Thẻ đeo";
      case 25:
        return "Tạp dề";
      case 26:
        return "Nón bếp";
      default:
        return "Nón bếp";
    }
  };

  const infoForm = () => {
    var formData;
    if (form?.content != null && form?.content != "") {
      formData = JSON.parse(form?.content);
    }

    var type = form?.type;
    switch (type) {
      case constants.TYPE_PROPOSE_TRANSFER:
        return (
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Đề xuất nhân viên
              </div>
              <b>{form?.staff_propose?.name ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>
                {areas.data?.filter(
                  (area) => area.id == form?.staff_propose?.area_id
                )[0]?.name ?? "---:---"}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Phòng ban
              </div>
              <b>
                {departmentsInput != null
                  ? departmentsInput.filter((v) => {
                      return (
                        v.value == form?.staff_propose?.department_id ?? ""
                      );
                    })[0]?.label ?? "---:---"
                  : "---:---"}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chức vụ
              </div>
              <b>{form?.staff_propose?.position ?? "---:---"}</b>
            </div>
            <div
              className="row"
              style={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "5px",
                margin: "5px",
              }}
            >
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Ngày điều chuyển
                </div>
                <b>{moment(form.date ?? Date.now()).format("DD-MM-YYYY")}</b>
              </div>
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Chi nhánh chuyển
                </div>
                <b>{formData?.area_new ?? "---:---"}</b>
              </div>
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Phòng ban chuyển
                </div>
                <b>{formData?.department_new ?? "---:---"}</b>
              </div>
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Mức lương điều chuyển
                </div>
                <b>{formatMoneyVND(formData?.money_new ?? 0)}</b>
              </div>
            </div>
            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );

      case constants.TYPE_PROPOSE_RESIGNATION:
        return (
          <div className="row">
            <div
              className="row"
              style={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "5px",
                margin: "5px",
              }}
            >
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Ngày nghỉ việc
                </div>
                <b>
                  {moment(formData?.date_resignation, "YYYY-MM-DD").format(
                    constants.FORMAT_DD_MM_YYYY
                  )}
                </b>
              </div>
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Người bàn giao
                </div>
                <b>{formData?.staff_handover ?? "---:---"}</b>
              </div>
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Lý do
                </div>
                <b>{formData?.reason ?? "---:---"}</b>
              </div>
            </div>
            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );

      case constants.TYPE_BUSINESS_TRIP:
        return (
          <div
            style={{
              backgroundColor: "#f2f7ff",
            }}
            className="pt-3 px-3"
          >
            <div className="row">
              <div className="col-4 pb-3">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Địa điểm công tác
                </div>
                <b>{form?.another ?? "---:---"}</b>
              </div>
              <div className="col-4 pb-3">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Thời gian bắt đầu
                </div>
                <b>
                  {form?.from === null
                    ? "---:---"
                    : moment(form?.from).format(
                        constants.FORMAT_DD_MM_YYYY_HH_MM
                      )}
                </b>
              </div>
              <div className="col-4 pb-3">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Thời gian kết thúc
                </div>
                <b>
                  {form?.to === null
                    ? "---:---"
                    : moment(form?.to).format(
                        constants.FORMAT_DD_MM_YYYY_HH_MM
                      )}
                </b>
              </div>

              {form?.timekeeping != null ? (
                <div className="col-4 pb-3">
                  <div
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    Ca làm
                  </div>
                  <b>{form?.timekeeping?.name ?? "---:---"}</b>
                </div>
              ) : (
                <></>
              )}
              <div className="col-12 pb-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Lý do
                </div>
                <b>{form?.reason ?? "---:---"}</b>
              </div>
              <div className="col-12 pb-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Quy trình duyệt
                </div>
                <b>{form?.procedure?.title ?? "---:---"}</b>
              </div>
            </div>
          </div>
        );

      case constants.TYPE_PROPOSE_RECRUITMENT:
        return (
          <div className="row">
            <div
              className="row"
              style={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "5px",
                margin: "5px",
              }}
            >
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Chi nhánh
                </div>
                <b>{form?.area?.name ?? "---:---"}</b>
              </div>
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Phòng ban
                </div>
                <b>{formData?.department ?? "---:---"}</b>
              </div>
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Ca làm việc
                </div>
                <b>{formData?.shifts ?? "---:---"}</b>
              </div>
              <div className="col-4 pt-2">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Số lượng nhân viên
                </div>
                <b>{formData?.quantity ?? "---:---"}</b>
              </div>
              <div className="col-4 pt-2">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Lý do
                </div>
                <b>{form?.reason ?? "---:---"}</b>
              </div>
            </div>
            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );

      case constants.TYPE_PROPOSE_UNIFORM:
        return (
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>{form?.area?.name ?? "--:--"}</b>
            </div>
            <span className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Thông tin đồng phục:
              </div>
              {(form?.products ?? []).map((e) => {
                return (
                  <div
                    className="row"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      margin: "5px",
                      padding: "5px",
                    }}
                  >
                    <span className="col">{getTypeUniform(e.type)}</span>
                    <span className="col-2">
                      SL: {e?.quantity ?? "---:---"}
                    </span>
                    <span className="col">
                      Đối tượng nhận: {e.staff?.name ?? "--:--"}
                    </span>
                    <span className="col">Ghi chú: {e?.note ?? "--:--"}</span>
                  </div>
                );
              })}
            </span>
            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );

      case constants.TYPE_PROPOSE_DISCIPLINE:
        return (
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Đề xuất nhân viên
              </div>
              <b>{form?.staff_propose?.name ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>
                {areas.data?.filter(
                  (area) => area.id == form?.staff_propose?.area_id
                )[0]?.name ?? "---:---"}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Phòng ban
              </div>
              <b>
                {departmentsInput != null
                  ? departmentsInput.filter((v) => {
                      return (
                        v.value == form?.staff_propose?.department_id ?? ""
                      );
                    })[0]?.label ?? "---:---"
                  : "---:---"}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chức vụ
              </div>
              <b>{form?.staff_propose?.position ?? "---:---"}</b>
            </div>
            <div
              className="row"
              style={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "5px",
                margin: "5px",
              }}
            >
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Nội dung kỷ luật
                </div>
                <b>{formData?.content ?? "---:---"}</b>
              </div>
            </div>
            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );

      case constants.TYPE_PROPOSE_ADVANCEMENT:
        return (
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Đề xuất nhân viên
              </div>
              <b>{form?.staff_propose?.name ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>
                {areas.data?.filter(
                  (area) => area.id == form?.staff_propose?.area_id
                )[0]?.name ?? "---:---"}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Phòng ban
              </div>
              <b>
                {departmentsInput != null
                  ? departmentsInput.filter((v) => {
                      return (
                        v.value == form?.staff_propose?.department_id ?? ""
                      );
                    })[0]?.label ?? "---:---"
                  : "---:---"}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chức vụ
              </div>
              <b>{form?.staff_propose?.position ?? "---:---"}</b>
            </div>
            <div
              className="row"
              style={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "5px",
                margin: "5px",
              }}
            >
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Ngày áp dụng
                </div>
                <b>
                  {" "}
                  {moment(form?.date, "YYYY-MM-DD").format(
                    constants.FORMAT_DD_MM_YYYY
                  )}
                </b>
              </div>
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Vị trí cũ
                </div>
                <b>{formData?.position_old ?? "---:---"}</b>
              </div>
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Mức lương cũ
                </div>
                <b>{formatMoneyVND(formData?.money_old ?? 0)}</b>
              </div>

              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Vị trí mới
                </div>
                <b>{formData?.position_new ?? "---:---"}</b>
              </div>
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Mức lương mới
                </div>
                <b>{formatMoneyVND(formData?.money_new ?? 0)}</b>
              </div>
            </div>
            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );

      case constants.TYPE_PROPOSE_REWARD:
        return (
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Đề xuất nhân viên
              </div>
              <b>{form?.staff_propose?.name ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>
                {areas.data?.filter(
                  (area) => area.id == form?.staff_propose?.area_id
                )[0]?.name ?? "---:---"}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Phòng ban
              </div>
              <b>
                {departmentsInput != null
                  ? departmentsInput.filter((v) => {
                      return (
                        v.value == form?.staff_propose?.department_id ?? ""
                      );
                    })[0]?.label ?? "---:---"
                  : "---:---"}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chức vụ
              </div>
              <b>{form?.staff_propose?.position ?? "---:---"}</b>
            </div>
            <div
              className="row"
              style={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "5px",
                margin: "5px",
              }}
            >
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Số tiền thưởng
                </div>
                <b>{formatMoneyVND(formData?.price ?? 0)}</b>
              </div>
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Nội dung thưởng
                </div>
                <b>{formData?.content ?? "---:---"}</b>
              </div>
            </div>
            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );

      case constants.TYPE_SUPPORT:
        return (
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Đề xuất nhân viên
              </div>
              <b>{form?.staff_propose?.name ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>{form.area?.name ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Phòng ban
              </div>
              <b>{formData?.department ?? "---:---"}</b>
            </div>

            <div
              className="row"
              style={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "5px",
                margin: "5px",
              }}
            >
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Loại phụ cấp
                </div>
                <b>{formData?.support_type ?? "--:--"}</b>
              </div>
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Mức phụ cấp
                </div>
                <b>{`${form.support?.title ?? ""} - ${formatMoneyVND(
                  form.support?.value ?? 0
                )}đ`}</b>
              </div>
            </div>
            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );

      case constants.TYPE_PROPOSE_ADJUST_SALARY:
        return (
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Đề xuất nhân viên
              </div>
              <b>{form?.staff_propose?.name ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>
                {areas.data?.filter(
                  (area) => area.id == form?.staff_propose?.area_id
                )[0]?.name ?? "---:---"}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Phòng ban
              </div>
              <b>
                {departmentsInput != null
                  ? departmentsInput.filter((v) => {
                      return (
                        v.value == form?.staff_propose?.department_id ?? ""
                      );
                    })[0]?.label ?? "---:---"
                  : "---:---"}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chức vụ
              </div>
              <b>{form?.staff_propose?.position ?? "---:---"}</b>
            </div>
            <div
              className="row"
              style={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "5px",
                margin: "5px",
              }}
            >
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Mức lương cũ
                </div>
                <b>{formatMoneyVND(formData?.money_old ?? 0)}</b>
              </div>
              <div className="col-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Mức lương mới
                </div>
                <b>{formatMoneyVND(formData?.money_new ?? 0)}</b>
              </div>
            </div>
            <div className="col-12 pb-4">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Quy trình duyệt
              </div>
              <b>{form?.procedure?.title ?? "---:---"}</b>
            </div>
          </div>
        );

      default:
        return (
          <div
            style={{
              backgroundColor: "#f2f7ff",
            }}
            className="pt-3 px-3"
          >
            <div className="row">
              <div className="col-4 pb-3">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Mã đơn
                </div>
                <b>{form?.id ?? "---:---"}</b>
              </div>
              <div className="col-4 pb-3">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Người tạo
                </div>
                <b>{form?.staff?.name ?? "---:---"}</b>
              </div>
              <div className="col-4 pb-3">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Thời gian bắt đầu
                </div>
                <b>
                  {form?.from === null
                    ? "---:---"
                    : moment(form?.from).format(
                        constants.FORMAT_DD_MM_YYYY_HH_MM
                      )}
                </b>
              </div>
              <div className="col-4 pb-3">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Thời gian kết thúc
                </div>
                <b>
                  {form?.to === null
                    ? "---:---"
                    : moment(form?.to).format(
                        constants.FORMAT_DD_MM_YYYY_HH_MM
                      )}
                </b>
              </div>

              {form?.timekeeping != null ? (
                <div className="col-4 pb-3">
                  <div
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    Ca làm
                  </div>
                  <b>{form?.timekeeping?.name ?? "---:---"}</b>
                </div>
              ) : (
                <></>
              )}
              <div className="col-12 pb-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Lý do
                </div>
                <b>{form?.reason ?? "---:---"}</b>
              </div>
              <div className="col-12 pb-4">
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Quy trình duyệt
                </div>
                <b>{form?.procedure?.title ?? "---:---"}</b>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <Modal
      title={form?.title ?? "Thông tin yêu cầu"}
      centered
      open={visible}
      width={"70%"}
      style={{ top: 20 }}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div
        className="mt-4"
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "70vh",
          margin: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "#f2f7ff",
          }}
          className="pt-3 px-3"
        >
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Người yêu cầu
              </div>
              <b>{form?.staff?.name ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Ngày tạo
              </div>
              <b>
                {form?.created_at === null
                  ? "---:---"
                  : moment
                      .utc(form?.created_at)
                      .format(constants.FORMAT_DD_MM_YYYY_HH_MM)}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>
                {areas.data?.filter(
                  (area) => area.id == form?.staff?.area_id
                )[0]?.name ?? "---:---"}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div>
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Phòng ban
                </div>
                <b>
                  {departmentsInput != null
                    ? departmentsInput.filter((v) => {
                        return v.value == form?.staff?.department_id ?? "";
                      })[0]?.label ?? "---:---"
                    : "---:---"}
                </b>
              </div>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Mã phiếu
              </div>
              <b>{form?.id ?? "---:---"}</b>
            </div>
          </div>
        </div>
        <div style={{ height: "10px" }}></div>
        <div
          style={{
            backgroundColor: "#f2f7ff",
          }}
          className="pt-3 px-3"
        >
          {infoForm()}
        </div>
        {(form?.images ?? []).length > 0 ? (
          <div>
            <div className="mt-2" style={{ fontWeight: "700" }}>
              Hình ảnh đính kèm:
            </div>
            <div className="d-flex" style={{ flexWrap: "wrap" }}>
              <Image.PreviewGroup
                preview={{
                  onChange: (current, prev) =>
                    console.log(
                      `current index: ${current}, prev index: ${prev}`
                    ),
                }}
              >
                {(form?.images ?? []).map((e) => {
                  return (
                    <div className="my-3 col-4">
                      <Image
                        src={e ?? ""}
                        height={300}
                        style={{
                          borderRadius: "5px",
                          objectFit: "cover",
                          border: "1px solid #ccc",
                          marginRight: "16px",
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://admin.comnieuthienly.vn/images/logo.jpg";
                        }}
                      ></Image>
                    </div>
                  );
                })}
              </Image.PreviewGroup>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="py-3" style={{ fontSize: "15px", fontWeight: "700" }}>
          Người duyệt
        </div>
        {itemProcess()}
        <div
          className="my-4"
          style={{ height: "1px", backgroundColor: "#f2f7ff", width: "100%" }}
        ></div>
        {itemHistory()}
        <div className="my-4"></div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Đóng
        </button>
        {form?.status === 1 && checkPermission(form?.process) ? (
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                borderColor: "#f6c23e",
                borderWidth: "1px",
                borderStyle: "solid",
                color: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                acceptOrCancelMany({ isApprove: false });
              }}
            >
              Từ chối
            </button>
            <div style={{ width: "10px" }}></div>
            <button
              type="button"
              style={{
                backgroundColor: "#f6c23e",
                borderRadius: "8px",
                color: "white",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                acceptOrCancelMany({ isApprove: true });
              }}
            >
              Chấp nhận
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};

export default FormModal;
