import { constants as c } from "../constants";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
const companyId =
  localStorage.getItem("companyId") == "undefined" ||
  localStorage.getItem("companyId") == null
    ? 1
    : JSON.parse(localStorage.getItem("companyId"));

function accountLogin(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'company-id': localStorage.getItem("companyId") == "undefined" ||
      localStorage.getItem("companyId") == null
        ? 1
        : JSON.parse(localStorage.getItem("companyId")),
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/login`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function accountUpdate(info) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/profile`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function accountChangePw(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/change_password`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getUserInfo(info) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(`${c.API_URL}/profile`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getReportNumber(areaId) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(`${c.API_URL}/personnel/${areaId}/report/number`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getReportNumberManager() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(
    `${c.API_URL}/personnel/manager/all/report-number`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function forgotPassword(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/forget_password`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function checkPhoneEmail(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/check_phone_email`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getPassword(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/get_password`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getListCompany(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/multi_company_exists`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

export const userServices = {
  getListCompany,
  accountLogin,
  accountUpdate,
  accountChangePw,
  getUserInfo,
  forgotPassword,
  getPassword,
  checkPhoneEmail,
  getReportNumber,
  getReportNumberManager,
};
