const initialState = {
    listCompany: [],
  };
  
  export function company(state = initialState, action) {
    switch (action.type) {
      case "SET_LIST_COMPANY_SUCCESS":
        return {
          ...state,
          listCompany: action.payload,
        };
      default:
        return state;
    }
  }
  
  