/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { procedureServices } from "../../../../services/procedureServices";
import moment from "moment";
import { constants } from "../../../../constants";
import { MdAccessTimeFilled } from "react-icons/md";
import { formatMoneyVND } from "../../../../helpers";
import { toast } from "react-toastify";
import { Button, Divider, Image, Input, Modal, Popconfirm, Space } from "antd";
import { probationServices } from "../../../../services/probationServices";

const FormProbationModal = ({
  visible,
  formId,
  closeModal,
  onSuccess,
  departmentsInput,
}) => {
  const areas = useSelector((state) => state.area.areas);
  const reportNumber = useSelector((state) => state.reportNumber.report);

  const dispatch = useDispatch();

  const [form, setForm] = useState(null);
  const [reason, setReason] = useState(null);
  const [listQuestionForm, setListQuestionForm] = useState([]);

  useEffect(() => {
    if (visible == true) {
      getFormDetail({ id: formId });
    }
  }, [formId]);

  useEffect(() => {
    console.log("listQuestionForm", listQuestionForm);
  }, [listQuestionForm]);

  const checkPermission = (process) => {
    const index = (process?.approvers ?? []).findIndex(
      (e) => e.id === reportNumber.current_user?.id
    );
    if (index != -1) {
      return true;
    } else {
      return false;
    }
  };

  const acceptOrCancelMany = async ({ isApprove }) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await probationServices.acceptOrCancelMany({
        data: {
          is_approve: isApprove,
          list_form_id: [form?.id],
        },
      });
      closeModal();
      onSuccess();
      if (response.code != 200) {
        return;
      }
      toast.success(isApprove == true ? "Đã xác nhận" : "Đã từ chối");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const getFormDetail = async ({ id }) => {
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.SHOW_LOADING,
    });
    try {
      const res = await probationServices.getFormDetail({
        id: id,
      });

      setForm(res.data);
      setListQuestionForm([]);

      var listQuestionFormData = [];

      (res.data?.evaluation_question ?? []).forEach((element) => {
        const index = listQuestionFormData.findIndex(
          (element2) => element2.category_name === element.category_name
        );

        if (index === -1) {
          listQuestionFormData.push({
            category_name: element.category_name,
            failed_number: element.failed_number,
            questions: [
              {
                question: element.question,
                is_completed: element.is_completed,
              },
            ],
          });
        } else {
          listQuestionFormData[index].questions.push({
            question: element.question,
            is_completed: element.is_completed,
          });
        }

        console.log("listQuestionFormData", listQuestionFormData);
      });

      setListQuestionForm(listQuestionFormData);
      console.log(listQuestionForm);
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.NONE_LOADING,
    });
  };

  function checkApprove(processId, staffId) {
    var history = form?.histories ?? [];

    if (history.length === 0) {
      return null;
    }

    var dataFind = history.filter((e) => {
      console.log("sss");
      return e?.process_id === processId;
    });

    if (dataFind?.length === 0) {
      return null;
    }

    if ((dataFind[0]?.actions ?? []).length === 0) {
      return null;
    }

    var staffApprove = dataFind[0]?.actions.filter((e) => {
      return e?.id === staffId;
    });

    if ((staffApprove ?? []).length == 0) {
      return null;
    }

    if ((staffApprove ?? []).length !== 0) {
      return staffApprove[0]?.pivot?.is_approve === 1 ? true : false;
    }

    return null;
  }

  const checkProcessItem = (processId, staffId) => {
    const check = checkApprove(processId, staffId);
    if (check === null) return;
    return (
      <div
        className="px-2 py-1 mx-5"
        style={{
          border: `1px solid ${check === true ? "#39d46b" : "red"}`,
          borderRadius: "10px",
          color: `${check === true ? "#39d46b" : "red"}`,
          transform: "rotate(-10deg)",
        }}
      >
        {check === true ? "Đã duyệt" : check === false ? "Từ chối" : ""}
      </div>
    );
  };

  const itemProcess = () => {
    return (
      <div>
        {(form?.list_process ?? []).map((p, index) => {
          return (
            <div className="d-flex mb-3 ">
              <div
                style={{
                  backgroundColor: "#f2f7ff",
                  borderLeft: "2px solid #1ccc78",
                  padding: "4px",
                  height: "20px",
                  color: "#1ccc78",
                  alignItems: "center",
                  display: "flex",
                  marginRight: "15px",
                  fontWeight: "700",
                }}
              >
                {index + 1}
              </div>
              <div className="flex-shrink-1 px-2">
                {(p?.approvers ?? []).map((staff) => {
                  return (
                    <div className="d-flex align-items-center mb-2">
                      <div className="mr-2">
                        <img
                          src={staff?.avatar ?? ""}
                          style={{
                            width: "35px",
                            height: "35px",
                            borderRadius: "20%",
                            objectFit: "cover",
                            border: "1px solid #ccc",
                          }}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "https://i.imgur.com/QWm0Z3L.png";
                          }}
                        />
                      </div>
                      <div
                        style={{
                          color:
                            checkApprove(p.id, staff.id) === null
                              ? "grey"
                              : checkApprove(p.id, staff.id) == true
                              ? "#39d46b"
                              : "red",
                          fontWeight: "400",
                        }}
                      >
                        {staff?.name}
                      </div>
                      {checkProcessItem(p.id, staff.id)}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const itemHistory = () => {
    return (
      <div>
        {(form?.histories ?? []).map((h) => {
          return (h.actions ?? []).map((a) => {
            return (
              <div className="d-flex align-items-center mb-2">
                <MdAccessTimeFilled color="grey"></MdAccessTimeFilled>
                <span>
                  <span style={{ fontWeight: "700", marginLeft: "5px" }}>{`${
                    a?.name ?? ""
                  }`}</span>
                  <span>
                    {a?.pivot?.is_approve === 1
                      ? " đã duyệt yêu cầu"
                      : " đã từ chối yêu cầu"}
                  </span>
                </span>
                {a?.pivot?.handled_at !== null ? (
                  <div className="flex-grow-1" style={{ textAlign: "end" }}>
                    {moment(a?.pivot?.handled_at).format(
                      constants.FORMAT_DD_MM_YYYY_HH_MM
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            );
          });
        })}
        <div className="d-flex align-items-center mb-2">
          <MdAccessTimeFilled color="grey"></MdAccessTimeFilled>
          <span>
            <span style={{ fontWeight: "700", marginLeft: "5px" }}>{`${
              form?.staff?.name ?? ""
            } `}</span>
            <span>đã tạo yêu cầu</span>
          </span>
          {form?.created_at !== null ? (
            <div className="flex-grow-1" style={{ textAlign: "end" }}>
              {moment
                .utc(form?.created_at)
                .format(constants.FORMAT_DD_MM_YYYY_HH_MM)}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  function checkIsCompleted() {
    let countFailedTotal = 0;

    for (let i = 0; i < listQuestionForm.length; i++) {
      for (let j = 0; j < listQuestionForm[i].questions.length; j++) {
        if (
          listQuestionForm[i].questions[j].is_completed === null ||
          listQuestionForm[i].questions[j].is_completed === undefined
        ) {
          return null;
        }
      }

      let failedNumber = listQuestionForm[i].failed_number;

      let count = listQuestionForm[i].questions.filter(
        (element) => element.is_completed === false
      ).length;
      countFailedTotal += count;

      if (count >= failedNumber && failedNumber !== 0) {
        return false;
      }
    }

    if (
      countFailedTotal >= (form.failed_number || 0) &&
      (form.failed_number || 0) !== 0
    ) {
      return false;
    }

    return true;
  }

  const infoForm = () => {
    return listQuestionForm.map((e) => {
      return (
        <div>
          <div className="row">
            <div className="col-12 pb-3 d-flex justify-content-between">
              <b className="mr-2">{e.category_name ?? ""}:</b>
              <span style={{ color: "red" }}>{`giới hạn không đạt ${
                e.failed_number ?? ""
              }`}</span>
            </div>
            <div className="col-12">
              {(e.questions ?? []).map((q) => {
                return (
                  <div>
                    <div className="d-flex my-3">
                      <div className="col-9">
                        <div>{q.question}</div>
                      </div>
                      <div className="col-3">
                        <Space>
                          <Button
                            type={
                              q.is_completed == false ? "primary" : "default"
                            }
                            onClick={(e) => {
                              if (form?.is_completed == true) {
                                return;
                              }
                              setListQuestionForm((prevList) => {
                                const newList = [...prevList];
                                newList.forEach((element) => {
                                  element.questions.forEach((element2) => {
                                    if (element2.question === q.question) {
                                      element2.is_completed = false;
                                    }
                                  });
                                });
                                return newList;
                              });
                            }}
                          >
                            Không đạt
                          </Button>
                          <Button
                            type={
                              q.is_completed == true ? "primary" : "default"
                            }
                            onClick={(e) => {
                              if (form?.is_completed == true) {
                                return;
                              }
                              setListQuestionForm((prevList) => {
                                const newList = [...prevList];
                                newList.forEach((element) => {
                                  element.questions.forEach((element2) => {
                                    if (element2.question === q.question) {
                                      element2.is_completed = true;
                                    }
                                  });
                                });
                                return newList;
                              });
                            }}
                          >
                            Đạt
                          </Button>
                        </Space>
                      </div>
                    </div>
                    <Divider></Divider>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    });
  };

  const submitForm = async () => {
    var questionReq = [];

    listQuestionForm.forEach((element) => {
      element.questions.forEach((element2) => {
        questionReq.push({
          question: element2.question,
          is_completed: element2.is_completed,
          category_name: element.category_name,
          failed_number: element.failed_number,
        });
      });
    });

    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await probationServices.submitForm({
        formId: form?.id,
        data: {
          is_completed: checkIsCompleted(),
          evaluation_question: questionReq,
          reason: reason,
        },
      });
      closeModal();
      onSuccess();
      if (response.code != 200) {
        return;
      }
      toast.success(checkIsCompleted() == true ? "Đã xác nhận" : "Đã từ chối");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  return (
    <Modal
      title={form?.title ?? "Thông tin yêu cầu"}
      centered
      open={visible}
      width={"70%"}
      style={{ top: 20 }}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div
        className="mt-4"
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "70vh",
          margin: "10px",
        }}
      >
        <div
          style={{
            backgroundColor:
              form?.is_reviewed == false
                ? "#6b9dd6"
                : form?.is_completed == true
                ? "#57b35b"
                : "#cb5146",
          }}
          className="py-3 px-3 mb-3"
        >
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {form?.is_reviewed == false
              ? "ĐANG CHỜ ĐÁNH GIÁ"
              : form?.is_completed == true
              ? "ĐÃ ĐẠT YÊU CẦU"
              : "CHƯA ĐẠT YÊU CẦU"}
          </span>
        </div>
        <p
          style={{
            fontWeight: "bold",
          }}
        >{`${form?.staff_review?.name ?? ""} đánh giá nhân viên:`}</p>
        <div
          style={{
            backgroundColor: "#f2f7ff",
          }}
          className="pt-3 px-3 mt-3"
        >
          <div className="row">
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Người bị đánh giá
              </div>
              <b>{form?.staff?.name ?? "---:---"}</b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Ngày tạo
              </div>
              <b>
                {form?.created_at === null
                  ? "---:---"
                  : moment
                      .utc(form?.created_at)
                      .format(constants.FORMAT_DD_MM_YYYY_HH_MM)}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Chi nhánh
              </div>
              <b>
                {areas.data?.filter(
                  (area) => area.id == form?.staff?.area_id
                )[0]?.name ?? "---:---"}
              </b>
            </div>
            <div className="col-4 pb-3">
              <div>
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Phòng ban
                </div>
                <b>
                  {departmentsInput != null
                    ? departmentsInput.filter((v) => {
                        return v.value == form?.staff?.department_id ?? "";
                      })[0]?.label ?? "---:---"
                    : "---:---"}
                </b>
              </div>
            </div>
            <div className="col-4 pb-3">
              <div
                style={{
                  fontSize: "13px",
                }}
              >
                Mã phiếu
              </div>
              <b>{form?.id ?? "---:---"}</b>
            </div>
          </div>
        </div>
        <div style={{ height: "15px" }}></div>
        <div className="d-flex justify-content-between align-items-center">
          <p>TIÊU CHÍ ĐÁNH GIÁ:</p>
          <p
            style={{ color: "red", paddingRight: 10 }}
          >{`tổng giới hạn không đạt ${form?.failed_number ?? 0}`}</p>
        </div>
        <div
          style={{
            backgroundColor: "#f2f7ff",
          }}
          className="pt-3 px-3 mt-3"
        >
          {infoForm()}
        </div>
        <div style={{ height: "15px" }}></div>
        <div className="d-flex justify-content-between align-items-center">
          <p>GHI CHÚ ĐÁNH GIÁ:</p>
        </div>
        <div style={{ height: "15px" }}></div>
        <Input
          placeholder="Ghi chú"
          onChange={(e) => {
            setReason(e.target.value);
          }}
        />
        {(form?.images ?? []).length > 0 ? (
          <div>
            <div className="mt-2" style={{ fontWeight: "700" }}>
              Hình ảnh đính kèm:
            </div>
            <div className="d-flex" style={{ flexWrap: "wrap" }}>
              <Image.PreviewGroup
                preview={{
                  onChange: (current, prev) =>
                    console.log(
                      `current index: ${current}, prev index: ${prev}`
                    ),
                }}
              >
                {(form?.images ?? []).map((e) => {
                  return (
                    <div className="my-3 col-4">
                      <Image
                        src={e ?? ""}
                        height={300}
                        style={{
                          borderRadius: "5px",
                          objectFit: "cover",
                          border: "1px solid #ccc",
                          marginRight: "16px",
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://admin.comnieuthienly.vn/images/logo.jpg";
                        }}
                      ></Image>
                    </div>
                  );
                })}
              </Image.PreviewGroup>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="py-3" style={{ fontSize: "15px", fontWeight: "700" }}>
          Người duyệt
        </div>
        {itemProcess()}
        <div
          className="my-4"
          style={{ height: "1px", backgroundColor: "#f2f7ff", width: "100%" }}
        ></div>
        {itemHistory()}
        <div className="my-4"></div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Đóng
        </button>
        {form?.is_reviewed == false &&
        form?.staff_review?.id == reportNumber?.current_user?.id ? (
          <Popconfirm
            title="Xác nhận đánh giá"
            description={`Nhân viên vừa đánh giá ${
              checkIsCompleted() == true ? "`ĐẠT`" : "`KHÔNG ĐẠT`"
            } yêu cầu. Bạn có chắc chắn muốn xác nhận không ?`}
            onConfirm={(e) => {
              console.log("confirm");
              var check = checkIsCompleted();
              console.log("check", check);
              submitForm();
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
            disabled={checkIsCompleted() == null}
          >
            <Button
              type="primary"
              onClick={(e) => {
                if (checkIsCompleted() != null) {
                  return;
                }
                var check = checkIsCompleted();
                console.log(check);
                if (check == null) {
                  toast.error(
                    "Vui lòng hoàn thành tất cả các tiêu chí đánh giá"
                  );
                } else {
                  console.log(listQuestionForm);
                }
              }}
            >
              Xác nhận
            </Button>
          </Popconfirm>
        ) : form?.status === 1 && checkPermission(form?.process) ? (
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                borderColor: "#f6c23e",
                borderWidth: "1px",
                borderStyle: "solid",
                color: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                acceptOrCancelMany({ isApprove: false });
              }}
            >
              Từ chối
            </button>
            <div style={{ width: "10px" }}></div>
            <button
              type="button"
              style={{
                backgroundColor: "#f6c23e",
                borderRadius: "8px",
                color: "white",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                acceptOrCancelMany({ isApprove: true });
              }}
            >
              Chấp nhận
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};

export default FormProbationModal;
