import { constants as c } from "../constants";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
const companyId =
  localStorage.getItem("companyId") == "undefined" ||
  localStorage.getItem("companyId") == null
    ? 1
    : JSON.parse(localStorage.getItem("companyId"));

function getAllEvaluation(queryString = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(`${c.API_URL}/termquiz_evaluates${queryString}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      // window.location.href = "/"
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getEvaluationInfo(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(`${c.API_URL}/termquiz_evaluates/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getAllBadges() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(`${c.API_URL}/badges`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function createEvaluation(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(info),
  };

  return fetch(`${c.API_URL}/termquiz_evaluates`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function deleteEvaluation(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(`${c.API_URL}/termquiz_evaluates/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function updateEvaluation(id, info) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/termquiz_evaluates/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getAllStaffExams(queryString) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(`${c.API_URL}/manager_staffs${queryString}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function createMultiStaffs(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/mutil_manager_staffs`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function deleteStaffExam(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(`${c.API_URL}/manager_staffs/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function deleteMultiStaffExam(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/list_delete_manager_staff`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

/// new

function getQCEvaluateMain() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(`${c.API_URL}/qc/qc-evaluate-main`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function createQCEvaluateMain(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/qc/qc-evaluate-main`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function updateQCEvaluateMain(id, data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };
  return fetch(`${c.API_URL}/qc/qc-evaluate-main/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function deleteQCEvaluateMain(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(`${c.API_URL}/qc/qc-evaluate-main/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}


function getQCEvaluateGroup(mainId) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(`${c.API_URL}/qc/qc-evaluate-group?qc_evaluate_main_id=${mainId}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function createQCEvaluateGroup(info) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(info),
  };
  return fetch(`${c.API_URL}/qc/qc-evaluate-group`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function updateQCEvaluateGroup(id, data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };
  return fetch(`${c.API_URL}/qc/qc-evaluate-group/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function deleteQCEvaluateGroup(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(`${c.API_URL}/qc/qc-evaluate-group/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getQCEvaluate(qc_evaluate_group_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(
    `${c.API_URL}/qc/qc-evaluate?qc_evaluate_group_id=${qc_evaluate_group_id}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function createQCEvaluate(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };
  return fetch(`${c.API_URL}/qc/qc-evaluate`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function updateQCEvaluate(data) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };
  return fetch(`${c.API_URL}/qc/qc-evaluate`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function deleteQCEvaluate(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(`${c.API_URL}/qc/qc-evaluate/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

export const evaluationServices = {
  getAllEvaluation,
  getEvaluationInfo,
  createEvaluation,
  deleteEvaluation,
  updateEvaluation,
  getAllBadges,
  getAllStaffExams,
  createMultiStaffs,
  deleteStaffExam,
  deleteMultiStaffExam,

  getQCEvaluateGroup,
  createQCEvaluateGroup,
  updateQCEvaluateGroup,
  deleteQCEvaluateGroup,

  getQCEvaluate,
  createQCEvaluate,
  updateQCEvaluate,
  deleteQCEvaluate,

  getQCEvaluateMain,
  createQCEvaluateMain,
  updateQCEvaluateMain,
  deleteQCEvaluateMain,
};
