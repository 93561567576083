/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "rsuite";
import { constants } from "../../../../constants";
import { shiftServices } from "../../../../services/shiftServices";

import StaffModal from "./StaffModal";
import ItemStaff from "../../Salary/child/ItemStaff";
import ItemStaffReset from "./ItemStaffReset";
import { toast } from "react-toastify";
import { Modal } from "antd";

const FORMAT_DATE = "MM-yyyy";
const ResetDataStaffModal = ({ visible, salary, onSuccess, closeModal }) => {
  const dispatch = useDispatch();
  const areaId = useSelector((state) => state.area.areaId);

  const [staffIds, setStaffIds] = useState([]);
  const [applyDate, setApplyDate] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  const [modal, setModal] = useState({
    staff: false,
    bonus: false,
  });

  const [dataTemp, setDataTemp] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    if (salary && visible) {
      setStaffIds([salary.staff_id]);
      setApplyDate({
        month: salary.month,
        year: salary.year,
      });
    }
  }, [salary]);

  const handleCloseModal = () => {
    setModal({
      staff: false,
      bonus: false,
    });
  };

  const handleSubmitStaffModal = (dataTemp) => {
    convertData(dataTemp);
    var data = dataTemp.map((e, index) => {
      return {
        staff_id: e,
        kpi_percent: 100,
      };
    });
    setStaffIds(data);
    handleCloseModal();
  };

  const handleChangeDate = (date) => {
    if (moment.isDate(date)) {
      setApplyDate({
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      });
    }
  };

  const handleSetDefaultForm = () => {
    setStaffIds([]);
    setEmployees([]);
    setApplyDate({
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    });
  };

  const handleResetDataStaff = async () => {
    console.log(employees);
    try {
      for (let index = 0; index < employees.length; index++) {
        if (employees[index]?.is_reality == false) {
          if (
            employees[index]?.count_day_work == 0 ||
            employees[index]?.count_day_work > 31
          ) {
            toast.error(
              `${employees[index]?.name} ngày công chuẩn không hợp lệ`
            );
            return;
          }

          if (
            employees[index]?.standard_working_hour == 0 ||
            employees[index]?.standard_working_hour > 24
          ) {
            toast.error(
              `${employees[index]?.name} Giờ công chuẩn không hợp lệ`
            );
            return;
          }

          if (employees[index].type_key == "full_time") {
            if (employees[index]?.BHXH == true) {
              if (
                employees[index]?.position_salary == 0 ||
                employees[index]?.position_salary == ""
              ) {
                toast.error(
                  `${employees[index]?.name} Lương vị trí không hợp lệ`
                );
                return;
              }
            }

            if (employees[index]?.tax == "") {
              toast.error(`${employees[index]?.name} Thuế không hợp lệ`);
              return;
            }
          } else {
            if (employees[index]?.salary == 0) {
              toast.error(
                `${employees[index]?.name} Lương theo giờ không hợp lệ`
              );
              return;
            }
          }
        }
      }

      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });

      const resetBody = {
        year: applyDate.year,
        month: applyDate.month,
        data_staff: employees.map((e) => {
          return {
            is_reality: e.is_reality ?? false,
            staff_id: e.staff_id,
            count_day_work: e.count_day_work.toString().replaceAll(".", ""),
            standard_working_hour: e.standard_working_hour
              .toString()
              .replaceAll(".", ""),
            support_productive: e.support_productive
              .toString()
              .replaceAll(".", ""),
            support_result: e.support_result.toString().replaceAll(".", ""),
            pc_food: e.pc_food.toString().replaceAll(".", ""),
            pc_phone: e.pc_phone.toString().replaceAll(".", ""),
            pc_move: e.pc_move.toString().replaceAll(".", ""),
            cong_doan: e?.cong_doan || false,
            type_key: e.type_key,
            //total_salary: e.total_salary.toString().replaceAll(".", ""),
            salary: e.salary.toString().replaceAll(".", ""),
            tax: e.tax ?? "CK",
            position_salary: e.position_salary.toString().replaceAll(".", ""),
            kpi_percent: e.kpi_percent,
            dependent_count: e.dependent_count,
            BHXH: e.BHXH,
          };
        }),
      };

      const response = await shiftServices.resetDataStaff({
        areaId: areaId,
        body: resetBody,
      });
      if (response.success === true) {
        closeModal();
        onSuccess();
        handleSetDefaultForm();
        toast.success(response.msg);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      console.log(error);
      toast.error("Lỗi hệ thống");
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const handleRemoveEmployee = (employeeId) => {
    const result = employees.filter(
      (employee) => employee.staff_id !== employeeId
    );
    setEmployees(result);
  };

  const customStyles = {
    content: {
      minWidth: "90%",
      height: "auto",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  const convertData = (dataTemp) => {
    const dataNew = dataTemp.map((e) => {
      if (!employees.map((emp) => emp.id).includes(e.id)) {
        return {
          staff_id: e.id,
          name: e.name,
          is_reality: e.is_reality ?? false,
          type_key: e.contract?.type_key ?? "",
          count_day_work: e.contract?.count_day_work ?? 0,
          standard_working_hour: 0,
          total_salary: formatNumberV2(e.contract?.total_salary ?? 0),
          support_productive: formatNumberV2(
            e.contract?.support_productive ?? 0
          ),
          support_result: formatNumberV2(e.contract?.support_result ?? 0),
          pc_food: formatNumberV2(e.contract?.pc_food ?? 0),
          pc_phone: formatNumberV2(e.contract?.pc_phone ?? 0),
          pc_move: formatNumberV2(e.contract?.pc_move ?? 0),
          salary: formatNumberV2(e.contract?.salary ?? 0),
          tax: e.contract?.tax ?? "CK",
          position_salary: formatNumberV2(e.contract?.position_salary ?? 0),
          kpi_percent: formatNumberV2(e.contract?.kpi_percent ?? 0),
          dependent_count: e.contract?.dependent_count ?? 0,
          BHXH: e.contract?.BHXH ?? false,
          cong_doan: e.contract?.cong_doan ?? false,
        };
      }
    });
    setEmployees(dataNew);
  };

  const formatNumberV2 = (str) => {
    if (str === undefined || str === null) return "";
    const strFormat = str
      .toString()
      .replace(/[A-Za-z`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, "");
    if (Number(strFormat) >= 1000) {
      return strFormat
        .split("")
        .reverse()
        .reduce((prev, next, index) => {
          return (index % 3 ? next : next + ".") + prev;
        });
    } else if (Number(strFormat) > 0 && Number(strFormat) < 1000) {
      return Number(strFormat);
    } else {
      return "";
    }
  };

  const handleData = (e, key, indexEmp) => {
    console.log(key);

    if (key == "BHXH") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                ["BHXH"]: e,
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
      return;
    }

    if (key == "cong_doan") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                ["cong_doan"]: e,
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
      return;
    }

    if (e == true || e == false) {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                ["is_reality"]: e,
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
      return;
    }

    if (e == "full_time") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                ["type_key"]: "full_time",
                ["salary"]: "0",
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
      return;
    }

    if (e == "part_time") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                ["type_key"]: "part_time",
                ["total_salary"]: "0",
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
      return;
    }

    if (e == "CK") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                ["tax"]: "CK",
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
      return;
    }

    if (e == "KT") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                ["tax"]: "KT",
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
      return;
    }

    if (e == "LT") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                ["tax"]: "LT",
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
      return;
    }

    const name = e.target.name;
    const value = e.target.value;

    if (name == "position_salary") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
    }

    if (name == "support_productive") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
    }

    if (name == "support_result") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
    }

    if (name == "pc_food") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
    }

    if (name == "pc_phone") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
    }

    if (name == "pc_move") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
    }

    if (name == "dependent_count") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
    }

    if (name == "count_day_work") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
    }

    if (name == "standard_working_hour") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
    }

    if (name == "total_salary") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);

      setEmployees(newListEmp);
    }

    if (name == "salary") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);

      setEmployees(newListEmp);
    }
  };

  return (
    <Modal
      title={"Chạy lại bảng công"}
      centered
      open={visible}
      width={"auto"}
      style={{ top: 20, minWidth: 800}}
      onCancel={() => {
        closeModal();
      }}
      onOk={() => {
        handleResetDataStaff();
      }}
    >
      <div
        className="d-flex justify-content-between"
        style={{ margin: "10px" }}
      >
        <div>
          <label>
            Tháng áp dụng <span className="text-danger">*</span>
          </label>
          <div>
            <DatePicker
              value={moment(
                `${applyDate.month}-${applyDate.year}`,
                FORMAT_DATE
              ).toDate()}
              format={FORMAT_DATE}
              cleanable={false}
              onChange={handleChangeDate}
            />
          </div>
        </div>
        <div>
          <label>
            Đối tượng áp dụng<span className="text-danger">*</span>
          </label>
          <div
            className="d-flex align-items-center btn-add"
            onClick={() => setModal({ ...modal, staff: true })}
          >
            <i
              class="fa fa-plus-circle ml-2 question-group-icon text-success"
              aria-hidden="true"
            ></i>
            <span className="ml-1">
              Thêm nhân viên{" "}
              <span className="font-weight-bold">
                (Đã chọn: {employees.length} nhân viên)
              </span>
            </span>
          </div>
        </div>
        <div></div>
      </div>
      <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
          }}
        >
          {employees?.length > 0 ? (
            <div>
              {employees?.map((employee, index) => {
                return (
                  <ItemStaffReset
                    key={employee.id}
                    employee={employee}
                    onChange={(e, key) => {
                      console.log(e);
                      console.log(key);
                      handleData(e, key, index);
                    }}
                    handleRemoveEmployee={(employeeId) =>
                      handleRemoveEmployee(employeeId)
                    }
                  />
                );
              })}
            </div>
          ) : (
            <div className="mt-2 text-danger center">
              Chưa chọn đối tượng áp dụng
            </div>
          )}
        </div>
      </div>
      <StaffModal
        visible={modal.staff}
        staffIds={staffIds}
        closeModal={handleCloseModal}
        onSubmit={handleSubmitStaffModal}
      />
    </Modal>
  );
};

export default ResetDataStaffModal;
