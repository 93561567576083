import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { Space, Table, Button, Popconfirm, Input, Image } from "antd";
import moment from "moment";
import PermissionChecker from "../../../components/PermissionChecker";
import { useDispatch, useSelector } from "react-redux";
import { companyServices } from "../../../services/companyServices";
import AddCompanyModal from "./child/AddCompanyModal";
const { Search } = Input;

export default function CompaniesPage() {
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });
  const [companies, setCompanies] = useState(null);
  const [loading, setLoading] = useState(null);

  const dispatch = useDispatch();

  const getCompany = async (page, search) => {
    setLoading(true);
    try {
      const response = await companyServices.getCompany();
      setCompanies(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCompany();
  }, []);

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const columns = [
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      render: (_, record) => (
        <Image width={100} height={100} src={record?.logo}></Image>
      ),
    },
    {
      title: "Tên công ty",
      dataIndex: "name",
      key: "name",
      render: (name) => <a>{name}</a>,
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      render: (address) => <a>{address}</a>,
    },
    {
      title: "Ngày tạo",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <a> {moment(text ?? Date.now()).format("DD-MM-YYYY")}</a>
      ),
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          {PermissionChecker({
            decent: reportNumber.permissions?.edit_company ?? false,
            child: (
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  setModal({
                    method: "ADD",
                    visible: true,
                    data: record.data,
                  });
                }}
              >
                Sửa
              </a>
            ),
          })}
          {/* {PermissionChecker({
            decent: (reportNumber.role_title ?? "") == "Admin",
            child: (
              <Popconfirm
                title="Xoá công ty ?"
                description="Bạn có chắc chắn muốn xoá công ty này chứ ?"
                onConfirm={() => {
                  // deleteMultiStaff(record.key);
                }}
                onCancel={() => {}}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                okText="Có"
                cancelText="Không"
              >
                <a style={{ color: "red" }}>Xoá</a>
              </Popconfirm>
            ),
          })} */}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center mt-2">
          <h4 className="h4 title_content mb-0 text-gray-800">
            Danh sách công ty
          </h4>
          {PermissionChecker({
            decent: reportNumber.permissions?.add_company ?? false,
            child: (
              <Button
                type="primary"
                shape="round"
                size={20}
                icon={<MdAdd />}
                onClick={(e) => {
                  setModal({
                    method: "ADD",
                    visible: true,
                    data: null,
                  });
                }}
              >
                Thêm công ty
              </Button>
            ),
          })}
        </div>
        <div style={{ height: "20px" }}></div>
        <Table
          loading={loading}
          columns={columns}
          onRow={(record, index) => {
            return {
              style: {
                background: record.status === 0 ? "#f6f6f6" : "default",
              },
              onClick: (e) => {
                setModal({
                  method: "WATCH",
                  visible: true,

                  customer_id: record.key,
                });
              },
            };
          }}
          dataSource={(companies?.data ?? []).map((e) => {
            return {
              key: `${e.id}`,
              data: e,
              name: e.name ?? "--:--",
              address: e?.address ?? "--:--",
              logo: e?.logo ?? "",
              created_at: e.created_at,
            };
          })}
          pagination={null}
        />
        <AddCompanyModal
          open={modal.visible && modal.method === "ADD"}
          dataInput={modal.data}
          onOk={() => {
            getCompany();
            handleCloseModal();
          }}
          closeModal={() => {
            handleCloseModal();
          }}
        />
      </div>
      {/* <InfoCustomerModal
        open={modal.visible && modal.method === "WATCH"}
        onChoose={(month, year) => {
          // exportData(modal.isFollowArea, month, year);
          // setModal({
          //   method: null,
          //   visible: false,
          // });
        }}
        customer_id={modal.customer_id}
        closeModal={() => {
          setModal({
            method: null,
            visible: false,
          });
        }}
      ></InfoCustomerModal> */}
    </>
  );
}
