/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "rsuite";
import Pagination from "../../../components/Pagination";
import { convertObjToQueryString, formatMoneyVND } from "../../../helpers";
import { shiftServices } from "../../../services/shiftServices";
import DeleteModal from "./child/DeleteModal";
import { constants } from "../../../constants";
import SalaryCreateModal from "./child/SalaryCreateModal";
import "./style.css";
import SalaryDetailModal from "./child/SalaryDetailModal";
import UpdateSalaryTable from "./child/UpdateSalaryTable";
import { toast } from "react-toastify";
import PermissionChecker from "../../../components/PermissionChecker";
import SalaryResetModal from "./child/SalaryResetModal";
import axios from "axios";
import * as XLSX from "xlsx-js-style";

const convertDataToExcelFormat = (data) => {
  const result = [null, null];

  const endDate = moment();

  data?.salaries.forEach((item, index) => {
    let row;

    if (
      item.contract_object.type_label === "Full time" ||
      item.staff.contract.type_label === "Full time"
    ) {
      let total_sp = 0;
      total_sp += item.pc_food + item.pc_move + item.pc_phone;
      data?.support_list.forEach((support_v) => {
        if (!support_v["value"][index]) {
          total_sp = 0;
        } else {
          total_sp += support_v["value"][index];
        }
      });

      let total_bonus = 0;
      data?.bonus_list.forEach((bonus_v) => {
        if (!bonus_v["value"][index]) {
          total_bonus = 0;
        } else {
          total_bonus += bonus_v["value"][index];
        }
      });

      let total_minus = 0;
      data?.minus_list.forEach((minus_v) => {
        if (!minus_v["value"][index]) {
          total_minus = 0;
        } else {
          total_minus += minus_v["value"][index];
        }
      });

      row = [
        item.area.name, // Mã nhà hàng
        item.staff.department.name, // Phòng ban
        index + 1, // STT
        item.staff.staff_code, // Mã NV
        item.staff.name, // Họ và tên
        item.staff.position, // Chức vụ
        item.staff.contract.start.split(" ")[0], // L
        item.staff.contract.probation ? "-" : "-", // Thử việc
        item.contract_object.type_label || item.staff.contract.type_label, // Loại hình làm việc
        endDate.diff(item.staff.contract.start, "months"), // Thâm niên (tháng)
        item.salary_total_contract, // Tổng lương thu nhập
        item.staff.contract.position_salary, // Lương vị trí (P1)
        item.staff.contract.support_productive, // Lương năng lực (P2)
        item.staff.contract.support_result, // Lương hiệu quả công việc (P3)
        item.staff.contract.pc_food, // PC cơm
        item.staff.contract.pc_move, // PC xăng xe
        item.staff.contract.pc_phone, // PC điện thoại
        null, // Tạp vụ lưu động (7h0 - 21h0) - 9000000 | Giờ công thực tế
        null, // Tạp vụ lưu động (7h0 - 21h0) - 9000000 | Lương thực tế
        null, // CSKH Partime (17h30 - 21h0) - 2700000 | Giờ công thực tế
        null, // CSKH Partime (17h30 - 21h0) - 2700000 | Giờ công thực tế
        null, // CSKH Partime Full (8h30 - 21h0) - 9400000 | Giờ công thực tế
        null, // CSKH Partime Full (8h30 - 21h0) - 9400000 | Giờ công thực tế
        null, // CS Partime (10h - 11h) (10h0 - 13h0) - 3000000 | Giờ công thực tế
        null, // CS Partime (10h - 11h) (10h0 - 13h0) - 3000000 | Giờ công thực tế
        null, // Tổng lương các ca
        (item.work_time +
          item.over_time +
          item.bonus_form_time +
          item.scale_time +
          item.trip_form_time) /
          (3600 * item.contract_object.standard_working_hour), // Ngày công thực tế
        (item.work_time +
          item.over_time +
          item.bonus_form_time +
          item.scale_time) /
          3600, // Giờ công thực tế
        item.trip_form_time / 3600 || 0, // Giờ công đi công tác
        item.leave_form_time / 3600 || 0, // Nghỉ phép (AL)
        "-", // Nghỉ lễ / chế độ
        item.over_form_time / 3600 || 0, // Thường
        item.scale_time / 3600, // CN
        item.ot_time / 3600, // Lễ / tết
        item.contract_object.count_day_work *
          item.contract_object.standard_working_hour, // Giờ công chuẩn
        item.contract_object.count_day_work, // Ngày công chuẩn
        item.work_money +
          item.over_money +
          item.trip_form_money +
          item.leave_form_money +
          item.bonus_form_money, // Lương theo giờ công
        item.position_salary, // Lương theo giờ công P1
        item.support_productive, // Lương theo giờ công P2
        item.kpi_percent + "%", // % KPIs đạt được
        item.support_result, // P3
        0, // Lương theo Ngày/Giờ công thực tế (1)
        item.over_form_money, // Thêm giờ
        item.scale_money, // Tăng ca thường
        item.ot_money, // Lương làm lễ
        item.over_form_money + item.scale_money + item.ot_money, // Tổng lương Tăng ca (2)
        item.pc_food, // PC cơm
        item.pc_move, // PC xăng xe
        item.pc_phone, // PC điện thoại
        item.seniority, // Tiền thâm niên // đang trống
        item.seniority + total_sp, // Tổng phụ cấp (3)
        total_bonus, // Tổng thưởng (4) // đang trống
        item.another_plus, // Cộng khác
        item.salary_provisional, // TỔNG THU NHẬP THỰC TẾ 1+2+3+4+... (9)
        item.BHXH,
        item.BHYT,
        item.BHTN,
        item.BHXH + item.BHYT + item.BHTN, // Tổng BHXH Người Lao Động Phải Đóng (10.5%)
        item.cong_doan, // Đoàn phí công đoàn(1%)
        item.staff.contract.dependent_count, // Số người giảm trừ (4.400.000)
        11000000, // Giảm trừ bản thân (11.000.000)
        item.dependent_another +
          item.BHXH +
          item.BHYT +
          item.BHTN +
          item.pc_food, // Các khoản miễn thuế
        item.taxable_income > 0 ? item.taxable_income : 0, // Tính tổng thuế thu nhập các nhân
        item.tax, // Thuế TNCN (6)
        item.BHXH + item.BHYT + item.BHTN + Math.abs(total_minus) + item.tax, // Tổng các khoản trừ (5)+(6)+(...), số 0 thay thành total minus
        item.another_minus, // Trừ khác
        item.education_kpi_minus, // Trừ kpi đào tạo
        item.salary > 0 ? item.salary : 0, // THỰC LÃNH (9)+(10)
      ];
    } else {
      let total_minus = 0;
      data?.minus_list.forEach((minus_v) => {
        if (!minus_v["value"][index]) {
          total_minus = 0;
        } else {
          total_minus += minus_v["value"][index];
        }
      });
      let total_bonus = 0;
      data?.bonus_list.forEach((bonus_v) => {
        if (!bonus_v["value"][index]) {
          total_bonus = 0;
        } else {
          total_bonus += bonus_v["value"][index];
        }
      });
      let total_sp = 0;
      data?.support_list.forEach((support_v) => {
        if (!support_v["value"][index]) {
          total_sp = 0;
        } else {
          total_sp += support_v["value"][index];
        }
      });
      row = [
        item.area.name, // Mã nhà hàng
        item.staff.department.name, // Phòng ban
        index + 1, // STT
        item.staff.staff_code, // Mã NV
        item.staff.name, // Họ và tên
        item.staff.position, // Chức vụ
        item.staff.contract.start.split(" ")[0], // L
        item.staff.contract.probation ? "-" : "-", // Thử việc
        item.contract_object.type_label || item.staff.contract.type_label, // Loại hình làm việc
        null, // Thâm niên (tháng)
        null, // Tổng lương thu nhập
        item.staff.contract.position_salary, // Lương vị trí (P1)
        null, // Lương năng lực (P2)
        null, // Lương hiệu quả công việc (P3)
        null, // PC cơm
        null, // PC xăng xe
        null, // PC điện thoại
        item.shift_money[0]?.time / 3600 || 0, // Tạp vụ lưu động (7h0 - 21h0) - 9000000 | Giờ công thực tế
        item.shift_money[0]?.money || 0, // Tạp vụ lưu động (7h0 - 21h0) - 9000000 | Lương thực tế
        item.shift_money[1]?.time / 3600 || 0, // CSKH Partime (17h30 - 21h0) - 2700000 | Giờ công thực tế
        item.shift_money[1]?.money || 0, // CSKH Partime (17h30 - 21h0) - 2700000 | Giờ công thực tế
        item.shift_money[2]?.time / 3600 || 0, // CSKH Partime Full (8h30 - 21h0) - 9400000 | Giờ công thực tế
        item.shift_money[2]?.money || 0, // CSKH Partime Full (8h30 - 21h0) - 9400000 | Giờ công thực tế
        item.shift_money[3]?.time / 3600 || 0, // CS Partime (10h - 11h) (10h0 - 13h0) - 3000000 | Giờ công thực tế
        item.shift_money[3]?.money || 0, // CS Partime (10h - 11h) (10h0 - 13h0) - 3000000 | Giờ công thực tế
        item.salary_total, // Tổng lương các ca
        null, // Ngày công thực tế
        (item.work_time +
          item.over_time +
          item.bonus_form_time +
          item.scale_time) /
          3600, // Giờ công thực tế
        item.trip_form_time / 3600, // Giờ công đi công tác
        item.leave_form_time / 3600, // Nghỉ phép (AL)
        "-", // Nghỉ lễ / chế độ
        item.over_form_time / 3600, // Thường
        item.scale_time / 3600, // CN
        item.ot_time / 3600, // Lễ / tết
        item.time_default / 3600, // Giờ công chuẩn
        null, // Ngày công chuẩn
        item.salary_total, // Lương theo giờ công
        null, // Lương theo giờ công P1
        null, // Lương theo giờ công P2
        item.kpi_percent + "%", // % KPIs đạt được
        null, // P3
        0, // Lương theo Ngày/Giờ công thực tế (1)
        item.over_form_money, // Thêm giờ
        item.scale_money, // Tăng ca thường
        item.ot_money, // Lương làm lễ
        item.over_form_money + item.scale_money + item.ot_money, // Tổng lương Tăng ca (2)
        null, // PC cơm
        null, // PC xăng xe
        null, // PC điện thoại
        item.seniority, // Tiền thâm niên
        total_sp + item.seniority, // Tổng phụ cấp (3) // chưa tính
        total_bonus, // Tổng thưởng (4) // chưa tính
        item.another_plus, // Cộng khác
        item.salary_provisional, // TỔNG THU NHẬP THỰC TẾ 1+2+3+4+... (9)
        item.BHXH,
        item.BHYT,
        item.BHTN,
        item.BHXH + item.BHYT + item.BHTN, // Tổng BHXH Người Lao Động Phải Đóng (10.5%)
        item.cong_doan, // Đoàn phí công đoàn(1%)
        item.staff.contract.dependent_count, // Số người giảm trừ (4.400.000)
        11000000, // Giảm trừ bản thân (11.000.000)
        item.dependent_another + item.BHXH + item.BHYT + item.BHTN, // Các khoản miễn thuế
        item.taxable_income > 0 ? item.taxable_income : 0, // Tính tổng thuế thu nhập các nhân
        item.tax, // Thuế TNCN (6)
        item.BHXH + item.BHYT + item.BHTN + Math.abs(total_minus) + item.tax, // Tổng các khoản trừ (5)+(6)+(...), // chưa tính
        item.another_minus, // Trừ khác
        item.education_kpi_minus, // Trừ kpi đào tạo
        item.salary > 0 ? item.salary : 0, // THỰC LÃNH (9)+(10)
      ];
    }
    result.push(row);
  });

  return result;
};

const Salary = () => {
  const dispatch = useDispatch();
  const areaId = useSelector((state) => state.area.areaId);
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const [isReload, setIsReload] = useState(false);
  const [salaryList, setSalaryList] = useState([]);
  const [selectSalary, setSelectSalary] = useState([]);

  const [salary, setSalary] = useState();
  const [query, setQuery] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    page: 1,
  });
  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
    salaryTable: null,
    componentContentModal: null,
  });

  useEffect(() => {
    fetchSalaryList(areaId, convertObjToQueryString(query));
  }, [areaId, query, isReload]);

  const fetchSalaryList = async (areaId, queryString) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const resSalaryList = await shiftServices.fetchSalaryList({
        areaId: areaId,
        queryString:
          areaId == constants.AREA_ALL_ID
            ? `${queryString}&view_all=1`
            : queryString,
      });
      console.log(resSalaryList);
      setSalaryList(resSalaryList.data);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const deleteSalaryMany = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });

      var response = await shiftServices.deleteSalaryMany({
        areaId: areaId,
        body: {
          list_salary_id: selectSalary ?? [],
        },
      });

      setSelectSalary([]);

      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });

      await fetchSalaryList(areaId, convertObjToQueryString(query));

      if (response.success === true) {
        toast.success("Đã xoá");
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const handleResetSalary = async (item) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const salaryBody = {
        year: query.year,
        month: query.month,
        list_data: [
          {
            staff_id: item?.staff?.id,
            kpi_percent: item.kpi_percent.toString().replaceAll(".", ""),
            another_plus: item.another_plus.toString().replaceAll(".", ""),
            another_minus: item.another_minus.toString().replaceAll(".", ""),
            BHXH: item.BHXH.toString().replaceAll(".", ""),
            tax: item.tax.toString().replaceAll(".", ""),
            list_support_default_id: [],
            list_bonus_default_id: [],
            list_bonus: [],
          },
        ],
        is_all: false,
      };
      const response = await shiftServices.resetSalary({
        areaId: areaId,
        body: salaryBody,
      });

      handleSuccessSalaryModal();

      if (response.success) {
        toast.success(response.msg);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      console.log(error);
      toast.error("Lỗi khi tạo mới bảng lương");
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const updateSalaryTable = async ({ isAll, listSalaryId, status }) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });

      var response = await shiftServices.updateSalaryTable({
        areaId: areaId,
        body: {
          area_id: areaId,
          is_all: isAll,
          month: query.month,
          year: query.year,
          list_salary_id: listSalaryId ?? [],
          status: status,
        },
      });

      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });

      await fetchSalaryList(areaId, convertObjToQueryString(query));

      if (response.success === true) {
        if (status === 0) {
          toast.success("Đã ẩn");
        } else {
          toast.success("Đã gửi");
        }
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const handleChangePageIndex = (index) => {
    setQuery({
      ...query,
      page: index,
    });
  };

  const handleChangeDate = (date) => {
    if (moment.isDate(date)) {
      setQuery({
        ...query,
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      });
    }
  };

  const handleDelete = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await shiftServices.deleteSalary({
        areaId,
        salaryId: salary.id,
      });
      if (response.success) {
        setIsReload(!isReload);
        handleCloseModal();
        toast.success(response.msg);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error("Lỗi khi xóa bảng lương");
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const handleCloseModal = () => {
    setModal({ title: "", method: "", visible: false });
  };

  const handleSuccessSalaryModal = () => {
    setIsReload(!isReload);
    handleCloseModal();
  };

  const handleExportSalary = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });

      var body = null;

      if (areaId === constants.AREA_ALL_ID) {
        body = {
          month: query.month,
          year: query.year,
        };
      } else {
        body = {
          area_id: areaId,
          month: query.month,
          year: query.year,
        };
      }

      const response = await shiftServices.exportSalary({
        areaId: areaId,
        body: body,
      });
      if (response.success) {
        const link = `${response.data?.link}`;
        const tagA = document.createElement("a");
        tagA.href = link;
        tagA.download = "salary.xlsx";
        tagA.click();
        toast.success(response.msg);
      } else {
        dispatch({
          type: constants.FAILURE,
          code: response.code,
          message: response.message,
        });
      }
    } catch (error) {
      console.log(error);
      let msg = "Lỗi khi export bảng lương";
      toast.success(msg);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const handleExportSalaryV1 = async () => {
    try {
      const res = await axios.post(
        `https://api.comnieuthienly.vn/api/personnel/${areaId}/salary/excel/json-all`,
        {
          area_id: areaId,
          month: query.month,
          year: query.year,
        },
        {
          headers: {
            token: JSON.parse(localStorage.getItem("tokenInfo")) || "",
          },
        }
      );
      const dataExcel = convertDataToExcelFormat(res.data?.data);

      const data = [
        [
          "Mã nhà hàng",
          "Phòng ban",
          "STT",
          "Mã NV",
          "Họ và tên",
          "Chức vụ",
          "L",
          "Thử việc",
          "Loại hình làm việc",
          "Thâm niên (tháng)",
          "Tổng lương thu nhập",
          "Thông tin thu nhập",
          null,
          null,
          null,
          null,
          null,
          // "Tạp vụ lưu động (7h0 - 21h0) - 9000000",
          "CSKH Partime (17h30 - 21h0) - 2700000",
          null,
          // "CSKH Partime (17h30 - 21h0) - 2700000",
          "CSKH Partime Full (8h30 - 21h0) - 9400000",
          null,
          // "CSKH Partime Full (8h30 - 21h0) - 9400000",
          "CS Partime (10h - 11h) (10h0 - 13h0) - 3000000",
          null,
          // "CS Partime (10h - 11h) (10h0 - 13h0) - 3000000",
          "Tạp vụ lưu động (7h0 - 21h0) - 9000000",
          null,
          "TỔNG LƯƠNG CÁC CA",
          "THÔNG TIN NGÀY/GIỜ CÔNG THỰC TẾ",
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          "LƯƠNG THEO NGÀY/GIỜ CÔNG THỰC TẾ",
          null,
          null,
          null,
          null,
          "Lương theo Ngày/Giờ công thực tế (1)",
          "LƯƠNG TĂNG CA THỰC TẾ",
          null,
          null,
          null,
          "CÁC KHOẢN PHỤ CẤP THỰC TẾ",
          null,
          null,
          null,
          null,
          "CÁC KHOẢN THƯỞNG THỰC TẾ",
          null,
          "TỔNG THU NHẬP THỰC TẾ 1+2+3+4+... (9)",
          "CÁC KHOẢN TRỪ THỰC TẾ",
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          "THỰC LÃNH (9)+(10)",
        ],
        [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          "Lương vị trí (P1)",
          "Lương năng lực (P2)",
          "Lương hiệu quả công việc (P3)",
          "PC cơm",
          "PC xăng xe",
          "PC điện thoại",
          "Giờ công thực tế",
          "Lương thực tế",
          "Giờ công thực tế",
          "Lương thực tế",
          "Giờ công thực tế",
          "Lương thực tế",
          "Giờ công thực tế",
          "Lương thực tế",
          null,
          "Ngày công thực tế",
          "Giờ công thực tế",
          "Giờ công đi công tác",
          "Nghỉ phép (AL)",
          "Nghỉ lễ / chế độ",
          "Công tăng ca (Theo giờ)",
          null,
          null,
          "Giờ công chuẩn",
          "Ngày công chuẩn",
          "Lương theo giờ công",
          "Lương theo giờ công P1",
          "Lương theo giờ công P2",
          "% KPIs đạt được",
          "P3",
          null,
          "Thêm giờ",
          "Tăng ca thường",
          "Lương làm lễ",
          "Tổng lương Tăng ca (2)",
          "PC cơm",
          "PC xăng xe",
          "PC điện thoại",
          "Tiền thâm niên",
          "Tổng phụ cấp",
          "Tổng thưởng (4)",
          "Cộng khác",
          null,
          "BHXH (08%)",
          "CỘT NÀY TÍNH RA THU NHẬP CHỊU THUẾ VÀ CUỐI CÙNG RA ĐƯỢC CỘT KẾ BÊN PHẢI TRẢ THUẾ TNCN LÀ BAO NHIÊU",
          null,
          null,
          null,
          null,
          "Giảm trừ bản thân (11.000.000)",
          "Các khoản miễn thuế",
          "Tổng thu nhập tính thuế TNCN",
          "Thuế TNCN (6)",
          "Tổng các khoản trừ (5)+(6)+(...)",
          "Trừ khác",
          "Trừ kpi đào tạo",
        ],
        [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          "Thường",
          "CN",
          "Lễ tết",
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          "BHYT (1.5%)",
          "BHTN (01%)",
          "Tổng BHXH Người Lao Động Phải Đóng (10.5%)",
          "Đoàn phí công đoàn(1%)",
          "Số người giảm trừ (4.400.000)",
        ],
      ];
      const newData = data.concat(dataExcel);
      const worksheet = XLSX.utils.aoa_to_sheet(newData, { cellStyles: true });

      worksheet["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 4, c: 0 } },
        { s: { r: 0, c: 1 }, e: { r: 4, c: 1 } },
        { s: { r: 0, c: 2 }, e: { r: 4, c: 2 } },
        { s: { r: 0, c: 3 }, e: { r: 4, c: 3 } },
        { s: { r: 0, c: 4 }, e: { r: 4, c: 4 } },
        { s: { r: 0, c: 5 }, e: { r: 4, c: 5 } },
        { s: { r: 0, c: 6 }, e: { r: 4, c: 6 } },
        { s: { r: 0, c: 7 }, e: { r: 4, c: 7 } },
        { s: { r: 0, c: 8 }, e: { r: 4, c: 8 } },
        { s: { r: 0, c: 9 }, e: { r: 4, c: 9 } },
        { s: { r: 0, c: 10 }, e: { r: 4, c: 10 } },
        { s: { r: 0, c: 11 }, e: { r: 0, c: 16 } },
        { s: { r: 0, c: 17 }, e: { r: 0, c: 18 } },
        { s: { r: 0, c: 19 }, e: { r: 0, c: 20 } },
        { s: { r: 0, c: 21 }, e: { r: 0, c: 22 } },
        { s: { r: 0, c: 23 }, e: { r: 0, c: 24 } },
        { s: { r: 0, c: 25 }, e: { r: 4, c: 25 } },
        { s: { r: 0, c: 26 }, e: { r: 0, c: 35 } },
        { s: { r: 0, c: 36 }, e: { r: 0, c: 40 } },
        { s: { r: 0, c: 41 }, e: { r: 4, c: 41 } },
        { s: { r: 0, c: 42 }, e: { r: 0, c: 45 } },
        { s: { r: 0, c: 46 }, e: { r: 0, c: 50 } },
        { s: { r: 0, c: 51 }, e: { r: 0, c: 52 } },
        { s: { r: 0, c: 53 }, e: { r: 4, c: 53 } },
        { s: { r: 0, c: 54 }, e: { r: 0, c: 66 } },
        { s: { r: 0, c: 67 }, e: { r: 4, c: 67 } },

        // Dòng 2
        { s: { r: 1, c: 11 }, e: { r: 4, c: 11 } },
        { s: { r: 1, c: 12 }, e: { r: 4, c: 12 } },
        { s: { r: 1, c: 13 }, e: { r: 4, c: 13 } },
        { s: { r: 1, c: 14 }, e: { r: 4, c: 14 } },
        { s: { r: 1, c: 15 }, e: { r: 4, c: 15 } },
        { s: { r: 1, c: 16 }, e: { r: 4, c: 16 } },
        { s: { r: 1, c: 17 }, e: { r: 4, c: 17 } },
        { s: { r: 1, c: 18 }, e: { r: 4, c: 18 } },
        { s: { r: 1, c: 19 }, e: { r: 4, c: 19 } },
        { s: { r: 1, c: 20 }, e: { r: 4, c: 20 } },
        { s: { r: 1, c: 21 }, e: { r: 4, c: 21 } },
        { s: { r: 1, c: 22 }, e: { r: 4, c: 22 } },
        { s: { r: 1, c: 23 }, e: { r: 4, c: 23 } },
        { s: { r: 1, c: 24 }, e: { r: 4, c: 24 } },
        { s: { r: 1, c: 25 }, e: { r: 4, c: 25 } },
        { s: { r: 1, c: 26 }, e: { r: 4, c: 26 } },
        { s: { r: 1, c: 27 }, e: { r: 4, c: 27 } },
        { s: { r: 1, c: 28 }, e: { r: 4, c: 28 } },
        { s: { r: 1, c: 29 }, e: { r: 4, c: 29 } },
        { s: { r: 1, c: 30 }, e: { r: 4, c: 30 } },
        { s: { r: 1, c: 31 }, e: { r: 1, c: 33 } },
        { s: { r: 1, c: 34 }, e: { r: 4, c: 34 } },
        { s: { r: 1, c: 35 }, e: { r: 4, c: 35 } },
        { s: { r: 1, c: 36 }, e: { r: 4, c: 36 } },
        { s: { r: 1, c: 37 }, e: { r: 4, c: 37 } },
        { s: { r: 1, c: 38 }, e: { r: 4, c: 38 } },
        { s: { r: 1, c: 39 }, e: { r: 4, c: 39 } },
        { s: { r: 1, c: 40 }, e: { r: 4, c: 40 } },
        { s: { r: 1, c: 41 }, e: { r: 4, c: 41 } },
        { s: { r: 1, c: 42 }, e: { r: 4, c: 42 } },
        { s: { r: 1, c: 43 }, e: { r: 4, c: 43 } },
        { s: { r: 1, c: 44 }, e: { r: 4, c: 44 } },
        { s: { r: 1, c: 45 }, e: { r: 4, c: 45 } },
        { s: { r: 1, c: 46 }, e: { r: 4, c: 46 } },
        { s: { r: 1, c: 47 }, e: { r: 4, c: 47 } },
        { s: { r: 1, c: 48 }, e: { r: 4, c: 48 } },
        { s: { r: 1, c: 49 }, e: { r: 4, c: 49 } },
        { s: { r: 1, c: 50 }, e: { r: 4, c: 50 } },
        { s: { r: 1, c: 51 }, e: { r: 4, c: 51 } },
        { s: { r: 1, c: 52 }, e: { r: 4, c: 52 } },
        { s: { r: 1, c: 54 }, e: { r: 4, c: 54 } },
        { s: { r: 1, c: 55 }, e: { r: 1, c: 59 } },
        { s: { r: 1, c: 60 }, e: { r: 4, c: 60 } },
        { s: { r: 1, c: 61 }, e: { r: 4, c: 61 } },
        { s: { r: 1, c: 62 }, e: { r: 4, c: 62 } },
        { s: { r: 1, c: 63 }, e: { r: 4, c: 63 } },
        { s: { r: 1, c: 64 }, e: { r: 4, c: 64 } },
        { s: { r: 1, c: 65 }, e: { r: 4, c: 65 } },
        { s: { r: 1, c: 66 }, e: { r: 4, c: 66 } },

        // Dòng 3
        { s: { r: 2, c: 31 }, e: { r: 4, c: 31 } },
        { s: { r: 2, c: 32 }, e: { r: 4, c: 32 } },
        { s: { r: 2, c: 33 }, e: { r: 4, c: 33 } },
        { s: { r: 2, c: 55 }, e: { r: 4, c: 55 } },
        { s: { r: 2, c: 56 }, e: { r: 4, c: 56 } },
        { s: { r: 2, c: 57 }, e: { r: 4, c: 57 } },
        { s: { r: 2, c: 58 }, e: { r: 4, c: 58 } },
        { s: { r: 2, c: 59 }, e: { r: 4, c: 59 } },
      ];

      const numCols = XLSX.utils.decode_range(worksheet["!ref"]).e.c + 1;
      const numRows = XLSX.utils.decode_range(worksheet["!ref"]).e.r + 1;

      const wscols = [];
      const wsrows = [];
      for (let i = 0; i < numCols; i++) {
        switch (i) {
          case 0:
            wscols.push({ wch: 25 });
            break;
          case 1:
          case 4:
            wscols.push({ wch: 30 });
            break;
          case 3:
            wscols.push({ wch: 20 });
            break;
          case 5:
            wscols.push({ wch: 35 });
            break;
          default:
            wscols.push({ wch: 18 });
            break;
        }
      }
      for (let i = 0; i <= 2; i++) {
        wsrows.push({ hpx: 40 });
      }
      for (let i = 3; i < numRows; i++) {
        wsrows.push({ hpx: 30 });
      }
      worksheet["!cols"] = wscols;
      worksheet["!rows"] = wsrows;

      const firstColumnStyle = {
        fill: { patternType: "solid", fgColor: { rgb: "f9c114" } },
        border: {
          top: { style: "dotted" },
          left: { style: "dotted" },
          bottom: { style: "dotted" },
          right: { style: "dotted" },
        },
        alignment: { horizontal: "center", vertical: "center", wrapText: true },
      };

      const range = XLSX.utils.decode_range(worksheet["!ref"]);

      for (let R = range.s.r; R <= range.s.r + 2; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cell_address = { c: C, r: R };
          const cell_ref = XLSX.utils.encode_cell(cell_address);

          if (!worksheet[cell_ref]) {
            continue;
          }
          worksheet[cell_ref].s = firstColumnStyle;
        }
      }

      for (let R = range.s.r; R <= range.e.r + 2; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cell_address = { c: C, r: R };
          const cell_ref = XLSX.utils.encode_cell(cell_address);

          if (!worksheet[cell_ref]) {
            continue;
          }
          if (!worksheet[cell_ref].s) {
            worksheet[cell_ref].s = {
              alignment: {
                horizontal: "left",
                vertical: "center",
              },
            };
          }
        }
      }

      for (let r = 0; r <= range.e.r + 2; r++) {
        for (let c = 0; c <= range.e.c; c++) {
          const cell_address = XLSX.utils.encode_cell({ r: r, c: c });
          const cell = worksheet[cell_address];
          if (cell && typeof cell.v === "number") {
            const value = cell.v.toString();
            if (value.length > 3) {
              cell.z = '#,##0.000""';
            }
          }
        }
      }

      const colorBrown = [
        "Thông tin thu nhập",
        "THÔNG TIN NGÀY/GIỜ CÔNG THỰC TẾ",
        "LƯƠNG THEO NGÀY/GIỜ CÔNG THỰC TẾ",
        "LƯƠNG TĂNG CA THỰC TẾ",
        "CÁC KHOẢN PHỤ CẤP THỰC TẾ",
        "CÁC KHOẢN THƯỞNG THỰC TẾ",
        "CÁC KHOẢN TRỪ THỰC TẾ",
      ];

      const colorPink = [
        "CSKH Partime (17h30 - 21h0) - 2700000",
        "CSKH Partime Full (8h30 - 21h0) - 9400000",
        "LƯƠNG THEO NGÀY/GIỜ CÔNG THỰC TẾ",
        "CS Partime (10h - 11h) (10h0 - 13h0) - 3000000",
        "Tạp vụ lưu động (7h0 - 21h0) - 9000000",
        "TỔNG LƯƠNG CÁC CA",
      ];

      const colorYellow = [
        "Lương vị trí (P1)",
        "Lương năng lực (P2)",
        "Lương hiệu quả công việc (P3)",
        "PC cơm",
        "PC xăng xe",
        "PC điện thoại",
        "Ngày công thực tế",
        "Giờ công đi công tác",
        "Nghỉ phép (AL)",
        "Nghỉ lễ / chế độ",
        "Công tăng ca (Theo giờ)",
        "Thường",
        "CN",
        "Lễ tết",
        "Giờ công chuẩn",
        "Ngày công chuẩn",
        "Lương theo giờ công",
        "Lương theo giờ công P1",
        "Lương theo giờ công P2",
        "% KPIs đạt được",
        "P3",
        "Thêm giờ",
        "Tăng ca thường",
        "Lương làm lễ",
        "Tổng lương Tăng ca (2)",
        "Tổng phụ cấp (3)",
        "Tổng thưởng (4)",
        "Cộng khác",
        "BHXH (08%)",
        "BHYT (1.5%)",
        "BHTN (01%)",
        "Tổng BHXH Người Lao Động Phải Đóng (10.5%)",
        "Đoàn phí công đoàn(1%)",
        "Số người giảm trừ (4.400.000)",
        "Giảm trừ bản thân (11.000.000)",
        "Các khoản miễn thuế",
        "Tổng thu nhập tính thuế TNCN",
        "Thuế TNCN (6)",
      ];

      const colorSilver = ["THỰC LÃNH (9)+(10)"];

      const applyCellStyle = (worksheet, range, conditionList, style) => {
        for (let rowIndex = range.s.r; rowIndex <= range.e.r; rowIndex++) {
          for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
            const cellRef = XLSX.utils.encode_cell({
              r: rowIndex,
              c: colIndex,
            });
            const cell = worksheet[cellRef];
            if (cell && cell.v && conditionList.includes(cell.v)) {
              cell.s = style;
            }
          }
        }
      };

      applyCellStyle(worksheet, range, colorSilver, {
        fill: { patternType: "solid", fgColor: { rgb: "deeaf6" } },
        border: {
          top: { style: "dotted" },
          left: { style: "dotted" },
          bottom: { style: "dotted" },
          right: { style: "dotted" },
        },
        alignment: { horizontal: "center", vertical: "center", wrapText: true },
      });

      applyCellStyle(worksheet, range, colorBrown, {
        fill: { patternType: "solid", fgColor: { rgb: "bf9002" } },
        border: {
          top: { style: "dotted" },
          left: { style: "dotted" },
          bottom: { style: "dotted" },
          right: { style: "dotted" },
        },
        alignment: { horizontal: "center", vertical: "center", wrapText: true },
      });

      applyCellStyle(worksheet, range, colorPink, {
        fill: { patternType: "solid", fgColor: { rgb: "fabf8f" } },
        border: {
          top: { style: "dotted" },
          left: { style: "dotted" },
          bottom: { style: "dotted" },
          right: { style: "dotted" },
        },
        alignment: { horizontal: "center", vertical: "center", wrapText: true },
      });

      applyCellStyle(worksheet, range, colorYellow, {
        fill: { patternType: "solid", fgColor: { rgb: "ffd965" } },
        border: {
          top: { style: "dotted" },
          left: { style: "dotted" },
          bottom: { style: "dotted" },
          right: { style: "dotted" },
        },
        alignment: { horizontal: "center", vertical: "center", wrapText: true },
      });

      const colorPink1 = ["Giờ công thực tế", "Lương thực tế"];
      applyCellStyle(worksheet, range, colorPink1, {
        fill: { patternType: "solid", fgColor: { rgb: "fde9d9" } },
        border: {
          top: { style: "dotted" },
          left: { style: "dotted" },
          bottom: { style: "dotted" },
          right: { style: "dotted" },
        },
        alignment: { horizontal: "center", vertical: "center", wrapText: true },
      });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, worksheet, "Sheet1");
      XLSX.writeFile(wb, "salary.xlsx");
      toast.success("Thành công");
    } catch (error) {
      console.log("Có lỗi", error);
      toast.success("Đã có lỗi xảy ra");
    }
  };

  function checkedAll() {
    return (salaryList?.data ?? []).length === (selectSalary ?? []).length;
  }

  var _selected = checkedAll();

  const handleCheck = (id) => {
    if (selectSalary.includes(id)) {
      setSelectSalary(selectSalary.filter((item) => item !== id));
    } else {
      setSelectSalary([...selectSalary, id]);
    }
  };

  return (
    <div className="container-fluid">
      <div className="header-page">
        <h4 className="h4 title_content mb-0 text-gray-800">Bảng lương</h4>
        <div style={{ display: "flex" }}>
          {PermissionChecker({
            decent: reportNumber.permissions?.send_salary_table ?? false,
            child: (
              <button
                onClick={(e) => {
                  updateSalaryTable({ isAll: true, status: 1 });
                }}
                style={{ marginRight: "10px" }}
                class={`btn btn-success btn-icon-split btn-sm  `}
              >
                <span class="icon text-white-50">
                  <i class="fas fa-file-export"></i>
                </span>
                <span style={{ color: "white" }} class="text">
                  Gửi bảng lương cho tất cả nhân viên
                </span>
              </button>
            ),
          })}
          {PermissionChecker({
            decent: reportNumber.permissions?.salary_manager ?? false,
            child: (
              <button
                onClick={handleExportSalary}
                style={{ marginRight: "10px" }}
                class={`btn btn-danger btn-icon-split btn-sm  `}
              >
                <span class="icon text-white-50">
                  <i class="fas fa-file-export"></i>
                </span>
                <span style={{ color: "white" }} class="text">
                  Export bảng lương
                </span>
              </button>
            ),
          })}
          {PermissionChecker({
            decent: reportNumber.permissions?.send_salary_table ?? false,
            child: (
              <button
                onClick={(e) => {
                  setModal({
                    title: `Làm mới bảng lương`,
                    method: "RESET",
                    visible: true,
                  });
                }}
                style={{ marginRight: "10px" }}
                class={`btn btn-secondary btn-icon-split btn-sm  `}
              >
                <span class="icon text-white-50">
                  <i class="fas fa-file-export"></i>
                </span>
                <span style={{ color: "white" }} class="text">
                  Làm mới bảng lương
                </span>
              </button>
            ),
          })}
          {PermissionChecker({
            decent: reportNumber.permissions?.salary_add ?? false,
            child: (
              <button
                class={`btn btn-info btn-icon-split btn-sm`}
                onClick={() => {
                  setModal({
                    title: `Thêm mới bảng công`,
                    method: "CREATE",
                    visible: true,
                  });
                }}
              >
                <span class="icon text-white-50">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="text">Thêm bảng lương</span>
              </button>
            ),
          })}
        </div>
      </div>
      <div className="card shadow mb-4 pt-4 pb-4">
        <div className="card-tab">
          <DatePicker
            value={moment(`${query.month}-${query.year}`, "MM-yyyy").toDate()}
            format="MM-yyyy"
            cleanable={false}
            onChange={handleChangeDate}
          />
        </div>
        <div className="card-body">
          {selectSalary.length > 0 ? (
            PermissionChecker({
              decent: reportNumber.permissions?.salary_delete ?? false,
              child: (
                <button
                  style={{ marginBottom: "10px" }}
                  className="btn btn-danger btn-icon-split btn-sm show"
                  onClick={() => {
                    deleteSalaryMany();
                  }}
                  data-toggle="modal"
                  data-target="#modalUID"
                >
                  <span className="icon text-white-50">
                    <i className="fas fa-remove" />
                  </span>
                  <span className="text">Xóa bảng lương</span>
                </button>
              ),
            })
          ) : (
            <></>
          )}
          <div className="table-responsive">
            <div id="dataTable_wrapper">
              <table
                className="table no-footer dataTable table-striped"
                id="dataTable"
                width="100%"
                cellSpacing={0}
                aria-describedby="dataTable_info"
              >
                <thead>
                  <tr className="table-row">
                    <th>
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        value={_selected}
                        checked={_selected}
                        onChange={(e) => {
                          if (_selected == true) {
                            setSelectSalary([]);
                          } else {
                            setSelectSalary(
                              (salaryList?.data ?? []).map((e) => e.id)
                            );
                          }
                        }}
                      />
                    </th>
                    <th>STT</th>
                    <th>Tên nhân viên</th>
                    <th className="text-center">Số giờ làm (giờ)</th>
                    <th className="text-center">Lương thực nhận (VNĐ)</th>
                    <th className="text-center">Tháng áp dụng</th>
                    <th className="text-center">Trạng thái</th>
                    <th className="text-center" style={{ width: "200px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {salaryList?.data?.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor:
                          item?.confirm == true ? "#c4e3c5" : "white",
                      }}
                      onClick={(e) => {
                        setSalary(item);
                        setModal({
                          title: `Chi tiết bảng lương`,
                          method: "DETAIL",
                          visible: true,
                        });
                      }}
                    >
                      <td>
                        <input
                          type="checkbox"
                          name=""
                          id={index}
                          checked={
                            selectSalary.filter((v) => v == item.id).length == 1
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={(e) => {
                            e.stopPropagation();
                            handleCheck(item.id);
                          }}
                        />
                      </td>
                      <td>{index + 1}</td>
                      <td>{item.staff?.name || ""}</td>
                      <td className="text-center">
                        {item.work_time
                          ? (item.work_time / 3600).toFixed(2)
                          : 0}
                      </td>
                      <td className="text-center">
                        {item.salary_total
                          ? formatMoneyVND((item.salary ?? 0).toFixed(2))
                          : 0}
                      </td>
                      <td className="text-center">
                        {`${item?.month || 0}-${item?.year || 0}`}
                      </td>
                      <td
                        className="text-center"
                        style={{ color: item?.status === 0 ? "red" : "green" }}
                      >
                        {item?.confirm == true
                          ? "Đã xác nhận"
                          : item?.status === 0
                          ? "Chưa gửi"
                          : "Đã gửi"}
                      </td>
                      <td className="group-action-btn">
                        <div class="dropdown">
                          <button
                            style={{ color: "green", fontWeight: "bold" }}
                          >
                            . . .
                          </button>
                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                            style={{ marginBottom: "10px" }}
                          >
                            {PermissionChecker({
                              decent:
                                reportNumber.permissions?.send_salary_table ??
                                false,
                              child: (
                                <button
                                  class={`btn-success btn-sm dropdown-item`}
                                  style={{
                                    backgroundColor:
                                      item.status === 0 ? "#47dba2" : "grey",
                                    marginTop: "5px",
                                  }}
                                  onClick={() => {
                                    updateSalaryTable({
                                      isAll: false,
                                      listSalaryId: [item.id],
                                      status: item.status === 0 ? 1 : 0,
                                    });
                                  }}
                                  data-toggle="modal"
                                  data-target="#modalUID"
                                >
                                  <span className="icon text-white mr-1">
                                    <i
                                      className={`fas fa-${
                                        item.status === 0 ? "send" : "trash"
                                      }`}
                                    ></i>
                                  </span>
                                  <span className="btn-trash-text">
                                    {item.status === 0
                                      ? "Gửi bảng lương"
                                      : "Ẩn bảng lương"}
                                  </span>
                                </button>
                              ),
                            })}
                            <button
                              class={`btn-success btn-sm dropdown-item`}
                              style={{
                                backgroundColor: "#47dba2",
                                marginTop: "5px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setSalary(item);
                                setModal({
                                  title: `Chi tiết bảng lương`,
                                  method: "DETAIL",
                                  visible: true,
                                });
                              }}
                              data-toggle="modal"
                              data-target="#modalShift"
                            >
                              <span className="icon text-white mr-1">
                                {/* <i class="fas fa-edit"></i> */}
                              </span>
                              <span className="">Xem chi tiết</span>
                            </button>
                            {PermissionChecker({
                              decent:
                                reportNumber.permissions?.salary_edit ?? false,
                              child: (
                                <button
                                  class={`btn-success btn-sm dropdown-item`}
                                  style={{
                                    backgroundColor: "#6379f0",
                                    marginTop: "5px",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSalary(item);
                                    setModal({
                                      title: `Chỉnh sửa bảng lương`,
                                      method: "UPDATE",
                                      visible: true,
                                    });
                                  }}
                                  data-toggle="modal"
                                  data-target="#modalShift"
                                >
                                  <span className="icon text-white mr-1">
                                    {/* <i class="fas fa-edit"></i> */}
                                  </span>
                                  <span className="">Chỉnh sửa</span>
                                </button>
                              ),
                            })}
                            {PermissionChecker({
                              decent:
                                reportNumber.permissions?.salary_edit ?? false,
                              child: (
                                <button
                                  class={`btn-secondary btn-sm dropdown-item`}
                                  style={{
                                    backgroundColor: "#4c9fbb",
                                    marginTop: "5px",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleResetSalary(item);
                                  }}
                                  data-toggle="modal"
                                  data-target="#modalShift"
                                >
                                  <span className="icon text-white mr-1">
                                    {/* <i class="fas fa-edit"></i> */}
                                  </span>
                                  <span className="">Làm mới</span>
                                </button>
                              ),
                            })}
                            {PermissionChecker({
                              decent:
                                reportNumber.permissions?.salary_delete ??
                                false,
                              child: (
                                <button
                                  class={`btn-success btn-sm dropdown-item`}
                                  style={{
                                    backgroundColor: "red",
                                    marginTop: "5px",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setModal({
                                      title: `Thông báo`,
                                      method: "DELETE",
                                      visible: true,
                                    });
                                    setSalary(item);
                                  }}
                                  data-toggle="modal"
                                  data-target="#modalUID"
                                >
                                  <span className="icon text-white mr-1">
                                    <i className="fas fa-trash"></i>
                                  </span>
                                  <span className="btn-trash-text">Xoá</span>
                                </button>
                              ),
                            })}
                          </div>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div style={{ height: "50px" }}></div>
              {salaryList && salaryList?.data?.length > 0 && (
                <Pagination
                  to={salaryList?.to}
                  handleClickItem={handleChangePageIndex}
                  links={salaryList?.links}
                  lastPage={salaryList?.last_page}
                  currentPage={salaryList?.current_page}
                ></Pagination>
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        visible={modal.visible && modal.method === "DELETE"}
        title={"Thông báo"}
        onSubmit={handleDelete}
        closeModal={handleCloseModal}
      />
      <SalaryCreateModal
        visible={modal.visible && modal.method === "CREATE"}
        title={modal.title}
        onSuccess={handleSuccessSalaryModal}
        closeModal={handleCloseModal}
      />
      <SalaryResetModal
        visible={modal.visible && modal.method === "RESET"}
        title={modal.title}
        onSuccess={handleSuccessSalaryModal}
        closeModal={handleCloseModal}
      />
      <SalaryDetailModal
        visible={modal.visible && modal.method === "DETAIL"}
        title={modal.title}
        id={salary?.id}
        closeModal={handleCloseModal}
      />
      <UpdateSalaryTable
        visible={modal.visible && modal.method === "UPDATE"}
        salaryTableInput={salary}
        closeModal={handleCloseModal}
        onSuccess={handleSuccessSalaryModal}
      />
    </div>
  );
};

export default Salary;
