import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import {
  Space,
  Table,
  Button,
  Input,
  DatePicker as a,
  Select,
  ConfigProvider,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { shiftServices } from "../../services/shiftServices";
import viVN from "antd/locale/vi_VN";
import PermissionChecker from "../../components/PermissionChecker";
import dayjs from "dayjs";
import JobBoardModal from "../Timekeeping/JobBoard/components/JobBoardModal";
import { constants } from "../../constants";
import { toast } from "react-toastify";
import moment from "moment";
const { Search } = Input;
const { RangePicker } = a;

export default function FromUserHistoryPage() {
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const departmentList = useSelector((state) => state.staff.department_list);
  const dispatch = useDispatch();
  const DATE_FORMAT_DDMMYYYY_HHMMSS = "YYYY-MM-DD HH:mm:ss";
  const Option = Select.Option;
  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
    componentContentModal: null,
    histories: [],
  });
  const [staffs, setStaffs] = useState([]);
  const [loading, setLoading] = useState(null);
  const areaId = useSelector((state) => state.area.areaId);
  const [dateRange, setDateRange] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState(null);
  const [descending, setDescending] = useState("2");
  const [search, setSearch] = useState("");
  const areas = useSelector((state) => state.area.areas);
  const getAreaById = (areaId) => {
    var result = areas.data.filter((a) => a.id === areaId);

    if (result.length == 0) return;
    return result[0];
  };

  const handleDateChange = (value, type) => {
    setDateRange(value);
  };

  const getClockInColleague = async (page, search, department, descending) => {
    if (search) {
      setSearch(search);
    }
    if (department) {
      setDepartmentSelected(department);
    }
    setLoading(true);
    try {
      const response = await shiftServices.getClockInColleague(
        page,
        search,
        areaId,
        dateRange == null
          ? null
          : moment(dateRange[0].toDate() ?? Date()).format("YYYY-MM-DD"),
        dateRange == null
          ? null
          : moment(dateRange[1].toDate() ?? Date()).format("YYYY-MM-DD"),
        department,
        descending == "1" ? false : true
      );
      setStaffs(response);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setDateRange([dayjs().startOf("month"), dayjs().endOf("month")]);
  }, []);

  useEffect(() => {
    getClockInColleague(1);
    // xử lý lấy from và to tháng hiện tại
  }, [areaId]);

  const handleCloseModal = () => {
    setModal({ title: "", method: "", visible: false });
  };

  const handleSaveBonusLess = async (data) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });

      if ((data.list_staff ?? []).length === 0) {
        toast.error("Chưa chọn nhân viên");

        return false;
      }

      if ((data.custom_day ?? []).length === 0) {
        toast.error("Chưa chọn ngày bổ sung");

        return false;
      }

      if (
        (data.custom_day ?? []).length === 1 &&
        (data.list_staff ?? []).length === 1
      ) {
        if (data.checkout_time.getTime() <= data.checkin_time.getTime()) {
          toast.error("Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc");

          return false;
        }
      }

      const bonusLessUpdate = {
        is_bonus: true,
        checkin_time: moment(data.checkin_time)
          .format(DATE_FORMAT_DDMMYYYY_HHMMSS)
          .toString(),
        checkout_time: moment(data.checkout_time)
          .format(DATE_FORMAT_DDMMYYYY_HHMMSS)
          .toString(),
        reason: data.reason,
        is_over_time: data.is_over_time,
        list_staff_id: (data.list_staff ?? []).map((e) => e.id),
        custom_day: data.custom_day.map((e) =>
          moment(e.toDate()).format("YYYY-MM-DD")
        ),
      };

      const response = await shiftServices.createBonusLess({
        areaId: areaId,
        body: bonusLessUpdate,
      });

      if (response.success) {
        handleCloseModal();
        toast.success(response.msg);
      } else {
        dispatch({
          type: constants.FAILURE,
          code: response.code,
          message: response.message,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Lỗi khi thêm bớt công cho nhân viên");
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const columns = [
    {
      title: "Họ và tên",
      dataIndex: "name",
      key: "name",
      render: (name) => <a>{name}</a>,
    },

    {
      title: "Chi nhánh",
      dataIndex: "area_id",
      key: "area_id",
      render: (areaId) => <a>{getAreaById(areaId)?.name ?? "--:--"}</a>,
    },
    {
      title: "Số lần được chấm hộ",
      dataIndex: "clock_in_colleague",
      key: "clock_in_colleague",
      render: (text) => <a>{text}</a>,
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center mt-2">
          {/* <h4 className="h4 title_content mb-0 text-gray-800">Câu hỏi hỗ trợ</h4> */}
          <Space>
            <Search
              placeholder="Tìm kiếm khách hàng"
              onSearch={(v) => {
                getClockInColleague(1, v, departmentSelected, descending);
              }}
              enterButton
              loading={loading}
              style={{
                width: 300,
              }}
            />
            <ConfigProvider locale={viVN}>
              <RangePicker
                onChange={(value) => {
                  handleDateChange(value, "day");
                }}
                value={dateRange}
              />
            </ConfigProvider>
            <Select
              placeholder="Chọn Phòng ban"
              allowClear
              value={departmentSelected}
              style={{ width: "150px" }}
              onChange={(v) => {
                setDepartmentSelected(v);
              }}
            >
              {(departmentList?.data ?? []).map((e) => {
                return <Option value={e.id}>{e.name}</Option>;
              })}
            </Select>
            <Select
              placeholder="Sắp xếp"
              allowClear
              value={descending}
              style={{ width: "150px" }}
              onChange={(v) => {
                setDescending(v);
              }}
            >
              <Option value="2">Giảm dần</Option>
              <Option value="1">Tăng dần</Option>
            </Select>
            <Button
              type="primary"
              onClick={(e) => {
                getClockInColleague(1, search, departmentSelected, descending);
              }}
            >
              Lọc
            </Button>
            <p>{`Số lượng tổng chấm công hộ ${
              staffs.total_clock_in_at_area ?? 0
            }`}</p>
          </Space>
          {PermissionChecker({
            decent: reportNumber.permissions?.calculate_edit ?? false,
            child: (
              <Button
                type="primary"
                shape="round"
                size={20}
                icon={<MdAdd />}
                onClick={(e) => {
                  setModal({
                    title: "Thêm bớt công",
                    method: "CREATE",
                    visible: true,
                  });
                }}
              >
                Thêm bớt công
              </Button>
            ),
          })}
        </div>
        <div style={{ height: "20px" }}></div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={(staffs?.data?.data ?? []).map((e) => {
            return {
              key: `${e.id}`,
              data: e,
              name: e.name ?? "--:--",
              area_id: e.area_id,
              clock_in_colleague: e.clock_in_colleague ?? 0,
            };
          })}
          pagination={{
            total: staffs?.total ?? 0,
            pageSize: 20,
          }}
          onChange={(e) => {
            getClockInColleague(e.current);
          }}
        />
        <JobBoardModal
          visible={modal.visible && modal.method === "CREATE"}
          title={modal.title}
          staffs={staffs?.data}
          closeModal={handleCloseModal}
          onSubmit={handleSaveBonusLess}
        />
      </div>
    </>
  );
}
