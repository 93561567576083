import { constants as c } from "../constants";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
const companyId =
  localStorage.getItem("companyId") == "undefined" ||
  localStorage.getItem("companyId") == null
    ? 1
    : JSON.parse(localStorage.getItem("companyId"));

async function getHelp(param) {
  return fetch(`${c.API_URL}/help${param}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function addHelp(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/help`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function updateHelp(data, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/help/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getOneHelp( id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };

  return fetch(`${c.API_URL}/help/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function deleteHelp(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };

  return fetch(`${c.API_URL}/help/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

export const helpServices = {
  getHelp,
  addHelp,
  updateHelp,
  deleteHelp,
  getOneHelp,
};
