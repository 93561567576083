import React, { useState } from "react";
import moment from "moment";
import { constants } from "../../../constants";
import { useSelector } from "react-redux";

export default function IncidentItem({
  data,
  index,
  isSelected,
  onChoose,
  onClick,
  onClickStaffRelate,
  accept,
  cancel,
}) {
  const [imageError, setImageError] = useState(false);

  const onError = () => {
    setImageError(true);
  };

  const reportNumber = useSelector((state) => state.reportNumber.report);

  const checkPermission = (process) => {
    const index = (process?.approvers ?? []).findIndex(
      (e) => e.id === reportNumber.current_user?.id
    );
    if (index != -1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <tr
      style={{ height: "120px", borderBottom: "1px solid lightgrey" }}
      onClick={(e) => {
        onClick(data);
      }}
    >
      <td style={{ textAlign: "center" }}>
        <input
          type="checkbox"
          checked={isSelected}
          onClick={(e) => {
            e.stopPropagation();
            onChoose();
          }}
        />
      </td>
      <td className="text-center">{index + 1}</td>
      <td>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "16px" }}>
            {imageError || data?.staff_create[0]?.staff?.avatar === null ? (
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "20%",
                  objectFit: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f6c23e",
                  marginRight: "0px",
                }}
              >
                <div style={{ fontWeight: "bold", color: "white" }}>
                  {`${data?.staff_create[0]?.staff?.name ?? ""}` 
                    .charAt(0)
                    .toUpperCase()}
                </div>
              </div>
            ) : (
              <img
                src={data?.staff_create[0]?.staff?.avatar ?? 'https://admin.comnieuthienly.vn/images/logo.jpg'}
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "20%",
                  objectFit: "cover",
                  border: "1px solid #ccc",
                }}
                onError={onError}
              />
            )}
          </div>
          <div>
            <div style={{ fontWeight: "bold", color: "black" }}>
              {data?.staff_create[0]?.staff?.name ?? "Hệ thống"}
            </div>
            <div style={{ fontSize: "13px" }}>
              {data?.staff_create[0]?.staff?.phone_number ?? ""}
            </div>
          </div>
        </div>
      </td>
      <td>{data?.title ?? ""}</td>
      <td>
        <div
          style={{ padding: "10px", maxHeight: "120px", overflow: "hidden" }}
          dangerouslySetInnerHTML={{ __html: data?.content ?? "" }}
        ></div>
      </td>
      <td>
        <ol style={{ padding: "10px" }}>
          {(data?.area ?? []).slice(0, 3).map((a) => {
            return (
              <li>
                <div
                  style={{
                    color: "blue",
                  }}
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   onClickStaffRelate(xe.staff);
                  // }}
                >
                  {a?.area?.name ?? ""}
                </div>
              </li>
            );
          })}
        </ol>

        <ol style={{ paddingLeft: "10px" }}>
          {(data?.department ?? []).slice(0, 3).map((d) => {
            return (
              <li>
                <div
                  style={{
                    color: "blue",
                  }}
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   onClickStaffRelate(xe.staff);
                  // }}
                >
                  {d?.department?.name ?? ""}
                </div>
              </li>
            );
          })}
        </ol>
        <ol style={{ paddingLeft: "10px" }}>
          {(data?.staff_relate ?? []).map((xe) => {
            return (
              <li>
                <div
                  style={{
                    color: "blue",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickStaffRelate(xe.staff);
                  }}
                >
                  {xe?.staff?.name ?? ""}
                </div>
              </li>
            );
          })}
        </ol>
      </td>
      <td>{data?.address_happen ?? ""}</td>
      <td>
        {moment.utc(data?.created_at).format(constants.FORMAT_DD_MM_YYYY_HH_MM)}
      </td>
      {data?.procedure_status === 1 && checkPermission(data?.process) ? (
        <td style={{ textAlign: "center" }}>
          <div className="d-flex justify-content-center align-items-center">
            <button 
              type="button"
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                borderColor: "#f6c23e",
                borderWidth: "1px",
                borderStyle: "solid",
                color: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                cancel();
              }}
            >
              Từ chối
            </button>
            <div style={{ width: "10px" }}></div>
            <button
              type="button"
              style={{
                backgroundColor: "#f6c23e",
                borderRadius: "8px",
                color: "white",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                accept();
              }}
            >
              Chấp nhận
            </button>
          </div>
        </td>
      ) : (
        <></>
      )}
    </tr>
  );
}
