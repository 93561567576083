import React from "react";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import { Col, Form, Row, Space, Avatar, Divider, Spin, Button } from "antd";
import moment from "moment";
import { franchiseServices } from "../../../../../services/franchiseServices";

const ReportModal = ({ open, closeModal, onOk, dataInput }) => {
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (dataInput != null) {
      if (open == true) {
        getReportDetail(dataInput.id);
      }
    } else {
    }
  }, [open]);

  const getReportDetail = async (id) => {
    setLoading(true);
    try {
      const response = await franchiseServices.getReportDetail(id);
      setReport(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  function downloadFile(url) {
    const tagA = document.createElement("a");
    tagA.href = url;
    tagA.download = "file";
    tagA.target = "_blank";
    tagA.click();
  }

  return (
    <Modal
      title={"Chi tiết báo cáo"}
      centered
      open={open}
      width={1000}
      style={{ top: 10 }}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      {loading ? (
        <Spin></Spin>
      ) : (
        <div
          style={{
            overflowY: "auto",
            height: "auto",
            maxHeight: "80vh",
          }}
        >
          <p className="text-[20px] font-medium">{report?.title ?? "--:--"}</p>
          <p className="text-[14px] font-medium">
            <span>Loại báo cáo:</span> {report?.report_cate?.name ?? "--:--"}
          </p>
          <div>
            <p className="font-medium">Nội dung:</p>
            {loading == null ? (
              <div className="col-9">
                {" "}
                <Spin></Spin>
              </div>
            ) : (
              <div
                style={{
                  width: "95%",
                  marginTop: "10px",
                  background: "#f5f5f5",
                  padding: "20px",
                  borderRadius: "10px",
                }}
                dangerouslySetInnerHTML={{
                  __html: report?.content ?? "",
                }}
              ></div>
            )}
          </div>
          <div className="d-flex justify-contents-start align-items-center mt-2">
            {report?.image_url != null ? (
              <img
                src={report?.image_url}
                style={{
                  width: "140px",
                  height: "140px",
                  borderRadius: "10px",
                  objectFit: "cover",
                }}
                alt=""
              />
            ) : (
              <></>
            )}
            {report?.file_url != null ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  padding: "20px",
                  margin: "20px",
                  width: "100px",
                  boxShadow: "0 0 10px rgba(98, 98, 98, 0.2)",
                }}
                onClick={(x) => {
                  downloadFile(report?.file_url);
                }}
              >
                <img
                  src="/images/folder.png"
                  style={{ width: "50px", height: "50px" }}
                ></img>
                <div style={{ height: "5px" }}></div>
                <span className="px-3 text-[11  px]">
                  {`${report?.file_url}`.replace(
                    "https://api.comnieuthienly.vn/i/",
                    ""
                  )}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ReportModal;
