/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect, useState } from "react";
import { Modal, Space, Switch } from "antd";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  ConfigProvider,
} from "antd";
import "suneditor/dist/css/suneditor.min.css";
import { companyServices } from "../../../../services/companyServices";
import UploadImageSingle from "../../../../components/UploadImageSingle";

const AddCompanyModal = ({ open, closeModal, onOk, dataInput }) => {
  const [reset, setReset] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modulesPermission, setModulesPermission] = useState({
    timekeeping: false,
    education: false,
    request: false,
    incident: false,
    franchise: false,
    assignment: false,
  });
  const [form] = Form.useForm();

  useEffect(() => {
    if (dataInput != null) {
      if (open == true) {
        setImageUrl(dataInput.logo);
        onFill();
      }
    } else {
      onReset();
    }
  }, [open]);

  const createCompany = async (data) => {
    setLoading(true);
    try {
      const response = await companyServices.createCompany(data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const updateCompany = async (data, id) => {
    try {
      const response = await companyServices.updateCompany({ id, data });
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = (values) => {
    console.log(values);
    values.logo = imageUrl;
    values.modules_permission = modulesPermission;
    if (dataInput != null) {
      updateCompany(values, dataInput.id);
    } else {
      createCompany(values);
    }
  };

  const onFill = () => {
    form.setFieldsValue({
      name: dataInput?.name ?? "",
      logo: dataInput?.logo ?? "",
      address: dataInput?.address ?? "",
      phone_number: dataInput?.phone_number ?? "",
    });
    setModulesPermission(
      dataInput?.modules_permission ?? {
        timekeeping: false,
        education: false,
        request: false,
        incident: false,
        franchise: false,
        assignment: false,
      }
    );
    setReset(false);
  };

  const onReset = () => {
    setImageUrl(null);
    setModulesPermission({
      timekeeping: false,
      education: false,
      request: false,
      incident: false,
      franchise: false,
      assignment: false,
    });
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 20,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 22,
      span: 28,
    },
  };

  return (
    <Modal
      title={dataInput?.id != null ? "Sửa công ty" : "Tạo công ty"}
      centered
      open={open}
      width={1000}
      style={{ top: 20 }}
      hildren={form}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div
        className="mt-4"
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "80vh",
          margin: "10px",
        }}
      >
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          style={{ width: "95%" }}
        >
          <Form.Item name="image_url" label="Ảnh:  " hasFeedback>
            <UploadImageSingle
              imageInput={imageUrl ?? null}
              title={"Ảnh"}
              onDone={(e) => {
                setImageUrl(e);
              }}
            />
          </Form.Item>
          <Form.Item
            name="name"
            label="Tên công ty"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập tên công ty"} />
          </Form.Item>
          <Form.Item
            name="phone_number"
            label="Số điện thoại"
            rules={[
              {
                required: false,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập số điện thoại"} />
          </Form.Item>
          <Form.Item
            name="address"
            label="Địa chỉ"
            rules={[
              {
                required: false,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập địa chỉ"} />
          </Form.Item>

          <Form.Item name="" label="Chức năng">
            <Space direction="vertical" size={"middle"}>
              <Space>
                <b>Đào tạo</b>
                <Switch
                  checked={modulesPermission?.education ?? false}
                  onChange={(e) => {
                    setModulesPermission({
                      ...modulesPermission,
                      education: e,
                    });
                  }}
                ></Switch>
              </Space>
              <Space>
                <b>Phiếu yêu cầu</b>
                <Switch
                  checked={modulesPermission?.request ?? false}
                  onChange={(e) => {
                    console.log(e);
                    setModulesPermission({
                      ...modulesPermission,
                      request: e,
                    });
                  }}
                ></Switch>
              </Space>
              <Space>
                <b>Thông tin/Biên bản</b>
                <Switch
                  checked={modulesPermission?.incident ?? false}
                  onChange={(e) => {
                    setModulesPermission({
                      ...modulesPermission,
                      incident: e,
                    });
                  }}
                ></Switch>
              </Space>
              <Space>
                <b>Giao việc</b>
                <Switch
                  checked={modulesPermission?.assignment ?? false}
                  onChange={(e) => {
                    setModulesPermission({
                      ...modulesPermission,
                      assignment: e,
                    });
                  }}
                ></Switch>
              </Space>
            </Space>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddCompanyModal;
