import { constants as c } from "../constants";

const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
const companyId =
  localStorage.getItem("companyId") == "undefined" ||
  localStorage.getItem("companyId") == null
    ? 1
    : JSON.parse(localStorage.getItem("companyId"));

const requestOptions = ({ method, bodyData }) => {
  let options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo || "",
      "company-id": companyId ?? 1,
    },
  };
  bodyData && (options.body = JSON.stringify(bodyData));

  return options;
};

const getCompany = async () => {
  return fetch(`${c.API_URL}/company`, requestOptions({ method: "GET" }))
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const createCompany = async ({ data }) => {
  return fetch(
    `${c.API_URL}/company`,
    requestOptions({ method: "POST", bodyData: data })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      console.log("err", err);
      throw err;
    });
};

const updateCompany = async ({ id, data }) => {
  return fetch(
    `${c.API_URL}/company/${id}`,
    requestOptions({ method: "PUT", bodyData: data })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      console.log("err", err);
      throw err;
    });
};

const deleteCompany = async (id) => {
  return fetch(
    `${c.API_URL}/company/${id}`,
    requestOptions({ method: "DELETE" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      console.log("err", err);
      throw err;
    });
};

export const companyServices = {
  getCompany,
  createCompany,
  updateCompany,
  deleteCompany,
};
