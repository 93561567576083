import { constants as c } from "../constants";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
const companyId =
  localStorage.getItem("companyId") == "undefined" ||
  localStorage.getItem("companyId") == null
    ? 1
    : JSON.parse(localStorage.getItem("companyId"));

async function getReport(param) {
  return fetch(`${c.API_URL}/franchise/report${param}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getReportDetail(id) {
  return fetch(`${c.API_URL}/franchise/report/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function addReport(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/report`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function updateReport(data, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/report/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function deleteReport(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };

  return fetch(`${c.API_URL}/franchise/report/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getReportCate() {
  return fetch(`${c.API_URL}/franchise/report-cate`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function addReportCate(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/report-cate`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function updateReportCate(data, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/report-cate/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function deleteReportCate(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };

  return fetch(`${c.API_URL}/franchise/report-cate/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getFileFranchise(param) {
  return fetch(`${c.API_URL}/franchise/file-franchise${param}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function addFileFranchise(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/file-franchise`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function updateFileFranchise(data, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/file-franchise/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function deleteFileFranchise(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };

  return fetch(`${c.API_URL}/franchise/file-franchise/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getFranchiseCate() {
  return fetch(`${c.API_URL}/franchise/categories`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function addFranchiseCate(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/categories`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function updateFranchiseCate(data, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/categories/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function deleteFranchiseCate(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };

  return fetch(`${c.API_URL}/franchise/categories/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function sortFileCate(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/categories-sort`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function sortReportCate(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/report-cate-sort`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getMail(param) {
  return fetch(`${c.API_URL}/franchise/mail${param}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getMailDetail(id) {
  return fetch(`${c.API_URL}/franchise/mail/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getMailMess(id) {
  return fetch(`${c.API_URL}/franchise/mail/mess/?mail_id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function addMailMess(data) {
  return fetch(`${c.API_URL}/franchise/mail/mess`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getMailMessDetail(id) {
  return fetch(`${c.API_URL}/franchise/mail/mess/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function addMail(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/mail`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function updateMail(data, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/mail/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function deleteMail(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };

  return fetch(`${c.API_URL}/franchise/mail/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getCustomerFranchise(param) {
  return fetch(`${c.API_URL}/franchise/customers${param}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function addCustomer(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/customers`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function updateCustomer(data, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/customers/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function deleteCustomer(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };

  return fetch(`${c.API_URL}/franchise/customers/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function updateInfoFranchiseCustomer(data, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${c.API_URL}/franchise/customers/franchise_info/${id}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getMeeting() {
  return fetch(`${c.API_URL}/franchise/meeting`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function addMeeting(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/meeting`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function updateMeeting(data, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/meeting/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function deleteMeeting(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };

  return fetch(`${c.API_URL}/franchise/meeting/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getMeetingDetail(id) {
  return fetch(`${c.API_URL}/franchise/meeting/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getPost() {
  return fetch(`${c.API_URL}/franchise/post`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function addPost(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/post`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function updatePost(data, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/post/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function deletePost(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };

  return fetch(`${c.API_URL}/franchise/post/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getPostDetail(id) {
  return fetch(`${c.API_URL}/franchise/post/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getHelp(param) {
  return fetch(`${c.API_URL}/franchise/help${param}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function addHelp(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/help`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function updateHelp(data, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/help/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function deleteHelp(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };

  return fetch(`${c.API_URL}/franchise/help/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getHelpDetail(id) {
  return fetch(`${c.API_URL}/franchise/help/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getContact() {
  return fetch(`${c.API_URL}/franchise/contact`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function addContact(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${c.API_URL}/franchise/contact`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function deleteContact(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };

  return fetch(`${c.API_URL}/franchise/contact/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function getRegister(param) {
  return fetch(`${c.API_URL}/franchise/register${param}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function deleteRegister(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };

  return fetch(`${c.API_URL}/franchise/register/${id}`, requestOptions)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

export const franchiseServices = {
  getFileFranchise,
  addFileFranchise,
  updateFileFranchise,
  deleteFileFranchise,
  getFranchiseCate,
  addFranchiseCate,
  updateFranchiseCate,
  deleteFranchiseCate,
  getReport,
  addReport,
  updateReport,
  deleteReport,
  getReportCate,
  addReportCate,
  updateReportCate,
  deleteReportCate,
  getMail,
  getMailDetail,
  updateMail,
  addMail,
  deleteMail,
  getMailMess,
  getMailMessDetail,
  addMailMess,
  getCustomerFranchise,
  addCustomer,
  updateCustomer,
  deleteCustomer,
  updateInfoFranchiseCustomer,
  getMeeting,
  addMeeting,
  updateMeeting,
  deleteMeeting,
  getMeetingDetail,
  getPost,
  addPost,
  updatePost,
  deletePost,
  getPostDetail,
  getHelp,
  addHelp,
  updateHelp,
  deleteHelp,
  getHelpDetail,
  getContact,
  addContact,
  deleteContact,
  getRegister,
  deleteRegister,
  sortFileCate,
  sortReportCate,
  getReportDetail,
};
