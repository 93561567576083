import { constants as c } from "../constants";

const initialState = {
  report: {
    data: [],
    menu: [
      {
        label: "Nhân viên",

        children: [
          {
            label: "Danh sách nhân viên",
            key: "/staff-temp",
          },
          {
            label: "Đánh giá thử việc",
            key: "/probation-review",
          },
        ],
      },
      {
        label: "Chấm công",
        children: [
          {
            label: "Lịch làm việc công ty",
            key: "/work-chedule",
          },
          {
            label: "Bảng công",
            key: "/job-board",
          },
          {
            label: "Bảng lương",
            key: "/salary",
          },
          {
            label: "Lịch sử chấm công",
            key: "/timekeeping-history",
          },
          {
            label: "Xếp ca và Chấm công",
            key: "/timekeeping-table",
          },
          {
            label: "Chấm hộ",
            key: "/from-user-history-page",
          },
        ],
      },
      {
        label: "Chi nhánh",
        key: "/area-page",
      },
      {
        label: "Thiết lập",
        key: "setting",
        children: [
          // {
          //   class: "notification_list",
          //
          //   label: "Thông báo",
          //
          //   key: "/notification",
          // },
          {
            label: "Phòng ban",
            key: "/department",
          },
          {
            label: "Chi nhánh",
            key: "/branch",
          },
          {
            label: "Ca - Nhóm ca",
            key: "/work-shift",
          },
          {
            label: "Cấu hình thưởng",
            key: "/bonus-config",
          },
          {
            label: "Phụ cấp trong hợp đồng",
            key: "/allowances-contract",
          },
          {
            label: "Cài đặt ngày lễ",
            key: "/setup-ot",
          },
          {
            label: "Cài đặt phân quyền",
            key: "/permission",
          },
          {
            label: "Cài đặt yêu cầu",
            key: "/procedure",
          },
          {
            label: "Cài đặt quy trình thử việc",
            key: "/probation",
          },
          {
            label: "Cài đặt mã khuyến mãi",
            key: "/coupon",
          },
          {
            label: "Cài đặt trợ giúp",
            key: "/help-page",
          },
          {
            label: "Lịch sử hệ thống",
            key: "/history-systems",
          },
          {
            label: "Đánh giá QC",
            key: "/qc-evaluate-main",
          },
          {
            label: "Bài viết - Tin tức",
            key: "/news",
          },
          {
            label: "Công ty",
            key: "/companies",
          },
        ],
      },
    ],
    status: c.NONE,
  },
};

const stt = [
  "Nhân viên",
  "Chấm công",
  "Yêu cầu",
  "Chi nhánh",
  "Biên bản/TT",
  "Đào tạo",
  "Nhượng quyền",
  "Thiết lập",
]

export function reportNumber(state = initialState, action) {
  switch (action.type) {
    case c.GET_REPORT_NUMBER:
      let newMenu = state.report.menu;

      if (action.data.modules_permission?.request == true) {
        newMenu.push({
          label: "Yêu cầu",
          children: [
            {
              label: "Nhân sự",
              key: "/personnel-form",
            },
            {
              label: "Vận hành",
              key: "/operate-form",
            },
            {
              label: "Báo cáo",
              key: "/report-form",
            },
          ],
        });
      }

      if (action.data.modules_permission?.education == true) {
        newMenu.push({
          label: "Đào tạo",
          key: "/education-page",
          children: [
            // {
            //   key: "train",
            //   key: "/all-result",
            //   label: "Tổng hợp kết quả",
            //   setOpenKey: ["/report", "/report_"],
            //   class: "report_general",
            // },
            {
              key: "/train/test_peri/report",
              label: "Tổng hợp kết quả",
            },
            {
              label: "Nhóm khoá học",
              key: "/train/group_type",
            },
            // {
            //   key: "train",
            //
            //
            //   label: "Bài Test định kỳ",
            //
            //   key: "/train/term_quiz",
            // },
            {
              label: "Bài Test định kỳ",
              key: "/train/test_peri",
            },

            // {
            //   label: "Đánh giá",
            //   key: "/evaluation",
            // },

            { label: "Bình luận bài học", key: "/lesson/comments" },
            { label: "Bình luận bài viết", key: "/news/comments" },
            { label: "Góp ý", key: "/feedback" },
          ],
        });
      }

      if (action.data.modules_permission?.incident == true) {
        newMenu.push({
          label: "Biên bản/TT",
          key: "/incident-page",
        });
      }

      if (action.data.current_user?.company_id == 1) {
        newMenu.push({
          label: "Nhượng quyền",
          key: "franchise",
          children: [
            {
              label: "Danh sách khách hàng",
              key: "/franchise-customer",
            },
            {
              label: "File lưu trữ",
              key: "/franchise-files",
            },
            {
              label: "Báo cáo khách hàng",
              key: "/franchise-reports",
            },
            {
              label: "Trao đổi thông tin",
              key: "/franchise-mails",
            },
            {
              label: "Lịch họp",
              key: "/franchise-meetings",
            },
            {
              label: "Thông tin, tin tức",
              key: "/franchise-post",
            },
            {
              label: "Câu hỏi thường gặp",
              key: "/franchise-help",
            },
            {
              label: "Liên hệ và hỗ trợ",
              key: "/franchise-contact",
            },
            {
              label: "DS đăng ký nhượng quyền",
              key: "/franchise-register",
            },
          ],
        });
      }
      
      // sắp xếp các menu theo thứ tự đã định sẵn

      let listStt = [];

      stt.forEach((item, index) => {
          // tìm index của item trong newMenu
          let indexItem = newMenu.findIndex((menu) => menu.label === item);
          if (indexItem !== -1) {
            listStt.push(newMenu[indexItem]);
            newMenu.splice(indexItem, 1);
          }
      
      }); 
 
      return {
        ...state,
        report: { ...action.data,menu: [...listStt], status: c.SUCCESS },
      };

    case c.GET_REPORT_NUMBER_FAILURE:
      return {
        ...state,
        report: { status: c.FAILURE },
      };

    default:
      return state;
  }
}
