/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { Modal, message, Upload } from "antd";
import { areaServices } from "../../../services/areaServices";
import { branchAction } from "../../../actions/branchActions";
import { toast } from "react-toastify";
import moment from "moment";
import { constants } from "../../../constants";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
const { Dragger } = Upload;

const UploadMultiFile = ({ open, closeModal, onOk, areaCurrent }) => {
  useEffect(() => {}, [open]);

  const areaId = useSelector((state) => state.area.areaId);

  const [linkFile, setLinkFile] = useState([]);

  const updateFile = async () => {
    if (linkFile.length == 0) {
      toast.error("Vui lòng tải lên tệp");
      return;
    }
    try {
      var res = await areaServices.updateArea(areaId, {
        name: areaCurrent.name,
        address: areaCurrent.address,
        date_opening: moment(areaCurrent.date_opening).format(
          constants.FORMAT_YYYY_MM_DD_HH_MM_SS
        ),
        image: areaCurrent.image,
        acreage: areaCurrent.acreage,
        file_url: linkFile,
        staff_number: areaCurrent.staff_number,
        fee: areaCurrent.fee,
        number_of_floors: areaCurrent.number_of_floors,
      });
      onOk();
    } catch (error) {
      console.log(error);
    }
  };

  const props = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    customRequest({ file, onSuccess, onError }) {
      const formData = new FormData();
      formData.append("image", file);
      fetch("https://api.comnieuthienly.vn/api/upload_v2/files?type=chat", {
        method: "POST",
        headers: {
          token: tokenInfo,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setLinkFile([...linkFile, data?.data?.image_url]);
          onSuccess();
        })
        .catch((error) => {
          onError();
        });
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <Modal
      title={"Thêm tài liệu"}
      centered
      open={open}
      onOk={() => {
        updateFile();
      }}
      onCancel={() => {
        closeModal();
      }}
    >
      <div>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Nhấp hoặc kéo tệp vào khu vực này để tải lên
          </p>
          <p className="ant-upload-hint px-3">
            Hỗ trợ tải lên một lần hoặc hàng loạt. Nghiêm cấm tải lên dữ liệu
            công ty hoặc các tập tin bị cấm khác.
          </p>
        </Dragger>
      </div>
    </Modal>
  );
};

export default UploadMultiFile;
