function getUrl() {
  var hostname = window.location.hostname;
  var port = window.location.port;
  var name = "https://api.comnieuthienly.vn/api";
  if (hostname === "localhost" && port === "3000")
   name = "https://cpn-api.comnieuthienly.vn/api";

    //name = "https://api.comnieuthienly.vn/api";
  else if (hostname === "admin.comnieuthienly.vn") {
    name = "https://api.comnieuthienly.vn/api";
  } else if (hostname === "admin-cpn.comnieuthienly.vn") {
    name = "https://cpn-api.comnieuthienly.vn/api";
  }
  return name;
}

function getPathlogo() {
  return "/images/logo.jpg";
}

function getPathlogoMain() {
  return "/images/logo_main.jpg";
}

export const constants = {
  DOMAIN_NAME: window.location.hostname,
  NOT_IMAGE:
    "https://www.fullbusiness.tn/wp-content/uploads/2019/02/image_large.png",
  FAILURE: "FAILURE",
  NOT_FOUND: "NOT_FOUND",
  NONE: "NONE",
  SUCCESS: "SUCCESS",
  LOADING: "LOADING",
  // API_URL : "https://daotao-api.herokuapp.com/api",
  UID_POPUP: "UID_POPUP",
  NO_POPUP: "NO_POPUP",
  UID_SUCCESS: "UID_SUCCESS",
  UID_ERROR: "UID_ERROR",
  CHANGE_POPUP: "CHANGE_POPUP",
  NONE_LOADING: "NONE_LOADING",
  SHOW_LOADING: "SHOW_LOADING",
  CHANGE_LOADING: "CHANGE_LOADING",
  API_URL: getUrl(),
  PATH_LOGO: getPathlogo(),
  PATH_LOGO_MAIN: getPathlogoMain(),

  GET_PROFILE: "GET_PROFILE",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  REGIS_SUCCESS: "REGIS_SUCCESS",
  ACCOUNT_UPDATE_FAILURE: "ACCOUNT_UPDATE_FAILURE",

  GET_NEWS_SUCCESS: "GET_NEWS_SUCCESS",
  GET_NEWS_FAILURE: "GET_NEWS_FAILURE",
  GET_ALL_NEWS_SUCCESS: "GET_ALL_NEWS_SUCCESS",
  RESET_NEWS_LIST_STATUS: "RESET_NEWS_LIST_STATUS",
  RESET_NEWS_STATUS: "RESET_NEWS_STATUS",
  GET_ALL_NEWS_FAILURE: "GET_ALL_NEWS_FAILURE",

  GET_EVALUATION_SUCCESS: "GET_EVALUATION_SUCCESS",
  GET_EVALUATION_FAILURE: "GET_EVALUATION_FAILURE",
  GET_ALL_EVALUATION_SUCCESS: "GET_ALL_EVALUATION_SUCCESS",
  RESET_EVALUATION_LIST_STATUS: "RESET_EVALUATION_LIST_STATUS",
  RESET_EVALUATION_STATUS: "RESET_EVALUATION_STATUS",
  GET_ALL_EVALUATION_FAILURE: "GET_ALL_EVALUATION_FAILURE",

  GET_EVALUATION_QUESTION_SUCCESS: "GET_EVALUATION_QUESTION_SUCCESS",
  GET_EVALUATION_QUESTION_FAILURE: "GET_EVALUATION_QUESTION_FAILURE",
  GET_ALL_EVALUATION_QUESTION_SUCCESS: "GET_ALL_EVALUATION_QUESTION_SUCCESS",
  RESET_EVALUATION_QUESTION_LIST_STATUS:
    "RESET_EVALUATION_QUESTION_LIST_STATUS",
  RESET_EVALUATION_QUESTION_STATUS: "RESET_EVALUATION_QUESTION_STATUS",
  GET_ALL_EVALUATION_QUESTION_FAILURE: "GET_ALL_EVALUATION_QUESTION_FAILURE",

  GET_ALL_STAFF_EXAMS_EVALUATION_SUCCESS:
    "GET_ALL_STAFF_EXAMS_EVALUATION_SUCCESS",
  RESET_STAFF_EXAMS__EVALUATION_LIST_STATUS:
    "RESET_STAFF_EXAMS__EVALUATION_LIST_STATUS",

  GET_STAFF_FAILURE: "GET_STAFF_FAILURE",
  GET_ALL_STAFFS_FAILURE: "GET_ALL_STAFFS_FAILURE",
  GET_STAFF_SUCCESS: "GET_STAFF_SUCCESS",
  RESET_STAFFS_LIST_STATUS: "RESET_STAFFS_LIST_STATUS",
  RESET_STAFF_STATUS: "RESET_STAFF_STATUS",
  GET_ALL_STAFFS_SUCCESS: "GET_ALL_STAFFS_SUCCESS",
  GET_ALL_DEPARTMENT_SUCCESS: "GET_ALL_DEPARTMENT_SUCCESS",
  RESET_DEPARTMENT_LIST_STATUS: "RESET_DEPARTMENT_LIST_STATUS",

  GET_ALL_BRANCH_SUCCESS: "GET_ALL_BRANCH_SUCCESS",
  RESET_BRANCH_LIST_STATUS: "RESET_BRANCH_LIST_STATUS",

  GET_NOTIFICATION_FAILURE: "GET_NOTIFICATION_FAILURE",
  GET_ALL_NOTIFICATIONS_FAILURE: "GET_ALL_NOTIFICATIONS_FAILURE",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  RESET_NOTIFICATIONS_LIST_STATUS: "RESET_NOTIFICATIONS_LIST_STATUS",
  RESET_NOTIFICATION_STATUS: "RESET_NOTIFICATION_STATUS",
  GET_ALL_NOTIFICATIONS_SUCCESS: "GET_ALL_NOTIFICATIONS_SUCCESS",

  GET_ALL_SCOREBOARD_SUCCESS: "GET_ALL_SCOREBOARD_SUCCESS",
  GET_ALL_SCOREBOARD_FAILURE: "GET_ALL_SCOREBOARD_FAILURE",

  GET_ALL_REPORT_STAFFS_SUCCESS: "GET_ALL_REPORT_STAFFS_SUCCESS",
  GET_REPORT_USER_WATCHS_SUCCESS: "GET_REPORT_USER_WATCHS_SUCCESS",
  RESET_REPORT_USER_WATCHS_STATUS: "RESET_REPORT_USER_WATCHS_STATUS",
  GET_ALL_REPORT_QUIZ_DEPARMENT_SUCCESS:
    "GET_ALL_REPORT_QUIZ_DEPARMENT_SUCCESS",
  GET_ALL_REPORT_QUIZS_STAFF_SUCCESS: "GET_ALL_REPORT_QUIZS_STAFF_SUCCESS",
  GET_ALL_REPORT_EVALUATION_SUCCESS: "GET_ALL_REPORT_EVALUATION_SUCCESS",
  GET_ALL_REPORT_EVALUATION_DETAIL_SUCCESS:
    "GET_ALL_REPORT_EVALUATION_DETAIL_SUCCESS",
  GET_ALL_REPORT_COURSE_STAFF_SUCCESS: "GET_ALL_REPORT_COURSE_STAFF_SUCCESS",
  GET_REPORT_FAILURE: "GET_REPORT_FAILURE",
  GET_REPORT_EVALUATION_FAILURE: "GET_REPORT_EVALUATION_FAILURE",
  GET_REPORT_EVALUATION_DETAIL_FAILURE: "GET_REPORT_EVALUATION_DETAIL_FAILURE",

  GET_COURSE_FAILURE: "GET_COURSE_FAILURE",
  GET_ALL_COURSES_FAILURE: "GET_ALL_COURSES_FAILURE",
  GET_COURSE_SUCCESS: "GET_COURSE_SUCCESS",
  RESET_COURSES_LIST_STATUS: "RESET_COURSES_LIST_STATUS",
  RESET_COURSE_STATUS: "RESET_COURSE_STATUS",
  GET_ALL_COURSES_SUCCESS: "GET_ALL_COURSES_SUCCESS",
  GET_ALL_CHAPTER_SUCCESS: "GET_ALL_CHAPTER_SUCCESS",
  RESET_CHAPTER_LIST_STATUS: "RESET_CHAPTER_LIST_STATUS",

  GET_ALL_GROUP_TYPE_SUCCESS: "GET_ALL_GROUP_TYPE_SUCCESS",
  RESET_GROUP_TYPE_LIST_STATUS: "RESET_GROUP_TYPE_LIST_STATUS",

  GET_LESSON_FAILURE: "GET_LESSON_FAILURE",
  GET_ALL_LESSONS_FAILURE: "GET_ALL_LESSONS_FAILURE",
  GET_LESSON_SUCCESS: "GET_LESSON_SUCCESS",
  RESET_LESSONS_LIST_STATUS: "RESET_LESSONS_LIST_STATUS",
  RESET_LESSON_STATUS: "RESET_LESSON_STATUS",
  GET_ALL_LESSONS_SUCCESS: "GET_ALL_LESSONS_SUCCESS",
  GET_ALL_DOCS_LESSON_SUCCESS: "GET_ALL_DOCS_LESSON_SUCCESS",
  GET_ALL_DOCS_LESSON_FAILURE: "GET_ALL_DOCS_LESSON_FAILURE",
  RESET_ALL_DOCS_LESSON_STATUS: "RESET_ALL_DOCS_LESSON_STATUS",

  GET_QUIZ_FAILURE: "GET_QUIZ_FAILURE",
  GET_ALL_QUIZS_FAILURE: "GET_ALL_QUIZS_FAILURE",
  GET_QUIZ_SUCCESS: "GET_QUIZ_SUCCESS",
  RESET_QUIZS_LIST_STATUS: "RESET_QUIZS_LIST_STATUS",
  RESET_QUIZ_STATUS: "RESET_QUIZ_STATUS",
  GET_ALL_QUIZS_SUCCESS: "GET_ALL_QUIZS_SUCCESS",
  GET_ALL_QUESTIONS_SUCCESS: "GET_ALL_QUESTIONS_SUCCESS",
  GET_ALL_QUESTIONS_FAILURE: "GET_ALL_QUESTIONS_FAILURE",
  RESET_ALL_QUESTIONS_STATUS: "RESET_ALL_QUESTIONS_STATUS",
  RESET_STUDIES_LIST_STATUS: "RESET_STUDIES_LIST_STATUS",
  RESET_STUDY_STATUS: "RESET_STUDY_STATUS",
  GET_ALL_STUDIES_SUCCESS: "GET_ALL_STUDIES_SUCCESS",
  GET_ALL_STUDIES_FAILURE: "GET_ALL_STUDIES_FAILURE",
  GET_STUDY_FAILURE: "GET_STUDY_FAILURE",
  GET_STUDY_SUCCESS: "GET_STUDY_SUCCESS",

  //NEWS COMMENT
  RESET_NEWS_COMMENT_LIST_STATUS: "RESET_NEW_COMMENT_LIST_STATUS",
  GET_ALL_NEWS_COMMENT_SUCCESS: "GET_ALL_NEW_COMMENT_SUCCESS",
  GET_ALL_NEWS_COMMENT_FAILURE: "GET_ALL_NEW_COMMENT_FAILURE",

  //NEWS COMMENT CHILD
  RESET_NEWS_COMMENT_CHILD_LIST_STATUS: "RESET_NEWS_COMMENT_CHILD_LIST_STATUS",
  GET_ALL_NEWS_COMMENT_CHILD_SUCCESS: "GET_ALL_NEWS_COMMENT_CHILD_SUCCESS",
  GET_ALL_NEWS_COMMENT_CHILD_FAILURE: "GET_ALL_NEWS_COMMENT_CHILD_FAILURE",

  //LESSON COMMENT
  RESET_LESSON_COMMENT_LIST_STATUS: "RESET_LESSON_COMMENT_LIST_STATUS",
  GET_ALL_LESSON_COMMENT_SUCCESS: "GET_ALL_LESSON_COMMENT_SUCCESS",
  GET_ALL_LESSON_COMMENT_FAILURE: "GET_ALL_LESSON_COMMENT_FAILURE",

  //LESSON COMMENT CHILD
  RESET_LESSON_COMMENT_CHILD_LIST_STATUS:
    "RESET_LESSON_COMMENT_CHILD_LIST_STATUS",
  GET_ALL_LESSON_COMMENT_CHILD_SUCCESS: "GET_ALL_LESSON_COMMENT_CHILD_SUCCESS",
  GET_ALL_LESSON_COMMENT_CHILD_FAILURE: "GET_ALL_LESSON_COMMENT_CHILD_SUCCESS",

  RESET_BANNER_STATUS: "RESET_BANNER_STATUS",
  GET_BANNER_SUCCESS: "GET_BANNER_SUCCESS",
  GET_BANNER_FAILURE: "GET_BANNER_FAILURE",

  GET_DECENTRALIZATION_SUCCESS: "GET_DECENTRALIZATION_SUCCESS",
  GET_DECENTRALIZATION_FAILURE: "GET_DECENTRALIZATION_FAILURE",
  GET_ALL_DECENTRALIZATIONS_SUCCESS: "GET_ALL_DECENTRALIZATIONS_SUCCESS",
  GET_ALL_DECENTRALIZATIONS_FAILURE: "GET_ALL_DECENTRALIZATIONS_FAILURE",
  RESET_DECENTRALIZATIONS_LIST_STATUS: "RESET_DECENTRALIZATIONS_LIST_STATUS",

  GET_ALL_ROLE_PERMISSION_SUCCESS: "GET_ALL_ROLE_PERMISSION_SUCCESS",
  GET_ALL_ROLE_PERMISSION_FAILURE: "GET_ALL_ROLE_PERMISSION_FAILURE",
  RESET_ROLE_PERMISSION_LIST_STATUS: "RESET_ROLE_PERMISSION_LIST_STATUS",

  GET_ALL_ROLE_SUCCESS: "GET_ALL_ROLE_SUCCESS",
  GET_ALL_ROLE_FAILURE: "GET_ALL_ROLE_FAILURE",
  RESET_ROLE_LIST_STATUS: "RESET_ROLE_LIST_STATUS",

  GET_ALL_USER_PERMISSION_SUCCESS: "GET_ALL_USER_PERMISSION_SUCCESS",
  GET_ALL_USER_PERMISSION_FAILURE: "GET_ALL_USER_PERMISSION_FAILURE",
  RESET_USER_PERMISSION_LIST_STATUS: "RESET_USER_PERMISSION_LIST_STATUS",
  RESET_ROLE_PERMISSION_DETAIL_STATUS: "RESET_ROLE_PERMISSION_DETAIL_STATUS",
  GET_ALL_ROLE_PERMISSION_DETAIL_SUCCESS:
    "GET_ALL_ROLE_PERMISSION_DETAIL_SUCCESS",
  GET_ALL_ROLE_PERMISSION_DETAIL_FAILURE:
    "GET_ALL_ROLE_PERMISSION_DETAIL_FAILURE",
  GET_ALL_PERMISSIONS_SUCCESS: "GET_ALL_PERMISSIONS_SUCCESS",
  GET_ALL_PERMISSIONS_FAILURE: "GET_ALL_PERMISSIONS_FAILURE",
  RESET_PERMISSIONS_LIST_STATUS: "RESET_PERMISSIONS_LIST_STATUS",
  GET_BADGES_SUCCESS: "GET_BADGES_SUCCESS",
  GET_BADGES_FAILURE: "GET_BADGES_FAILURE",
  UID_FAILURE: "UID_FAILURE",
  RESET_ALL_REPORT_COURSE_STAFF_STATUS: "RESET_ALL_REPORT_COURSE_STAFF_STATUS",
  RESET_ALL_REPORT_QUIZ_STAFF_STATUS: "RESET_ALL_REPORT_QUIZ_STAFF_STATUS",
  RESET_ALL_REPORT_EVALUATION_STATUS: "RESET_ALL_REPORT_EVALUATION_STATUS",
  RESET_ALL_REPORT_EVALUATION_DETAIL_STATUS:
    "RESET_ALL_REPORT_EVALUATION_DETAIL_STATUS",
  GET_ALL_STAFF_EXAMS_SUCCESS: "GET_ALL_STAFF_EXAMS_SUCCESS",
  RESET_ALL_STAFF_EXAMS_STATUS: "RESET_ALL_STAFF_EXAMS_STATUS",
  GET_CHAPTER_SUCCESS: "GET_CHAPTER_SUCCESS",

  GET_ALL_REPORT_COURSE_STAFF_DETAIL_SUCCESS:
    "GET_ALL_REPORT_COURSE_STAFF_DETAIL_SUCCESS",
  GET_ALL_REPORT_COURSE_STAFF_DETAIL_FAILURE:
    "GET_ALL_REPORT_COURSE_STAFF_DETAIL_FAILURE",
  RESET_ALL_REPORT_COURSE_STAFF_DETAIL_STATUS:
    "RESET_ALL_REPORT_COURSE_STAFF_DETAIL_STATUS",
  GET_ALL_REPORT_LESSON_BY_COURSE_SUCCESS:
    "GET_ALL_REPORT_LESSON_BY_COURSE_SUCCESS",
  RESET_ALL_REPORT_LESSON_BY_COURSE_STATUS:
    "RESET_ALL_REPORT_LESSON_BY_COURSE_STATUS",
  GET_ALL_REPORT_LESSON_BY_COURSE_FAILURE:
    "GET_ALL_REPORT_LESSON_BY_COURSE_FAILURE",
  GET_ALL_HISTORY_QUIZ_SUCCESS: "GET_ALL_HISTORY_QUIZ_SUCCESS",
  GET_ALL_HISTORY_QUIZ_FAILURE: "GET_ALL_HISTORY_QUIZ_FAILURE",
  RESET_ALL_HISTORY_QUIZ_STATUS: "RESET_ALL_HISTORY_QUIZ_STATUS",

  GET_OVERVIEW_REPORT_QUIZS_SUCCESS: "GET_OVERVIEW_REPORT_QUIZS_SUCCESS",
  GET_OVERVIEW_REPORT_QUIZS_FAILURE: "GET_OVERVIEW_REPORT_QUIZS_FAILURE",
  RESET_OVERVIEW_REPORT_QUIZS_STATUS: "RESET_OVERVIEW_REPORT_QUIZS_STATUS",

  GET_COURSE_DEPARTMENT_SUCCESS: "GET_COURSE_DEPARTMENT_SUCCESS",
  GET_COURSE_DEPARTMENT_FAILURE: "GET_COURSE_DEPARTMENT_FAILURE",
  GET_ALL_COURSE_DEPARTMENT_SUCCESS: "GET_ALL_COURSE_DEPARTMENT_SUCCESS",
  GET_ALL_COURSE_DEPARTMENT_FAILURE: "GET_ALL_COURSE_DEPARTMENT_FAILURE",
  RESET_COURSE_DEPARTMENT_LIST_STATUS: "RESET_COURSE_DEPARTMENT_LIST_STATUS",
  RESET_COURSE_DEPARTMENT_STATUS: "RESET_COURSE_DEPARTMENT_STATUS",
  GET_ALL_MANAGERS_DEPARTMENT_SUCCESS: "GET_ALL_MANAGERS_DEPARTMENT_SUCCESS",
  GET_ALL_MANAGERS_DEPARTMENT_FAILURE: "GET_ALL_MANAGERS_DEPARTMENT_FAILURE",
  RESET_ALL_MANAGERS_DEPARTMENT_STATUS: "RESET_ALL_MANAGERS_DEPARTMENT_STATUS",

  GET_ALL_FEEDBACK_FAILURE: "GET_ALL_FEEDBACK_FAILURE",
  RESET_FEEDBACK_LIST_STATUS: "RESET_FEEDBACK_LIST_STATUS",
  GET_ALL_FEEDBACK_SUCCESS: "GET_ALL_FEEDBACK_SUCCESS",
  GET_PROFILE_FAILURE: "GET_PROFILE_FAILURE",

  GET_TOTAL_OVERVIEW_COURSE_SUCCESS: "GET_TOTAL_OVERVIEW_COURSE_SUCCESS",
  GET_TOTAL_OVERVIEW_COURSE_FAILURE: "GET_TOTAL_OVERVIEW_COURSE_FAILURE",
  GET_TOTAL_OVERVIEW_TERM_QUIZ_SUCCESS: "GET_TOTAL_OVERVIEW_TERM_QUIZ_SUCCESS",
  GET_TOTAL_OVERVIEW_TERM_QUIZ_FAILURE: "GET_TOTAL_OVERVIEW_TERM_QUIZ_FAILURE",

  GET_TOTAL_OVERVIEW_EVALUATE_SUCCESS: "GET_TOTAL_OVERVIEW_EVALUATE_SUCCESS",
  GET_TOTAL_OVERVIEW_EVALUATE_FAILURE: "GET_TOTAL_OVERVIEW_EVALUATE_FAILURE",
  GET_ALL_REPORT_QUIZS_BY_COURSE_SUCCESS:
    "GET_ALL_REPORT_QUIZS_BY_COURSE_SUCCESS",
  GET_ALL_REPORT_QUIZS_BY_COURSE_FAILURE:
    "GET_ALL_REPORT_QUIZS_BY_COURSE_FAILURE",

  // report number

  GET_REPORT_NUMBER: "GET_REPORT_NUMBER",
  GET_REPORT_NUMBER_FAILURE: "GET_REPORT_NUMBER_FAILURE",
  GET_REPORT_NUMBER_SUCCESS: "GET_REPORT_NUMBER_SUCCESS",

  // area
  GET_ALL_AREA_SUCCESS: "GET_ALL_AREA_SUCCESS",
  CHANGE_AREA_ID: "CHANGE_AREA_ID",

  DAY: "DAY",
  MONTH: "MONTH",
  QUARTER: "QUARTER",
  YEAR: "YEAR",
  WEEK: "WEEK",
  CUSTOM_DATE: "CUSTOM_DATE",
  MULTI_DATE: "MULTI_DATE",
  FORMAT_DD_MM_YYYY: "DD-MM-YYYY",
  FORMAT_DD_MM: "DD/MM",
  FORMAT_YYYY_MM_DD: "YYYY-MM-DD",
  FORMAT_YYYY_MM_DD_HH_MM_SS: "YYYY-MM-DD HH:mm:ss",
  FORMAT_DD_MM_YYYY_HH_MM_SS: "DD-MM-YYYY HH:mm:ss",
  FORMAT_DD_MM_YYYY_HH_MM: "DD-MM-YYYY HH:mm",

  AREA_ALL_ID: "999999999999999999",

  RESET_ALL_DETAIL_ANSWER_STATUS: "RESET_ALL_DETAIL_ANSWER_STATUS",
  GET_ALL_DETAIL_ANSWER_SUCCESS: "GET_ALL_DETAIL_ANSWER_SUCCESS",

  TYPE_BONUS: "bonus", // chấm hộ
  TYPE_OVERTIME: "overtime", // thêm giờ
  TYPE_ANNUAL_LEAVE: "annual_leave", // nghỉ phép năm
  TYPE_UNPAID_LEAVE: "unpaid_leave", // nghỉ ko lương
  TYPE_LEAVE: "leave", // nghỉ phép
  TYPE_COMPASSIONATE_LEAVE: "compassionate_leave", // đám tang
  TYPE_MARRIAGE_LEAVE: "marriage_leave", // kết hôn
  TYPE_BUSINESS_TRIP: "business_trip", // công tác
  TYPE_ADVANCE_SALARY: "advance_salary", // ứng lương

  TYPE_PROPOSE_REWARD: "reward", // đề xuất khen thưởng
  TYPE_SUPPORT: "support", // đề xuất khen thưởng
  TYPE_PROPOSE_ADJUST_SALARY: "adjust_salary", // đề xuất điều chỉnh lương
  TYPE_PROPOSE_ADVANCEMENT: "advancement", // đề xuất thăng tiến
  TYPE_PROPOSE_DISCIPLINE: "discipline", // đề xuất kỉ luật
  TYPE_PROPOSE_TRANSFER: "transfer", // đề xuất điều chuyển
  TYPE_PROPOSE_RESIGNATION: "resignation", // đơn thôi việc
  TYPE_PROPOSE_RECRUITMENT: "recruitment", // đề xuất tuyển dụng
  TYPE_PROPOSE_UNIFORM: "uniform", // đề xuất đồng phục
  // PROCEDURE CONFIG OPERATE
  TYPE_PROPOSE_REPAIR: "repair", // đề xuất sửa chữa
  TYPE_PROPOSE_HR_SUPPORT: "hr_support", // đề xuất hỗ trợ nhân sự
  TYPE_PROPOSE_BUY_EQUIPMENT: "buy_equipment", // đề xuất mua trang thiết bị
  TYPE_PROPOSE_CANCEL_ORDER: "cancel_order", // đề xuất hủy hàng
  TYPE_PROPOSE_TRANSPORTATION_STUFF: "transportation_stuff", // đề xuất điều chuyển hàng hóa
  TYPE_PROPOSE_PAYMENT: "payment", // đề xuất thanh toán
  TYPE_REQUEST_ADVANCE: "request_advance", // đề xuất tạm ứng

  TYPE_REPORT_STAFF_DAY: "report_staff_day", // Báo cáo ngày
  TYPE_REPORT_IN_DAY: "report_in_day", // Báo cáo ngày QLNH
  TYPE_REPORT_REVENUE: "report_revenue", // Báo cáo doanh thu
  TYPE_REPORT_BUDGET: "report_budget", // Báo cáo thu chi
  TYPE_REPORT_INVENTORY: "report_inventory", // Báo cáo tồn kho
  TYPE_REPORT_INVOICE: "report_invoice", // Hoá đơn giao nhận

  /////////////// POPUP NOTI

  MODAL_NOTI_HIDE: "MODAL_NOTI_HIDE",
  MODAL_NOTI_SHOW: "MODAL_NOTI_SHOW",

  ////////////// TYPE NOTI

  HAS_SALARY: "has_salary",
  NEW_SHIFTS: "new_shift",
  INCIDENT_1: "incident_1",
  INCIDENT_2: "incident_2",
  HAS_SHIFT: "has_shift",
  NONE_NOTI: "NONE_NOTI",
  TYPE_REPORT_FORM: "report_form",
  TYPE_OPERATE_FORM: "operate_form",
  TYPE_PERSONNEL_FORM: "personnel_form",

  ////////////// TYPE CHART

  NEW_STAFF: "NEW_STAFF",
  PROBATION: "PROBATION",
  LEAVE_STAFF: "LEAVE",
  WORKING_HOURS: "WORKING_HOURS",
  WORKING_MONEY: "WORKING_MONEY",
  OVERTIME: "OVERTIME",
  REWARD: "REWARD",
  ADJUST_SALARY: "ADJUST_SALARY",
  ADVANCEMENT: "ADVANCEMENT",
  DISCIPLINE: "DISCIPLINE",

  PAYMENT: "PAYMENT",
  REPAIR: "REPAIR",
  SUPPORT: "SUPPORT",
  BUY_EQUIPMENT: "BUY_EQUIPMENT",
  CANCEL_ORDER: "CANCEL_ORDER",
  TYPE_MONEY_CANCEL_ORDER: "TYPE_MONEY_CANCEL_ORDER",
  TYPE_RATE_CANCEL_ORDER: "TYPE_RATE_CANCEL_ORDER",
  TRANFER: "TRANFER",

  REVENUE: "REVENUE",
  CASH: "CASH",
  MONEY_TRANSFER: "MONEY_TRANSFER",
  MONEY_SWIPE: "MONEY_SWIPE",
  SHOPEE: "SHOPEE",
  GRAB: "GRAB",
  BEAMIN: "BEAMIN",
  BEFOOD: "BEFOOD",
  ORTHER: "ORTHER",
  BUDGET: "BUDGET",
  INCIDENT: "INCIDENT",
  INCIDENT_INFORMATION: "INCIDENT_INFORMATION",

  // CHAT
  RESET_ROOM_CHAT_STATUS: "RESET_ROOM_CHAT_STATUS",
  GET_ROOM_CHAT_SUCCESS: "GET_ROOM_CHAT_SUCCESS",
  TOGGLE_BOX_CHAT: "TOGGLE_BOX_CHAT",
  GET_ROOM_CHAT_FAILURE: "GET_ROOM_CHAT_FAILURE",
  SELECTED_ROOM_CHAT: "SELECTED_ROOM_CHAT",
  RECEIVE_MESSAGE: "RECEIVE_MESSAGE",
  CREATE_MESSAGE: "CREATE_MESSAGE",
  LOADING_FILE: "LOADING_FILE",
  CHANGE_ROOM_CHAT: "CHANGE_ROOM_CHAT",
  SELECTED_REPLY_MESSAGE: "SELECTED_REPLY_MESSAGE",
  UPDATE_MESSAGE: "UPDATE_MESSAGE",
  ADD_PIN_MESSAGE: "ADD_PIN_MESSAGE",
  GET_MESSAGE_LIST: "GET_MESSAGE_LIST",
  TOGGLE_MODAL_SELECTS_USER: "TOGGLE_MODAL_SELECTS_USER",
  SEARCH_MEMBERS: "SEARCH_MEMBERS",
  REDIRECT_MESSAGE: "REDIRECT_MESSAGE",
  GET_FILE_LIST: "GET_FILE_LIST",
  UPDATE_ROOM_CHAT: "UPDATE_ROOM_CHAT",
  UPDATE_MEMBER_ROOM_CHAT: "UPDATE_MEMBER_ROOM_CHAT",
  CREATE_ROOM_CHAT: "CREATE_ROOM_CHAT",
  DELETE_ROOM_CHAT: "DELETE_ROOM_CHAT",
  SHOW_LOADING_MODAL: "SHOW_LOADING_MODAL",
  GET_ONE_ROOM_CHAT: "GET_ONE_ROOM_CHAT",
  UPDATE_MESS_ROOM_CHAT: "UPDATE_MESS_ROOM_CHAT",
  TOGGLE_FULLSCREEN: "TOGGLE_FULLSCREEN",
  GET_ROOM_CHAT_BY_USER_ID: "GET_ROOM_CHAT_BY_USER_ID",
  SAVE_USER_ID: "SAVE_USER_ID",
  TOGGLE_HIDE_MAIN_CONTENT: "TOGGLE_HIDE_MAIN_CONTENT",
  TOGGLE_MODAL_USER_INFO: "TOGGLE_MODAL_USER_INFO",

  // CHATAD
  RESET_ROOM_CHAT_STATUS_AD: "RESET_ROOM_CHAT_STATUS_AD",
  GET_ROOM_CHAT_SUCCESS_AD: "GET_ROOM_CHAT_SUCCESS_AD",
  GET_ROOM_CHAT_FAILURE_AD: "GET_ROOM_CHAT_FAILURE_AD",
  SELECTED_ROOM_CHAT_AD: "SELECTED_ROOM_CHAT_AD",
  RECEIVE_MESSAGE_AD: "RECEIVE_MESSAGE_AD",
  CREATE_MESSAGE_AD: "CREATE_MESSAGE_AD",
  CHANGE_ROOM_CHAT_AD: "CHANGE_ROOM_CHAT_AD",
  SELECTED_REPLY_MESSAGE_AD: "SELECTED_REPLY_MESSAGE_AD",
  UPDATE_MESSAGE_AD: "UPDATE_MESSAGE_AD",
  ADD_PIN_MESSAGE_AD: "ADD_PIN_MESSAGE_AD",
  GET_MESSAGE_LIST_AD: "GET_MESSAGE_LIST_AD",
  TOGGLE_MODAL_SELECTS_USER_AD: "TOGGLE_MODAL_SELECTS_USER_AD",
  SEARCH_MEMBERS_AD: "SEARCH_MEMBERS_AD",
  REDIRECT_MESSAGE_AD: "REDIRECT_MESSAGE_AD",
  GET_FILE_LIST_AD: "GET_FILE_LIST_AD",
  UPDATE_ROOM_CHAT_AD: "UPDATE_ROOM_CHAT_AD",
  UPDATE_MEMBER_ROOM_CHAT_AD: "UPDATE_MEMBER_ROOM_CHAT_AD",
  CREATE_ROOM_CHAT_AD: "CREATE_ROOM_CHAT_AD",
  DELETE_ROOM_CHAT_AD: "DELETE_ROOM_CHAT_AD",
  SHOW_LOADING_MODAL_AD: "SHOW_LOADING_MODAL_AD",
  GET_ONE_ROOM_CHAT_AD: "GET_ONE_ROOM_CHAT_AD",
  TOGGLE_FULLSCREEN_AD: "TOGGLE_FULLSCREEN_AD",
  GET_ROOM_CHAT_BY_USER_ID_AD: "GET_ROOM_CHAT_BY_USER_ID_AD",
  SAVE_USER_ID_AD: "SAVE_USER_ID_AD",
  TOGGLE_HIDE_MAIN_CONTENT_AD: "TOGGLE_HIDE_MAIN_CONTENT_AD",
  TOGGLE_MODAL_USER_INFO_AD: "TOGGLE_MODAL_USER_INFO_AD",
  SHOW_LOADING_DRAWER: "SHOW_LOADING_DRAWER",
};
