import React, { useState } from "react";
import { useSelector } from "react-redux";
import { handleShowRoomNameAvatar } from "../../utils";
import { reportNumber } from "../../../../reducers/reportNumber";

const RoomChatItem = ({ room, wasInRoom = false }) => {
  const profile = useSelector((state) => state.user.profile);
  const [isChecked, setChecked] = useState(wasInRoom);
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const handleSelectRoom = () => {
    if (!wasInRoom) {
      setChecked(!isChecked);
    }
  };

  return (
    <div
      className="d-flex align-items-center room-chat-item-container"
      style={{
        gap: "8px",
        padding: "4px 8px",
        backgroundColor: isChecked ? "#f5f5f5" : "",
      }}
      onClick={handleSelectRoom}
    >
      <input type="radio" checked={isChecked} />
      <img
        src={
          handleShowRoomNameAvatar(room, profile).avatar ||
          "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg"
        }
        alt="avatar"
        style={{
          width: "32px",
          height: "32px",
          borderRadius: "50%",
          objectFit: "cover",
        }}
      />
      <div className="d-flex flex-column justify-content-start align-items-start">
        <div className="d-flex justify-content-center align-items-center">
          <p className="text-truncate-1">
            {handleShowRoomNameAvatar(room, profile).name || "Khách"}
          </p>
          {room.is_group == false && (
            <span style={{ fontSize: "11px", marginLeft: "3px" }}>
              {" - "}
              {handleShowRoomNameAvatar(room, profile).position || ""}
            </span>
          )}
          {room.position && (
            <span style={{ fontSize: "11px" }}> - {room.position || ""}</span>
          )}
        </div>
        {room?.company_id != reportNumber.current_user.company_id && (
          <span style={{ fontSize: "11px", color: "blue" }}>{`${
            room.company?.name ?? ""
          }`}</span>
        )}
      </div>
    </div>
  );
};

export default React.memo(RoomChatItem);
