import React, { useState, useEffect } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import ViewDate from "../../../components/ViewDate";
import { shiftServices } from "../../../services/shiftServices";
import moment from "moment";
import { constants } from "../../../constants";
import { convertNumberToDateStr } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { BiFirstPage, BiExpandHorizontal } from "react-icons/bi";
import InfiniteScroll from "react-infinite-scroll-component";
import { CiZoomIn, CiCirclePlus, CiCircleMore } from "react-icons/ci";
import ShiftModal from "./child/ShiftModal";
import HistoryCheckInOut from "./child/HistoryCheckInOutModal";
import HistoryCheckInOutModal from "./child/HistoryCheckInOutModal";
import ChooseShiftModal from "../../Procedure/OperateFormPage/child/ChooseShiftModal";
import { toast } from "react-toastify";
import { Spin } from "antd";

const TimekeepingTable = () => {
  const [timekeepingTable, setTimekeepingTable] = useState([]);
  const [dateSearch, setDateSearch] = useState(null);
  const areaId = useSelector((state) => state.area.areaId);
  const [hasMore, setHasmore] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(areaId);
    setPage(1);
  }, [dateSearch, areaId]);

  useEffect(() => {
    console.log(page);
    if (page == 1) {
      fetchCalendarShiftTable();
    }
  }, [page, areaId]);

  const getFirstAndLastDayInMonth = () => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return { firstDay, lastDay };
  };

  const fetchCalendarShiftTable = async (isFunctionLoad) => {
    if (isFunctionLoad === false) {
      setLoading(true);
    }
    let date_from = moment(
      dateSearch?.startDate ?? getFirstAndLastDayInMonth().firstDay
    ).format(constants.FORMAT_YYYY_MM_DD);
    let date_to = moment(
      dateSearch?.endDate ?? getFirstAndLastDayInMonth().lastDay
    ).format(constants.FORMAT_YYYY_MM_DD);

    const queryString = `?date_from=${date_from}&date_to=${date_to}&page=${page}`;

    try {
      const response = await shiftServices.fetchCalendarShiftTable(
        areaId,
        queryString
      );

      if (response.code != 200) {
        return;
      }
      const check = response.data.total - response.data.current_page * 20;

      if (check <= 0) {
        setHasmore(false);
      } else {
        setPage(page + 1);
        setHasmore(true);
      }

      if (isFunctionLoad === true) {
        setTimekeepingTable((data) => [...data, ...response.data.data]);
      } else {
        setTimekeepingTable(response.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const getDayNameFromDate = (date) => {
    const days = new Map([
      ["Monday", "T2"],
      ["Tuesday", "T3"],
      ["Wednesday", "T4"],
      ["Thursday", "T5"],
      ["Friday", "T6"],
      ["Saturday", "T7"],
      ["Sunday", "CN"],
    ]);
    return days.get(moment(date).format("dddd"));
  };

  const Item = (color, borderColor, title) => {
    return (
      <div
        className="d-flex"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            borderRadius: "50%",
            border: `1px solid ${borderColor}`,
            width: "10px",
            height: "10px",
            backgroundColor: `${color}`,
            marginRight: "5px",
            marginLeft: "10px",
          }}
        ></div>
        <div style={{ fontSize: "13px" }}>{title}</div>
      </div>
    );
  };
  function convertNumberToDateStr(start, end) {
    if (
      start !== null &&
      start !== undefined &&
      end !== null &&
      end !== undefined
    ) {
      return `${start.toString().padStart(2, "0")}:${end
        .toString()
        .padStart(2, "0")}`;
    }

    return "";
  }
  const checkTime = (historyTimeKeeping) => {
    if ((historyTimeKeeping.histories ?? []).length < 2) {
      if ((historyTimeKeeping.histories ?? []).length == 0) {
        const date = historyTimeKeeping.date;
        const startWorkHour = historyTimeKeeping?.start_work_hour ?? 0;
        const startWorkMinute = historyTimeKeeping?.start_work_minute ?? 0;

        const startWorkShift = `${moment(date).year()}-${
          moment(date).month() + 1 < 10 ? "0" : ""
        }${moment(date).month() + 1}-${
          moment(date).date() < 10 ? "0" : ""
        }${moment(date).date()} ${
          startWorkHour < 10 ? "0" : ""
        }${startWorkHour}:${
          startWorkMinute < 10 ? "0" : ""
        }${startWorkMinute}:00`;

        if (moment(startWorkShift ?? moment.now()).isAfter(moment.now())) {
          return "NOT_TIME_YET";
        } else {
          return "NOT_ALL_CHECK_IN_OUT";
        }
      }

      return "NOT_CHECK_IN_OUT";
    } else {
      var firstHis = historyTimeKeeping.histories[0];
      var lastHis =
        historyTimeKeeping.histories[historyTimeKeeping.histories.length - 1];
      if (
        historyTimeKeeping.histories.length > 1 &&
        firstHis.is_checkin == false
      ) {
        var timeTracking = firstHis.time_tracking ?? moment.now();

        const endWorkHour = historyTimeKeeping?.end_work_hour ?? 0;
        const endWorkMinute = historyTimeKeeping?.end_work_minute ?? 0;

        const endOfWorkShift = `${moment(timeTracking).year()}-${
          moment(timeTracking).month() + 1 < 10 ? "0" : ""
        }${moment(timeTracking).month() + 1}-${
          moment(timeTracking).date() < 10 ? "0" : ""
        }${moment(timeTracking).date()} ${
          endWorkHour < 10 ? "0" : ""
        }${endWorkHour}:${endWorkMinute < 10 ? "0" : ""}${endWorkMinute}:00`;

        if (moment(timeTracking).isBefore(moment(endOfWorkShift))) {
          return "COME_BACK_SOON";
        }
      }

      if (lastHis.id == null) {
        return "NOT_WORK";
      }

      var timeTracking = firstHis.time_tracking ?? moment.now();

      const startWorkHour = historyTimeKeeping?.start_work_hour ?? 0;
      const startWorkMinute = historyTimeKeeping?.start_work_minute ?? 0;

      const startWorkShift = `${moment(timeTracking).year()}-${
        moment(timeTracking).month() + 1 < 10 ? "0" : ""
      }${moment(timeTracking).month() + 1}-${
        moment(timeTracking).date() < 10 ? "0" : ""
      }${moment(timeTracking).date()} ${
        startWorkHour < 10 ? "0" : ""
      }${startWorkHour}:${
        startWorkMinute < 10 ? "0" : ""
      }${startWorkMinute}:00`;

      if (moment(timeTracking).isAfter(moment(startWorkShift))) {
        return "LATE";
      }
    }

    return "ON_TIME";
  };

  const getColor = (type) => {
    return type == "COME_BACK_SOON"
      ? "rgb(255 200 136 / 20%)"
      : type == "NOT_WORK"
      ? "grey"
      : type == "NOT_TIME_YET"
      ? "rgba(242, 242, 242, 0.81)"
      : type == "NOT_CHECK_IN_OUT"
      ? "rgb(255 14 57 / 20%)"
      : type == "LATE"
      ? "rgb(255 200 136 / 20%)"
      : type == "ON_TIME"
      ? "rgb(30 204 120 / 20%)"
      : type == "NOT_ALL_CHECK_IN_OUT"
      ? "rgb(131 139 163 / 20%)"
      : "red";
  };

  const getColorBorder = (type) => {
    return type == "COME_BACK_SOON"
      ? "rgb(240, 171, 92)"
      : type == "NOT_WORK"
      ? "grey"
      : type == "NOT_TIME_YET"
      ? "rgb(172, 178, 193)"
      : type == "NOT_CHECK_IN_OUT"
      ? "rgb(255, 14, 57)"
      : type == "LATE"
      ? "rgb(240, 171, 92)"
      : type == "ON_TIME"
      ? "rgb(30, 204, 120)"
      : type == "NOT_ALL_CHECK_IN_OUT"
      ? "rgb(131 139 163 / 20%)"
      : "red";
  };

  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
    shifts: [],
  });

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const addStaffToShift = async (shift) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const body = {
        staff_id: modal.staff.id,
        shift_id: shift.id,
        date: modal.dateInput,
      };
      const response = await shiftServices.addStaffToShift(areaId, body);
      if (response.code === 200) {
        console.log("done");
        var list = timekeepingTable;
        if (modal.index != null && modal.indexDate != null) {
          var list = timekeepingTable;
          list[modal.index].timekeeping[modal.indexDate].shifts = response.data;
          console.log(list);
          setTimekeepingTable(list);
          setModal({ ...modal, title: "", method: "", visible: true });
          setModal({ ...modal, title: "", method: "", visible: false });
        }
      } else {
        toast.success(response.msg);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const updateTable = async (shiftData) => {
    for (const shift of shiftData ?? []) {
      console.log(shift);
      if (shift?.is_input !== true) {
        await addStaffToShift(shift);
      }
    }
  };

  return (
    <div>
      <div
        style={{
          flex: 1,
          paddingLeft: "20px",
          paddingRight: "5px",
          paddingBottom: "5px",
        }}
      >
        <div className="d-flex">
          <ViewDate
            className="d-flex justify-content-between"
            type={constants.MONTH}
            handleChange={(date) => {
              setPage(1);
              setDateSearch(date);
            }}
          />
          <div style={{ width: "50px" }}></div>
          {Item(
            "rgb(131 139 163 / 20%)",
            "rgb(131, 139, 163)",
            "Không chấm công"
          )}
          {Item("rgb(172 178 193 / 20%)", "rgb(172, 178, 193)", "Chưa đến ca")}
          {Item("rgb(30 204 120 / 20%)", "rgb(30, 204, 120)", "Đúng giờ")}
          {Item(
            "rgb(255 200 136 / 20%)",
            "rgb(255, 200, 136)",
            "Vào trễ, ra sớm"
          )}
          {Item("rgb(255 14 57 / 20%)", "rgb(255, 14, 57)", "Chưa vào/ra ca")}
          {Item(
            "rgb(188 136 255 / 20%)",
            "rgb(188, 136, 255)",
            "Nghỉ phép có lương"
          )}
          {Item(
            "rgb(192 127 118 / 20%)",
            "rgb(192, 127, 118)",
            "Nghỉ phép không lương"
          )}
          {Item(
            "rgb(87 242 214 / 20%)",
            "rgb(87, 242, 214)",
            "Công tác/ra ngoài"
          )}
        </div>
      </div>
      <div
        id="scrollableDiv"
        style={{
          height: `${(window.innerHeight * 85) / 100}px`,
          overflow: "auto",
        }}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              height: `${(window.innerHeight * 85) / 100}px`,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin></Spin>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={timekeepingTable.length}
            next={() => {
              fetchCalendarShiftTable(true);
            }}
            hasMore={hasMore}
            loader={
              <div
                style={{
                  textAlign: "center",
                  color: "red",
                  fontSize: "18px",
                }}
              >
                <Spin></Spin>
              </div>
            }
            scrollableTarget="scrollableDiv"
          >
            <div className="card-body">
              <table class="table table-work-schedule">
                <tbody>
                  <tr
                    style={{
                      position: "sticky",
                      left: 0,
                      backgroundColor: "white",
                      zIndex: 100,
                    }}
                  >
                    <th
                      scope="row"
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 999,
                        background: "white",
                        border: "1px solid #d9d7ce",
                      }}
                    >
                      Tìm kiếm
                    </th>
                    {timekeepingTable?.map((table, index) => {
                      return (
                        <td
                          key={index}
                          style={{
                            height: "70px",
                            minHeight: "unset",
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "13px",
                            alignItems: "center",
                          }}
                        >
                          {table.name}
                        </td>
                      );
                    })}
                  </tr>
                  {timekeepingTable[0]?.timekeeping?.length > 0 ? (
                    timekeepingTable[0]?.timekeeping?.map((t, indexDate) => {
                      return (
                        <tr key={indexDate} scope="row">
                          <th
                            scope="row"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minWidth: "120px",
                              maxWidth: "150px",
                              position: "sticky",
                              top: 0,
                              zIndex: 999,
                              background: "white",
                              border: "1px solid #d9d7ce",
                            }}
                          >
                            {getDayNameFromDate(t.date)}, {}
                            {moment(t.date).format(constants.FORMAT_DD_MM)}
                          </th>
                          {timekeepingTable.map((row, index) => {
                            return (
                              <td
                                key={index}
                                style={{
                                  height: "70px",
                                  minHeight: "unset",
                                  padding: "2px",
                                  minWidth: "120px",
                                  maxWidth: "150px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {(row.timekeeping[indexDate]?.shifts ?? [])
                                  .length === 0 ? (
                                  <div
                                    style={{
                                      width: "50%",
                                      height: "60%",
                                      boxSizing: "border-box",
                                      borderRadius: "15px",
                                      padding: "5px",
                                      fontWeight: "700",
                                      border: "1px dashed grey",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      color: "grey",
                                    }}
                                    onClick={(e) => {
                                      setModal({
                                        title: `Chọn ca`,
                                        method: "ADD_SHIFT",
                                        dateInput: t.date,
                                        visible: true,
                                        staff: timekeepingTable[index],
                                        index: index,
                                        indexDate: indexDate,
                                        shifts: (
                                          row.timekeeping[indexDate].shifts ??
                                          []
                                        ).map((e) => {
                                          return {
                                            ...e,
                                            is_input: true,
                                          };
                                        }),
                                      });
                                    }}
                                  >
                                    +
                                  </div>
                                ) : (row.timekeeping[indexDate]?.shifts ?? [])
                                    .length > 1 ? (
                                  <div className="container">
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        boxSizing: "border-box",
                                        border: `1px solid #76beff`,
                                        backgroundColor: "#ddefff",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        fontWeight: "700",
                                        fontSize: "13px",
                                        transition:
                                          "background-color 0.3s ease",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                      onClick={(e) => {
                                        setModal({
                                          title: "",
                                          method: "SHOW_ALL",
                                          visible: true,
                                          dateInput: t.date,
                                          staff: timekeepingTable[index],
                                          shifts:
                                            row.timekeeping[indexDate]
                                              ?.shifts ?? [],
                                        });
                                      }}
                                    >
                                      <div>
                                        {`${
                                          (
                                            row.timekeeping[indexDate]
                                              ?.shifts ?? []
                                          ).length
                                        } Ca`}
                                      </div>
                                      <div className="d-flex">
                                        {(
                                          row.timekeeping[indexDate]?.shifts ??
                                          []
                                        ).map((e) => {
                                          return (
                                            <div
                                              style={{
                                                height: "5px",
                                                width: "5px",
                                                marginRight: "2px",
                                                marginLeft: "2px",
                                                borderRadius: "50%",
                                                backgroundColor: getColorBorder(
                                                  checkTime(e)
                                                ),
                                              }}
                                            ></div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="container">
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        boxSizing: "border-box",
                                        border: `1px solid ${getColorBorder(
                                          checkTime(
                                            row.timekeeping[indexDate].shifts[0]
                                          )
                                        )}`,
                                        backgroundColor: getColor(
                                          checkTime(
                                            row.timekeeping[indexDate].shifts[0]
                                          )
                                        ),
                                        borderRadius: "5px",
                                        padding: "5px",
                                        fontWeight: "700",
                                        fontSize: "13px",
                                        transition:
                                          "background-color 0.3s ease",
                                      }}
                                    >
                                      <div>
                                        <div style={{ fontSize: "11px" }}>
                                          {row.timekeeping[indexDate].shifts[0]
                                            ?.name ?? ""}
                                        </div>
                                        <div style={{ fontSize: "11px" }}>
                                          {"("}
                                          {convertNumberToDateStr(
                                            row.timekeeping[indexDate].shifts[0]
                                              ?.start_work_hour,
                                            row.timekeeping[indexDate].shifts[0]
                                              ?.start_work_minute
                                          )}
                                          -
                                          {convertNumberToDateStr(
                                            row.timekeeping[indexDate].shifts[0]
                                              ?.end_work_hour,
                                            row.timekeeping[indexDate].shifts[0]
                                              ?.end_work_minute
                                          )}
                                          {")"}
                                        </div>
                                        {(
                                          row.timekeeping[indexDate].shifts[0]
                                            ?.histories ?? []
                                        ).length === 0 ? (
                                          <></>
                                        ) : (
                                          <div
                                            style={{
                                              fontSize: "11px",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {moment(
                                              new Date(
                                                row.timekeeping[
                                                  indexDate
                                                ].shifts[0]?.histories[0].time_tracking
                                              )
                                            ).format("HH:mm:ss")}
                                            {" - "}
                                            {row.timekeeping[indexDate]
                                              .shifts[0]?.histories.length >=
                                              2 &&
                                            row.timekeeping[indexDate].shifts[0]
                                              ?.histories[
                                              row.timekeeping[indexDate]
                                                .shifts[0]?.histories.length - 1
                                            ].is_checkin === false
                                              ? moment(
                                                  new Date(
                                                    row.timekeeping[
                                                      indexDate
                                                    ].shifts[0]?.histories[
                                                      row.timekeeping[indexDate]
                                                        .shifts[0]?.histories
                                                        .length - 1
                                                    ].time_tracking
                                                  )
                                                ).format("HH:mm:ss")
                                              : "--:--"}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="overlay">
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          width: "100%",
                                          height: "100%",
                                          paddingLeft: "30px",
                                          paddingRight: "25px",
                                        }}
                                      >
                                        <button
                                          type="button"
                                          style={{
                                            backgroundColor:
                                              "rgba(255, 255, 255, 0)",
                                          }}
                                          onClick={(e) => {
                                            setModal({
                                              title: "",
                                              method: "HISTORY",
                                              visible: true,
                                              staff: timekeepingTable[index],
                                              shiftHistory:
                                                row.timekeeping[indexDate]
                                                  .shifts[0],
                                            });
                                          }}
                                        >
                                          <CiZoomIn
                                            size={30}
                                            color={"white"}
                                          ></CiZoomIn>
                                        </button>
                                        <button
                                          type="button"
                                          style={{
                                            backgroundColor:
                                              "rgba(255, 255, 255, 0)",
                                          }}
                                          onClick={(e) => {
                                            setModal({
                                              title: `Chọn ca`,
                                              method: "ADD_SHIFT",
                                              dateInput: t.date,
                                              visible: true,
                                              staff: timekeepingTable[index],
                                              index: index,
                                              indexDate: indexDate,
                                              shifts: (
                                                row.timekeeping[indexDate]
                                                  .shifts ?? []
                                              ).map((e) => {
                                                return {
                                                  ...e,
                                                  is_input: true,
                                                };
                                              }),
                                            });
                                          }}
                                        >
                                          <CiCirclePlus
                                            size={30}
                                            color={"white"}
                                          ></CiCirclePlus>
                                        </button>
                                        <button
                                          type="button"
                                          style={{
                                            backgroundColor:
                                              "rgba(255, 255, 255, 0)",
                                          }}
                                        >
                                          <CiCircleMore
                                            size={30}
                                            color={"white"}
                                          ></CiCircleMore>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
        )}
      </div>
      <ShiftModal
        visible={modal.visible && modal.method === "SHOW_ALL"}
        shiftsInput={modal?.shifts}
        staff={modal?.staff}
        dateInput={modal?.dateInput}
        closeModal={handleCloseModal}
      ></ShiftModal>
      <HistoryCheckInOutModal
        visible={modal.visible && modal.method === "HISTORY"}
        shiftInput={modal?.shiftHistory}
        staffInput={modal?.staff}
        closeModal={handleCloseModal}
      ></HistoryCheckInOutModal>
      <ChooseShiftModal
        visible={modal.visible && modal.method === "ADD_SHIFT"}
        shiftsInput={(modal.shifts ?? []).map((e) => {
          return { ...e, id: e.shift_id ?? e.id };
        })}
        chooseOne={false}
        onSave={(shiftsData) => {
          console.log(shiftsData);
          updateTable(shiftsData);
          handleCloseModal();
        }}
        closeModal={handleCloseModal}
      ></ChooseShiftModal>
    </div>
  );
};

export default TimekeepingTable;
