import { constants as c } from "../constants";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
const companyId =
  localStorage.getItem("companyId") == "undefined" ||
  localStorage.getItem("companyId") == null
    ? 1
    : JSON.parse(localStorage.getItem("companyId"));

function getHistorySystems({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
  };
  return fetch(`${c.API_URL}/system/history_activities${param}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function createIncident({ data }) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };
  return fetch(`${c.API_URL}/incident/staff/form/submit`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function deleteIncidentMany({ data }) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
      'company-id': companyId ?? 1,
    },
    body: JSON.stringify(data),
  };
  return fetch(`${c.API_URL}/incident/manager/form/many`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

export const historySysServices = {
  getHistorySystems,
  createIncident,
  deleteIncidentMany,
};
