/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

import { DatePicker as DatePicker2 } from "rsuite";
import StaffModal from "./StaffModal";
import ShiftChoose from "./ShiftChoose";
import { toast } from "react-toastify";
import { constants } from "../../../../constants";
import moment from "moment";
import { Modal } from "antd";

const gregorian_en_lowercase = {
  name: "gregorian_en_lowercase",
  months: [
    ["Tháng 1", "jan"],
    ["Tháng 2", "feb"],
    ["Tháng 3", "mar"],
    ["Tháng 4", "apr"],
    ["Tháng 5", "may"],
    ["Tháng 6", "jun"],
    ["Tháng 7", "jul"],
    ["Tháng 8", "aug"],
    ["Tháng 9", "sep"],
    ["Tháng 10", "oct"],
    ["Tháng 11", "nov"],
    ["Tháng 12", "dec"],
  ],
  weekDays: [
    ["saturday", "T7"],
    ["sunday", "CN"],
    ["monday", "T2"],
    ["tuesday", "T3"],
    ["wednesday", "T4"],
    ["thursday", "T5"],
    ["friday", "T6"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"],
  ],
};

const JobBoardModal = ({ visible, title, closeModal, onSubmit }) => {
  const [modal, setModal] = useState({
    staff: false,
  });

  const [body, setBody] = useState({
    is_over_time: false,
    requestDate: new Date(),
    checkin_time: new Date(),
    checkout_time: new Date().setMinutes(new Date().getMinutes() + 1),
    reason: "",
    list_staff: [],
    custom_day: [],
    shift: null,
  });

  useEffect(() => {}, [!visible]);

  const handleCloseModal = () => {
    setModal({
      staff: false,
      visible: false,
    });
  };

  const handleSubmitStaffModal = (listEmp) => {
    console.log(listEmp);
    setBody({ ...body, list_staff: listEmp });
    handleCloseModal();
  };

  const handleSubmitForm = (data) => {
    if (!(body?.list_staff ?? []).length > 0) {
      toast.error("Chưa chọn nhân viên");
      return;
    }
    onSubmit(data);
  };

  const handleSubmitShift = (data) => {
    const time = `${data?.date} ${convertNumberToDateStr(
      data?.start_work_hour,
      data?.start_work_minute
    )}`;

    const timeCheckOut = `${data?.date} ${convertNumberToDateStr(
      data?.end_work_hour,
      data?.end_work_minute
    )}`;

    console.log(time);
    console.log(timeCheckOut);

    setBody({
      ...body,
      shift: data,
      checkin_time: new Date(time),
      checkout_time: new Date(timeCheckOut),
    });
    handleCloseModal();
  };

  function convertNumberToDateStr(start, end) {
    if (
      start !== null &&
      start !== undefined &&
      end !== null &&
      end !== undefined
    ) {
      return `${start.toString().padStart(2, "0")}:${end
        .toString()
        .padStart(2, "0")}`;
    }

    return "";
  }

  return (
    <Modal
      title={"Thêm bớt công"}
      centered
      open={visible}
      width={"auto"}
      style={{ top: 20, minWidth: 800, maxWidth: 1000 }}
      onCancel={() => {
        closeModal();
      }}
      onOk={() => {
        onSubmit(body);
      }}
    >
      <div>
        <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
          <div className="d-flex mt-3">
            <div className="form-group d-flex align-item-center">
              <input
                type="radio"
                name="is_over_time"
                checked={(body?.is_over_time ?? false) === false}
                onChange={(e) => {
                  setBody({ ...body, is_over_time: false });
                }}
              />
              <span className="ml-1">Chấm công hộ</span>
            </div>
            <div style={{ width: "30px" }}></div>
            <div className="form-group d-flex align-item-center">
              <input
                type="radio"
                name="is_over_time"
                checked={(body?.is_over_time ?? false) === true}
                onChange={(e) => {
                  setBody({ ...body, is_over_time: true });
                }}
              />
              <span className="ml-1">Thêm giờ làm</span>
            </div>
          </div>
          <div className="form-group my-3">
            <label>
              Chọn nhân viên <span className="text-danger">*</span>
            </label>
            {body?.list_staff?.map((e) => {
              return (
                <div className="d-flex align-items-center mb-2">
                  <span>{e.name}</span>
                  <i
                    class="fa fa-times ml-2 text-danger"
                    aria-hidden="true"
                    onClick={(v) => {
                      setBody({
                        ...body,
                        list_staff: body.list_staff.filter(
                          (x) => x.id !== e.id
                        ),
                      });
                    }}
                  ></i>
                </div>
              );
            })}
            <div
              className="d-flex align-items-center btn-add"
              onClick={() => setModal({ ...modal, staff: true })}
              style={{ paddingRight: "10px", paddingBottom: "10px" }}
            >
              <i
                class="fa fa-plus-circle ml-2 question-group-icon text-success"
                aria-hidden="true"
              ></i>
              <span className="ml-1">
                Thêm nhân viên{" "}
                <span className="font-weight-bold">
                  (Đã chọn: {(body.list_staff ?? []).length} nhân viên)
                </span>
              </span>
            </div>
            {(body?.list_staff ?? []).length === 0 && (
              <small className="text-danger">Vui lòng chọn nhân viên</small>
            )}
          </div>
          <div className="form-group d-flex my-5">
            <label>
              Ngày yêu cầu <span className="text-danger">*</span>
            </label>
            <div style={{ width: "10px" }}></div>
            <DatePicker
              inputClass="custom-input"
              
              locale={gregorian_en_lowercase}
              style={{ 
                width: "100%",
                boxSizing: "border-box",
              }}
              containerStyle={{
                width: "70%",
              }}
              calendarPosition="top-center"
              multiple
              value={body.custom_day}
              plugins={[<DatePanel />]}
              onChange={(e) => {
                console.log(
                  e.map((x) => {
                    return moment(x.toDate()).format("YYYY-MM-DD");
                  })
                );

                setBody({ ...body, custom_day: e });
              }}
            />
          </div>
          {(body?.custom_day ?? []).length === 0 && (
            <small className="text-danger">Vui lòng chọn ngày yêu cầu</small>
          )}
          {(body.list_staff ?? []).length === 1 &&
          (body.custom_day ?? []).length === 1 ? (
            <div
              className="button-handle"
              style={{
                width: "200px",
                textAlign: "center",
                marginBottom: "10px",
              }}
              onClick={(e) => {
                setModal({ ...modal, visible: true });
              }}
            >
              {body.shift?.name ?? "Chọn ca làm việc"}
            </div>
          ) : (
            <></>
          )}
          {(body.custom_day ?? []).length <= 1 &&
          (body.list_staff ?? []).length <= 1 ? (
            <div>
              <div className="form-group">
                <label>
                  Bắt đầu <span className="text-danger">*</span>
                </label>
                <DatePicker2
                  format="HH:mm"
                  className="time-picker"
                  cleanable
                  value={body.checkin_time}
                  onChange={(e) => {
                    setBody({ ...body, checkin_time: e });
                  }}
                />
                {body?.checkin_time === null && (
                  <small className="text-danger">
                    Vui lòng chọn giờ bắt đầu
                  </small>
                )}
              </div>
              <div className="form-group">
                <label>
                  Kết thúc <span className="text-danger">*</span>
                </label>
                <DatePicker2
                  format="HH:mm"
                  className="time-picker"
                  cleanable
                  value={body.checkout_time}
                  onChange={(e) => {
                    setBody({ ...body, checkout_time: e });
                  }}
                />
                {body?.checkout_time === null && (
                  <small className="text-danger">
                    Vui lòng chọn giờ kết thúc
                  </small>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="form-group">
            <label>
              Lý do <span className="text-danger">*</span>
            </label>
            <input
              name="reason"
              type="text"
              className="form-control"
              placeholder="Nhập lý do"
              autoComplete="off"
            />
            {body?.reason === null && (
              <small className="text-danger">Vui lòng nhập lý do</small>
            )}
          </div>
        </div>
      </div>
      <StaffModal
        visible={modal.staff}
        staffIds={(body.list_staff ?? [])}
        closeModal={handleCloseModal}
        onSubmit={handleSubmitStaffModal}
      />
      <ShiftChoose
        visible={modal.visible}
        staffId={(body.list_staff ?? []).map((e) => e.id)[0] ?? 0}
        date={
          body.custom_day[0]
            ? moment(body.custom_day[0]).format("YYYY-MM-DD")
            : ""
        }
        closeModal={handleCloseModal}
        onSubmit={handleSubmitShift}
      />
    </Modal>
  );
};

export default JobBoardModal;
