import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { areaServices } from "../../services/areaServices";
import { FilterFilled } from "@ant-design/icons";
import {
  Empty,
  Pagination,
  Tabs,
  Button,
  Popconfirm,
  Table,
  Space,
  Select,
  DatePicker,
  ConfigProvider,
} from "antd";
import { MdLocationOn } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { BsFillDatabaseFill, BsFillPersonLinesFill } from "react-icons/bs";
import { BiSolidArea } from "react-icons/bi";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { HiUserGroup, HiFolderOpen } from "react-icons/hi";
import { BsStars } from "react-icons/bs";
import { MdDangerous } from "react-icons/md";
import "./style.css";
import { constants } from "../../constants";
import { shiftServices } from "../../services/shiftServices";
import moment from "moment";
import AddHistoryModal from "./child/AddHistoryModal";
import UploadMultiFileModal from "./child/UploadMultiFileModal";
import viVN from "antd/locale/vi_VN";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { reportSysServices } from "../../services/reportSysServices";
import { get } from "jquery";
import PermissionChecker from "../../components/PermissionChecker";
import QcHistoryModal from "./child/QcHistoryModal";
import { formatMoney, formatMoneyVND } from "../../helpers";
import dayjs from "dayjs";
import ChooseReportQcModal from "./child/ChooseReportQcModal";
import ReportQcViewModal from "./child/ReportQcViewModal";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

const processDataForTableQc = (data) => {
  const structuredData = {};
  data?.qc_check_history_list.forEach((history_list) => {
    history_list?.list_standard.forEach((standard) => {
      const key = `${history_list.group_qc_name}_${standard.qc_title}`;

      if (!structuredData[key]) {
        structuredData[key] = {
          groupName: history_list.group_qc_name,
          title: standard.qc_title,
          level: standard.level,
          percent: standard.standard_percent,
          point: standard.percent,
          passed: standard.percent !== 0 ? true : false,
          failed: standard.percent !== 0 ? false : true,
          shiftName: data.shifts_name,
          images: standard.images,
          note: standard.note,
          totalPoints: standard.report_input
        };
      }
    });
  });
  return Object.values(structuredData);
};

export default function AreaDetail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const areaId = useSelector((state) => state.area.areaId);

  const [areaCurrent, setAreaCurrent] = useState({});
  const [staffs, setStaffs] = useState(null);

  const [file, setFile] = React.useState([]);

  const monthPicker = React.useRef();
  const uploaderFile = React.useRef();
  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });

  const [historyReview, setHistoryReview] = useState([]);
  const [forms, setForms] = useState([]);
  const [historyViolate, setHistoryViolate] = useState([]);
  const [qcHistory, setQcHistory] = useState();
  const [avg, setAvg] = useState(0);
  const [dataRevenue, setDataRevenue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [stateFilter, setStateFilter] = useState(false);
  const [indexTab, setIndexTab] = useState(1);

  const getFirstAndLastDayInMonth = () => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return { firstDay, lastDay };
  };

  const getAreaById = async () => {
    try {
      const response = await areaServices.getAreaById(areaId);
      setAreaCurrent(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getHistoryLogArea = async (page, typeLog) => {
    setLoading(true);
    try {
      const response = await areaServices.getHistoryLogArea(
        `?area_id=${areaId}&page=${page}&type=${typeLog}`
      );
      if (typeLog == 0) {
        setHistoryReview(response.data);
      } else {
        setHistoryViolate(response.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const deleteHistoryLogArea = async (id, typeLog) => {
    try {
      const response = await areaServices.deleteHistoryLogArea(id);
      if (typeLog == 0) {
        getHistoryLogArea(1, 0);
      } else {
        getHistoryLogArea(1, 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFormRepairArea = async (page) => {
    setFormLoading(true);
    try {
      const response = await areaServices.getFormRepairArea(
        `?area_id=${areaId}&status=2&page=${page}&type=repair&type_parent=operate`
      );
      setForms(response.data);
    } catch (error) {
      setFormLoading(false);
      console.log(error);
    }
    setFormLoading(false);
  };

  const getQcHistory = async (page) => {
    setLoading(true);
    console.log(new Date(monthPicker.current));
    try {
      const response = await areaServices.getQcHistory(
        `?area_id=${areaId}&page=${page}&month=${
          monthPicker.current == null
            ? ""
            : dayjs(monthPicker.current).format("MM")
        }`
      );
      setQcHistory(response.data);
      setAvg(response.month_avg);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const deleteQcHistory = async (id, typeLog) => {
    try {
      const response = await areaServices.deleteQcHistory(id);
      if (typeLog == 0) {
        getHistoryLogArea(1, 0);
      } else {
        getHistoryLogArea(1, 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAreaById();
    fetchStaffs();
    getHistoryLogArea(1, 0);
    getHistoryLogArea(1, 1);
    getFormRepairArea(1);
    getQcHistory(1);
    getReportRevenue();
  }, [areaId]);

  const fetchStaffs = async () => {
    const queryString = `?department_id=${8}&page=${1}`;
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await shiftServices.fetchStaffsTemp({
        areaId,
        queryString,
      });
      setStaffs(response.data?.data);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const getTypeReview = (type) => {
    switch (type) {
      case 1:
        return "QR tại quán";
      case 2:
        return "Google";
      case 3:
        return "App Food";
      case 4:
        return "Zalo";
      case 5:
        return "Fanpage";
      case 6:
        return "Hotline";
      case 7:
        return "Khác";
      default:
        break;
    }
  };

  const getTypeViolate = (type) => {
    switch (type) {
      case 1:
        return "Vi phạm hành chính";

      default:
        return "Khác";
    }
  };

  function downloadFile(url) {
    const tagA = document.createElement("a");
    tagA.href = url;
    tagA.download = "file";
    tagA.target = "_blank";
    tagA.click();
  }

  const getReportRevenue = async () => {
    var from_time = getFirstAndLastDayInMonth().firstDay;
    var to_time = getFirstAndLastDayInMonth().lastDay;

    try {
      const response = await reportSysServices.getReportRevenue({
        param: `?area_id=${areaId}&from_time=${moment(
          from_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&to_time=${moment(
          to_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&type=day`,
      });

      if (areaId == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );
        setDataRevenue({
          labels,
          datasets: [
            {
              label: `Tổng doanh thu`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.total_revenue ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        setDataRevenue({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.total_revenue ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
    }
  };

  const chartWidget = (title, dataChart, type) => {
    var options = {
      responsive: true,
      scales: {
        xAxes: {
          barPercentage: 1.6,
          grid: {
            display: false,
            zeroLineColor: "transparent",
          },
          ticks: {
            font: {
              size: 11,
            },

            major: {
              enable: false,
            },
          },
        },
      },
      aspectRatio: 2,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: title,
        },
      },
    };
    return (
      <div>
        <Bar
          options={options}
          onClick={() => {
            setModal({
              method: "DEATAIL",
              type: type,
              visible: true,
            });
          }}
          data={dataChart}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "Nguồn đánh giá",
      dataIndex: "type_review",
      width: 200,
      key: "type_review",
      render: (text) => <a>{getTypeReview(text ?? 0)}</a>,
    },
    {
      title: "Người đánh giá",
      key: "staff_action",
      dataIndex: "staff_action",
      width: 300,
      render: (staff) => <a>{staff?.name}</a>,
    },
    {
      title: "Nội dung đánh giá",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Thời gian",
      width: 200,
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Xoá đánh giá ?"
            description="Bạn có chắc chắn muốn xoá đánh giá này chứ ?"
            onConfirm={() => {
              deleteHistoryLogArea(record.key, 0);
            }}
            onCancel={() => {}}
            okText="Có"
            cancelText="Không"
          >
            <a style={{ color: "red" }}>Xoá</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const formColumns = [
    {
      title: "Mức độ sửa chữa",
      dataIndex: "content",
      width: 200,
      key: "content",
      render: (text) => (
        <a>{JSON.parse(text).is_hot == true ? "Gấp" : "Bình thường"}</a>
      ),
    },
    {
      title: "Người yêu cầu",
      width: 300,
      dataIndex: "staff",
      key: "staff",
      render: (staff) => <a>{staff?.name ?? "--:--"}</a>,
    },
    {
      title: "Danh sách nội dung sửa",
      key: "products",
      dataIndex: "products",
      render: (products) => (
        <div className="mx-2">
          {(products ?? []).map((e) => {
            return (
              <div
                className="d-flex "
                style={{
                  fontSize: "13px",
                }}
              >
                <span>Tên nội dung: {e.name}</span>
                <div style={{ width: "10px" }}></div>
                <span>Số lượng: {e.quantity}</span>
                <div style={{ width: "10px" }}></div>
                <span>Ghi chú: {e.note}</span>
              </div>
            );
          })}
        </div>
      ),
    },

    {
      title: "Thời gian",
      width: 200,
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Xoá phiếu ?"
            description="Bạn có chắc chắn muốn xoá phiếu này chứ ?"
            onConfirm={() => {
              deleteHistoryLogArea(record.key, 1);
            }}
            onCancel={() => {}}
            okText="Có"
            cancelText="Không"
          >
            <a style={{ color: "red" }}>Xoá</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const violateColumns = [
    {
      title: "Loại",
      dataIndex: "type_violate",
      width: 200,
      key: "type_violate",
      render: (text) => <a>{getTypeViolate(text ?? 0)}</a>,
    },
    {
      title: "Người thêm vi phạm",
      key: "staff_action",
      dataIndex: "staff_action",
      width: 300,
      render: (staff) => <a>{staff?.name}</a>,
    },
    {
      title: "Nội dung vi phạm",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Thời gian",
      width: 200,
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Xoá vi phạm ?"
            description="Bạn có chắc chắn muốn xoá vi phạm này chứ ?"
            onConfirm={(e) => {
              e.stopPropagation();
              deleteHistoryLogArea(record.key, 1);
            }}
            onCancel={() => {}}
            okText="Có"
            cancelText="Không"
          >
            <a style={{ color: "red" }}>Xoá</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const qcColumns = [
    {
      title: "Bài đánh giá",
      key: "qc_evaluate_main_name",
      dataIndex: "qc_evaluate_main_name",
      width: 300,
      render: (qc_evaluate_main_name) => <a>{qc_evaluate_main_name}</a>,
    },
    {
      title: "Kết quả",
      dataIndex: "result_percent",
      key: "result_percent",
      render: (result_percent) => <p>{result_percent}</p>,
    },
    {
      title: "Người đánh giá",
      dataIndex: "staff",
      key: "staff",
      render: (staff) => <p>{staff}</p>,
    },
    {
      title: "Thời gian",
      width: 200,
      dataIndex: "created_at",
      key: "created_at",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Space>
            <ConfigProvider locale={viVN}>
              <DatePicker
                onChange={(month) => {
                  monthPicker.current = month;

                  setStateFilter(!stateFilter);
                  getQcHistory(1);
                }}
                picker="month"
              />
            </ConfigProvider>
          </Space>
        </div>
      ),
      filterDropdownOpen: stateFilter,
      filterIcon: (filtered) => (
        <FilterFilled
          style={{
            color: monthPicker.current !== null ? "#1677ff" : undefined,
          }}
          onClick={() => {
            setStateFilter(!stateFilter);
          }}
        />
      ),
      render: (created_at) => <p>{created_at}</p>,
    },
    reportNumber.permissions?.is_admin == true ? (
      {
        title: "Hành động",
        key: "action",
        width: 200,
        render: (_, record) => (
          <Space size="middle">
            <Popconfirm
              title="Xoá đánh giá?"
              description="Bạn có chắc chắn muốn xoá đánh giá này chứ ?"
              onClick={(e) => {
                e.stopPropagation();
              }}
              onConfirm={(e) => {
                e.stopPropagation();
                deleteQcHistory(record.key);
                getQcHistory(1);
              }}
              onCancel={() => {}}
              okText="Có"
              cancelText="Không"
            >
              <a style={{ color: "red" }}>Xoá</a>
            </Popconfirm>
          </Space>
        ),
      }
    ) : (
      <></>
    ),
  ];

  return (
    <div className="mt-2">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4 className="h4 title_content mb-0 text-gray-800">Chi nhánh </h4>{" "}
      </div>
      <div>
        <div className="card-body">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-7">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "column",
                }}
              >
                <div className="d-flex">
                  <div className="mr-3">
                    <img
                      style={{
                        width: "200px",
                        height: "200px",
                      }}
                      src={`${
                        areaCurrent?.image ??
                        "https://comnieuthienly.vn/wp-content/uploads/2022/11/com-nieu-thien-ly-au-co.jpg"
                      }`}
                      alt="image"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <MdLocationOn color="rgb(193, 103, 103)" size={18} />
                      <span className="text-area">Địa chỉ:</span>
                      <span className="text-area">
                        {areaCurrent?.address ?? ""}
                      </span>
                    </div>
                    <div className="d-flex">
                      <FaRegCalendarAlt color="rgb(82, 150, 189)" size={18} />
                      <span className="text-area">Ngày khai trương:</span>
                      <span className="text-area">
                        {areaCurrent?.date_opening ?? ""}
                      </span>
                    </div>
                    <div className="d-flex">
                      <BsFillDatabaseFill color="rgb(82, 150, 189)" size={18} />
                      <span className="text-area" style={{ color: "red" }}>
                        Điểm QC trung bình tháng:
                      </span>
                      <span className="text-area" style={{ color: "red" }}>
                        {(qcHistory?.data ?? []).length == 0
                          ? "Chưa có đánh giá"
                          : formatMoneyVND(avg || 0, true)}
                      </span>
                    </div>
                    <div className="d-flex">
                      <BiSolidArea color="rgb(82, 150, 189)" size={18} />
                      <span className="text-area">Diện tích:</span>
                      <span className="text-area">
                        {areaCurrent?.acreage ?? ""} m2
                      </span>
                    </div>
                    <div className="d-flex">
                      <RiMoneyDollarCircleFill
                        color="rgb(82, 150, 189)"
                        size={18}
                      />
                      <span className="text-area">Chi phí xây dựng:</span>
                      <span className="text-area">
                        {areaCurrent?.fee ?? ""}₫
                      </span>
                    </div>
                    <div className="d-flex">
                      <BsFillPersonLinesFill
                        color="rgb(82, 150, 189)"
                        size={18}
                      />
                      <span className="text-area">Số nhân viên hiển thị:</span>
                      <span className="text-area">
                        {areaCurrent?.staff_number ?? ""}
                      </span>
                    </div>
                    <div className="d-flex">
                      <HiUserGroup color="rgb(82, 150, 189)" size={18} />
                      <span className="text-area">{`Danh sách quản lý cửa hàng: ${(
                        staffs ?? []
                      )
                        .map((e) => e.name)
                        .join(", ")}`}</span>
                    </div>
                  </div>
                </div>
                <div style={{ height: "30px" }}></div>
                <div className="d-flex justify-content-around align-items-center mt-4">
                  <button
                    className="button-report"
                    style={{ backgroundColor: "#67c1bf" }}
                    onClick={(e) => {
                      history.push(`/personnel-report`);
                    }}
                  >
                    Thống kê nhân sự
                  </button>
                  <button
                    className="button-report"
                    style={{ backgroundColor: "#7073cc" }}
                    onClick={(e) => {
                      history.push(`/operate-report`);
                    }}
                  >
                    Thống kê vận hành
                  </button>
                  <button
                    className="button-report"
                    style={{ backgroundColor: "#c16767" }}
                    onClick={(e) => {
                      history.push(`/report-report`);
                    }}
                  >
                    Thống kê báo cáo
                  </button>
                  <button
                    className="button-report"
                    style={{ backgroundColor: "#fcb900" }}
                    onClick={(e) => {
                      history.push(`/reviews-from-customers`);
                    }}
                  >
                    Đánh giá từ khách hàng
                  </button>
                  <button
                    className="button-report"
                    style={{ backgroundColor: "#fcb900" }}
                    onClick={(e) => {
                      history.push(`/report-quality-control`);
                    }}
                  >
                    Báo cáo QC/Checklist
                  </button>
                </div>
              </div>
            </div>
            <div className="col-5">
              {dataRevenue == null ? (
                <></>
              ) : (
                chartWidget("Tổng doanh thu", dataRevenue, constants.REVENUE)
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="title_content text-gray-800">Danh sách tài liệu</h4>
          {PermissionChecker({
            decent: reportNumber.permissions?.area_file_add ?? false,
            child: (
              <Button
                type="primary"
                shape="round"
                size={20}
                icon={<HiFolderOpen />}
                onClick={(e) => {
                  setModal({
                    method: "UPLOAD_FILE",
                    type: 0,
                    visible: true,
                  });
                }}
              >
                Thêm file tài liệu
              </Button>
            ),
          })}
        </div>
        {(areaCurrent?.file_url ?? []).length == 0 ? (
          <Empty width="100%" />
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {(areaCurrent?.file_url ?? []).map((e) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    padding: "20px",
                    margin: "20px",
                    boxShadow: "0 0 10px rgba(98, 98, 98, 0.2)",
                  }}
                  onClick={(x) => {
                    downloadFile(e);
                  }}
                >
                  <img
                    src="/images/folder.png"
                    style={{ width: "50px", height: "auto" }}
                  ></img>
                  <div style={{ height: "10px" }}></div>
                  <span className="px-3">
                    {`${e}`.replace("https://api.comnieuthienly.vn/i/", "")}
                  </span>
                </div>
              );
            })}
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="title_content text-gray-800">Lịch sử chi nhánh</h4>
          <div className="d-flex">
            {indexTab == 1 &&
              PermissionChecker({
                decent: reportNumber.permissions?.area_review_add ?? false,
                child: (
                  <Button
                    type="primary"
                    shape="round"
                    size={20}
                    icon={<BsStars />}
                    onClick={(e) => {
                      setModal({
                        method: "ADD",
                        type: 0,
                        visible: true,
                      });
                    }}
                  >
                    Thêm đánh giá
                  </Button>
                ),
              })}
            <div style={{ width: "10px" }}></div>
            {indexTab == 3 &&
              PermissionChecker({
                decent: reportNumber.permissions?.area_violate_add ?? false,
                child: (
                  <Button
                    type="primary"
                    shape="round"
                    size={20}
                    danger
                    icon={<MdDangerous />}
                    onClick={(e) => {
                      setModal({
                        method: "ADD",
                        type: 1,
                        visible: true,
                      });
                    }}
                  >
                    Thêm vi phạm
                  </Button>
                ),
              })}
            {indexTab == 4 && (
              <Space>
                {PermissionChecker({
                  decent:
                    reportNumber.permissions?.qc_evaluate_group_manager ??
                    false,
                  child: (
                    <Button
                      type="primary"
                      shape="round"
                      size={20}
                      icon={<BsStars />}
                      onClick={(e) => {
                        setModal({
                          method: "REPORT_QC",
                          visible: true,
                        });
                      }}
                    >
                      Xem báo cáo
                    </Button>
                  ),
                })}
                {PermissionChecker({
                  decent:
                    reportNumber.permissions?.qc_evaluate_group_manager ??
                    false,
                  child: (
                    <Button
                      type="primary"
                      shape="round"
                      size={20}
                      icon={<BsStars />}
                      onClick={(e) => {
                        setModal({
                          method: "EXPORT_QC",
                          visible: true,
                        });
                      }}
                    >
                      Export báo cáo
                    </Button>
                  ),
                })}
              </Space>
            )}
          </div>
        </div>
        <div style={{ paddingLeft: "13px", marginBottom: "20px" }}>
          <Tabs
            defaultActiveKey="1"
            onTabClick={(e) => {
              if (e == 1) {
                getHistoryLogArea(1, 0);
              }
              if (e == 3) {
                getHistoryLogArea(1, 1);
              }
              setIndexTab(e);
            }}
            items={[
              {
                label: "Lịch sử đánh giá",
                key: "1",
                children: (
                  <Table
                    loading={loading}
                    columns={columns}
                    dataSource={(historyReview?.data ?? []).map((e, index) => {
                      return {
                        key: `${e.id}`,
                        type_review: e.type_review ?? "--:--",
                        staff_action: e.staff_action ?? "--:--",
                        note: e.note ?? "--:--",
                        created_at: moment(e.created_at).format(
                          "HH:MM DD/MM/YYYY" ?? "--:--"
                        ),
                      };
                    })}
                    pagination={{
                      total: historyReview.total ?? 0,
                      pageSize: 20,
                    }}
                    onChange={(e) => {
                      getHistoryLogArea(e.current, 0);
                    }}
                  />
                ),
              },
              {
                label: "Danh sách phiếu sửa chữa",
                key: "2",
                children: (
                  <Table
                    loading={formLoading}
                    columns={formColumns}
                    dataSource={(forms?.data ?? []).map((e, index) => {
                      return {
                        key: `${e.id}`,
                        content: e.content ?? "--:--",
                        staff: e.staff ?? "--:--",
                        products: e.products ?? "--:--",
                        created_at: moment(e.created_at).format(
                          "HH:MM DD/MM/YYYY" ?? "--:--"
                        ),
                      };
                    })}
                    pagination={{
                      total: forms.total ?? 0,
                      pageSize: 20,
                    }}
                    onChange={(e) => {
                      getFormRepairArea(e.current);
                    }}
                  />
                ),
              },
              {
                label: "Lịch sử vi phạm hành chính",
                key: "3",
                children: (
                  <Table
                    loading={loading}
                    columns={violateColumns}
                    dataSource={(historyViolate?.data ?? []).map((e, index) => {
                      return {
                        key: `${e.id}`,
                        type_violate: e.type_violate ?? "--:--",
                        staff_action: e.staff_action ?? "--:--",
                        note: e.note ?? "--:--",
                        created_at: moment(e.created_at).format(
                          "HH:MM DD/MM/YYYY" ?? "--:--"
                        ),
                      };
                    })}
                    pagination={{
                      total: historyViolate.total ?? 0,
                      pageSize: 20,
                    }}
                    onChange={(e) => {
                      getHistoryLogArea(e.current, 1);
                    }}
                  />
                  // <div>
                  //   {(historyViolate?.data ?? []).length == 0 ? (
                  //     <Empty width="100%" />
                  //   ) : (
                  //     (historyViolate?.data ?? []).map((e) => {
                  //       return (
                  //         <div
                  //           style={{
                  //             display: "flex",
                  //             justifyContent: "space-between",
                  //             marginBottom: "10px",
                  //             borderRadius: "5px",
                  //             padding: "10px",
                  //             boxShadow: "0 0 5px 0px #0000001a",
                  //             gap: "5px",
                  //           }}
                  //         >
                  //           <div
                  //             style={{
                  //               display: "flex",
                  //               flexDirection: "column",
                  //             }}
                  //           >
                  //             <span
                  //               style={{
                  //                 fontSize: "14px",
                  //               }}
                  //             >
                  //               Loại vi phạm:{" "}
                  //               {getTypeViolate(e.type_violate ?? 0)}
                  //             </span>
                  //             <span
                  //               style={{
                  //                 fontSize: "14px",
                  //               }}
                  //             >
                  //               {e.note ?? "--:--"}
                  //             </span>

                  //             <span
                  //               style={{
                  //                 color: "red",
                  //                 fontSize: "13px",
                  //               }}
                  //             >
                  //               Người thêm vi phạm:{" "}
                  //               {e.staff_action?.name ?? "--:--"}
                  //             </span>
                  //             <span style={{ color: "grey", fontSize: "13px" }}>
                  //               {moment(e.created_at).format(
                  //                 "HH:MM DD/MM/YYYY"
                  //               ) ?? "--:--"}
                  //             </span>
                  //           </div>

                  //           <Popconfirm
                  //             title="Xoá lịch sử ?"
                  //             description="Bạn có chắc chắn muốn xoá lịch sử này chứ ?"
                  //             onConfirm={() => {
                  //               deleteHistoryLogArea(e.id, 1);
                  //             }}
                  //             onCancel={() => {}}
                  //             okText="Có"
                  //             cancelText="Không"
                  //           >
                  //             <Button danger>Xoá</Button>
                  //           </Popconfirm>
                  //         </div>
                  //       );
                  //     })
                  //   )}
                  //   <Pagination
                  //     defaultCurrent={1}
                  //     total={historyViolate?.total ?? 0}
                  //     pageSize={20}
                  //     onChange={(e) => {
                  //       getHistoryLogArea(e, 0);
                  //     }}
                  //   />
                  // </div>
                ),
              },
              {
                label: "Lịch sử QC đánh giá / Báo cáo",
                key: "4",
                children: (
                  <Tabs
                    items={[
                      {
                        label: "Đánh giá",
                        key: "danh-gia",
                        children: (
                          <Table
                            loading={loading}
                            columns={qcColumns}
                            onRow={(record, index) => {
                              return {
                                onClick: (e) => {
                                  e.stopPropagation();
                                  setModal({
                                    method: "QC",
                                    visible: true,
                                    // dataQc: record.data,
                                    dataQc: {
                                      isReport: record.data?.is_report,
                                      assessor: record.data?.staff?.name,
                                      data: processDataForTableQc(record.data),
                                      createdAt: record.data.created_at,
                                      resultPercent: record.data.result_percent,
                                    },
                                  });
                                },
                              };
                            }}
                            dataSource={(
                              qcHistory?.data.filter(
                                (item) => !item.is_report
                              ) ?? []
                            ).map((e, index) => {
                              return {
                                key: `${e.id}`,
                                data: e,
                                qc_evaluate_main_name:
                                  e.qc_evaluate_main_name ?? "--:--",
                                staff: e.staff?.name ?? "--:--",
                                result_percent: e.result_percent ?? "--:--",
                                created_at: moment(e.created_at).format(
                                  "HH:MM DD/MM/YYYY" ?? "--:--"
                                ),
                              };
                            })}
                            pagination={{
                              total: qcHistory?.total ?? 0,
                              pageSize: 20,
                            }}
                            onChange={(e) => {
                              getQcHistory(e.current);
                            }}
                          />
                        ),
                      },
                      {
                        label: "Báo cáo",
                        key: "bao-cao",
                        children: (
                          <Table
                            loading={loading}
                            columns={qcColumns}
                            onRow={(record, index) => {
                              return {
                                onClick: (e) => {
                                  e.stopPropagation();
                                  setModal({
                                    method: "QC",
                                    visible: true,
                                    // dataQc: record.data,
                                    dataQc: {
                                      assessor: record.data?.staff?.name,
                                      data: processDataForTableQc(record.data),
                                      createdAt: record.data.created_at,
                                      resultPercent: record.data.result_percent,
                                      isReport: record.data.is_report,
                                    },
                                  });
                                },
                              };
                            }}
                            dataSource={(
                              qcHistory?.data.filter(
                                (item) => item.is_report
                              ) ?? []
                            ).map((e, index) => {
                              return {
                                key: `${e.id}`,
                                data: e,
                                qc_evaluate_main_name:
                                  e.qc_evaluate_main_name ?? "--:--",
                                staff: e.staff?.name ?? "--:--",
                                result_percent: e.result_percent ?? "--:--",
                                created_at: moment(e.created_at).format(
                                  "HH:MM DD/MM/YYYY" ?? "--:--"
                                ),
                              };
                            })}
                            pagination={{
                              total: qcHistory?.total ?? 0,
                              pageSize: 20,
                            }}
                            onChange={(e) => {
                              getQcHistory(e.current);
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
      <AddHistoryModal
        open={modal.visible && modal.method === "ADD"}
        type={modal.type}
        onOk={() => {
          getHistoryLogArea(1, modal.type);
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
      <QcHistoryModal
        open={modal.visible && modal.method === "QC"}
        data={modal.dataQc}
        onOk={() => {
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
      <UploadMultiFileModal
        open={modal.visible && modal.method === "UPLOAD_FILE"}
        type={modal.type}
        areaCurrent={areaCurrent}
        onOk={() => {
          getAreaById();
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
      <ChooseReportQcModal
        open={modal.visible && modal.method === "EXPORT_QC"}
        onOk={() => {
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
      <ReportQcViewModal
        open={modal.visible && modal.method === "REPORT_QC"}
        onOk={() => {
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
    </div>
  );
}
